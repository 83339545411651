import { Col, Row, Spin } from "antd";
import style from "./style.module.scss";
import TableHeader from "components/TableHeader/TableHeader";
import React, { useEffect, useMemo, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import EmptyImage from "components/EmptyImage";
import CommonTable from "components/Tables/CommonTable";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import AuctionBlack from "assets/img/black/auction.svg";
import BidsBlack from "assets/img/black/bids.svg";
import SalesBlack from "assets/img/black/sales.svg";
import { getDashboardAPI } from "apis/auction/AuctionApi";
import { findDataOnStatus } from "utils/common-functions";
import NoDataFound from "components/no-data-found";

const Dashboard = (props) => {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const response = await getDashboardAPI();
      if (response.status === 200 && response.data?.data) {
        setDashboardData(response.data.data);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dashboardData === null) {
      fetchDashboardData();
    }
  }, []);

  const renderHeader = (title, icon) => {
    return (
      <>
        <TableHeader
          onAddNew={() => { }}
          headerTitle={title}
          headerBtnTitle={""}
          loading={loading}
          headerIcon={icon}
        />
      </>
    );
  };

  const statusCardData = [
    {
      title: "Total Auctions",
      value: renderItemDataOrEmptyNull(dashboardData?.totalAuction),
      icon: AuctionBlack,
    },
    {
      title: "Total Bids",
      value: renderItemDataOrEmptyNull(dashboardData?.totalBids),
      icon: BidsBlack,
    },
    {
      title: "Total Sales",
      value: renderItemDataOrEmptyNull(dashboardData?.totalSales, 2),
      // value: renderItemDataOrEmptyNull(dashboardData?.totalSales),
      icon: SalesBlack,
    },
  ];

  const renderStatusCards = useMemo(() => {
    return (
      <Row gutter={20} className={style.statusRow}>
        {statusCardData?.map((item, i) => {
          return (
            <Col className={style.statusColumn} md={8} sm={24} xs={24} key={i}>
              <div className={style.statusCardContainer}>
                <div className={style.iconImgContainer}>
                  {true ? (
                    <img src={item?.icon} alt="icon" />
                  ) : (
                    <div className={style.noImage}>
                      <EmptyImage fontSize="25px" background="white" />
                    </div>
                  )}
                </div>
                <div className={style.leftBoxStatus}>
                  <div>
                    <p>{item?.title}</p>
                  </div>
                  <div>
                    <span> {renderItemDataOrEmptyNull(item?.value)} </span>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    );
  }, [dashboardData]);

  const auctionTableColumn = [
    {
      title: "Quantity",
      key: "totalUnits",
      width: "33%",
      dataIndex: "totalUnits",
      render: ColTextCheck,
    },
    {
      title: "Unit Price",
      width: "33%",
      key: "price",
      dataIndex: "price",
      render: (text, row) =>
        `$ ${renderItemDataOrEmptyNull(text / row?.totalUnits, 2)}.`,
    },
    {
      title: "Lock Status",
      width: "33%",
      key: "lockedStatus",
      dataIndex: "lockedStatus",
      render: ColTextCheck,
    },
  ];

  const renderRecentAuctions = useMemo(() => {
    return (
      <div className={style.recentAuctions}>
        {renderHeader("Recent Auctions")}
        {dashboardData?.auctions?.length &&
          dashboardData?.auctions?.map((item, index) => {
            return (
              <div className={style.auctionCards} key={index}>
                <Row className={style.responsiveAuctions}>
                  <Col md={24} sm={24} xs={24}>
                    <div className={style.leftBox}>
                      <div
                        onClick={() => {
                          props.history.push("auction-detail", item);
                        }}
                        className={style.titleContainer}
                      >
                        <h5>
                          {`${item?.name}`}
                        </h5>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className={style.tableAuctionsContainer}>
                  <CommonTable
                    columns={auctionTableColumn}
                    loading={loading}
                    data={[item]}
                    hidePagination={true}
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  }, [dashboardData, loading]);

  const renderRecentWinners = useMemo(() => {
    return (
      <div className={style.recentWinners}>
        {renderHeader("Recent Winners")}
        {(dashboardData?.winners?.length &&
          dashboardData?.winners?.map((item, index) => {
            return (
              <Row className={style.recentCards} key={index}>
                <Col md={12} sm={12} xs={12}>
                  <div className={style.winnerCardLeft}>
                    <h5> {renderItemDataOrEmptyNull(item?.userName)} </h5>
                    <p
                      className={style.winnerAuctionId}
                      onClick={() => {
                        props.history.push("auction-detail", item?.auction);
                      }}
                    >
                      Auction ID: {item?.auction?.identifier}
                    </p>
                  </div>
                </Col>
                <Col md={12} sm={12} xs={12}>
                  <div className={style.winnerCardRight}>
                    <p> {findDataOnStatus(item?.status)} </p>
                    <p>
                      $
                      {renderItemDataOrEmptyNull(
                        item?.bidPrice + item?.auction?.deliveryFee,
                        2
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
            );
          })) || <NoDataFound />}
      </div>
    );
  }, [dashboardData]);

  return (
    <div className={style.dashboardContaienr}>
      <Spin spinning={loading} indicator={renderLoader}>
        {renderStatusCards}
        <Row gutter={20}>
          <Col lg={14} md={12} sm={24} xs={24}>
            {renderRecentAuctions}
          </Col>
          <Col lg={10} md={12} sm={24} xs={24}>
            {renderRecentWinners}
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default Dashboard;
