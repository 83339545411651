import React, { useCallback, useEffect, useState } from "react";
import style from "./style.module.scss";
import { Checkbox, Col, Form, Input, Row } from "antd";
import { getPermissionsApi } from "apis/roles-and-permission";
import CustomButton from "components/custom-button";
import { setRules } from "utils/common-functions";
import { requestErrorHandel } from "utils/helpers/helpers";
import { createRolesApi } from "apis/roles-and-permission";
import { successMessage } from "utils/helpers/helpers";
import { updateRolesApi } from "apis/roles-and-permission";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

const RolesForm = (props) => {
  const CheckboxGroup = Checkbox.Group;
  const [form] = Form.useForm();
  const { location } = props;

  const rowData = location.state;
  const [loading, setLoading] = useState(false);
  const [permissionsData, setPermissionsData] = useState([]);
  const [checkedList, setCheckedList] = useState([]);

  const [checkedListImportantAlert, setCheckedListImportantAlert] = useState(
    []
  );
  const [checkedListAdminControl, setCheckedListAdminControl] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [indeterminateImportantAlert, setIndeterminateImportantAlert] =
    useState(true);
  const [indeterminateAdminControl, setIndeterminateAdminControl] =
    useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [checkAllImportantAlert, setCheckAllImportantAlert] = useState(false);
  const [checkAllAdminControl, setCheckAllAdminControl] = useState(false);

  // Memoized update functions
  const updateCheckedList = useCallback((permissions, type) => {
    return (
      permissions
        .filter((item) => item?.type === type)
        .map((item) => item.id) || []
    );
  }, []);

  useEffect(() => {
    if (rowData && rowData.permissions) {
      form.setFieldsValue({
        name: rowData?.name,
        description: rowData?.description,
      });
      setCheckedList(updateCheckedList(rowData.permissions, "rolePermission"));
      setCheckedListImportantAlert(
        updateCheckedList(rowData.permissions, "impAlerts")
      );
      setCheckedListAdminControl(
        updateCheckedList(rowData.permissions, "adminControl")
      );
    }
  }, [rowData, updateCheckedList]);

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getPermissionsApi();
      if (response.status === 200 && response.data?.data) {
        setPermissionsData(response?.data?.data);
        if (!rowData) {
          setCheckedList(
            response?.data?.data
              ?.filter((item) => item?.type === "rolePermission")
              ?.map((item) => item.id)
          );
          setCheckedListImportantAlert(
            response?.data?.data
              ?.filter((item) => item?.type === "impAlerts")
              ?.map((item) => item.id)
          );
          setCheckedListAdminControl(
            response?.data?.data
              ?.filter((item) => item?.type === "adminControl")
              ?.map((item) => item.id)
          );
        }
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  const handleSubmit = async (values) => {
    const permissions = checkedList.concat(
      checkedListImportantAlert,
      checkedListAdminControl
    );
    if (rowData) {
      try {
        setLoading(true);
        const response = await updateRolesApi({
          id: rowData?.id,
          permissions,
          ...values,
        });
        if (response.status === 200 && response.data?.data) {
          successMessage({ message: "Role has been updated." });
          props.history.push("/admin/roles");
        }
      } catch (error) {
        requestErrorHandel({ error });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const response = await createRolesApi({ permissions, ...values });
        if (response.status === 200 && response.data?.data) {
          successMessage({ message: "Role has been added." });
          props.history.push("/admin/roles");
        }
      } catch (error) {
        requestErrorHandel({ error });
      } finally {
        setLoading(false);
      }
    }
  };

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(
      !!list.length &&
        list.length <
          permissionsData
            ?.filter((item) => item?.type === "rolePermission")
            ?.map((item) => {
              return {
                label: item?.name,
                value: item?.id,
              };
            }).length
    );
    setCheckAll(
      list.length ===
        permissionsData
          ?.filter((item) => item?.type === "rolePermission")
          ?.map((item) => {
            return {
              label: item?.name,
              value: item?.id,
            };
          }).length
    );
  };

  const onChangeImportantAlert = (list) => {
    setCheckedListImportantAlert(list);
    setIndeterminateImportantAlert(
      !!list.length &&
        list.length <
          permissionsData
            ?.filter((item) => item?.type === "impAlerts")
            ?.map((item) => {
              return {
                label: item?.name,
                value: item?.id,
              };
            }).length
    );
    setCheckAllImportantAlert(
      list.length ===
        permissionsData
          ?.filter((item) => item?.type === "impAlerts")
          ?.map((item) => {
            return {
              label: item?.name,
              value: item?.id,
            };
          }).length
    );
  };

  const onChangeAdminControl = (list) => {
    setCheckedListAdminControl(list);
    setIndeterminateAdminControl(
      !!list.length &&
        list.length <
          permissionsData
            ?.filter((item) => item?.type === "adminControl")
            ?.map((item) => {
              return {
                label: item?.name,
                value: item?.id,
              };
            }).length
    );
    setCheckAllAdminControl(
      list.length ===
        permissionsData
          ?.filter((item) => item?.type === "adminControl")
          ?.map((item) => {
            return {
              label: item?.name,
              value: item?.id,
            };
          }).length
    );
  };

  const onCheckAllChange = (e) => {
    setCheckedList(
      e.target.checked
        ? permissionsData
            ?.filter((item) => item?.type === "rolePermission")
            ?.map((item) => item.id)
        : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onCheckAllChangeImportantAlert = (e) => {
    setCheckedListImportantAlert(
      e.target.checked
        ? permissionsData
            ?.filter((item) => item?.type === "impAlerts")
            ?.map((item) => item.id)
        : []
    );
    setIndeterminateImportantAlert(false);
    setCheckAllImportantAlert(e.target.checked);
  };

  const onCheckAllChangeAdminControl = (e) => {
    setCheckedListAdminControl(
      e.target.checked
        ? permissionsData
            ?.filter((item) => item?.type === "adminControl")
            ?.map((item) => item.id)
        : []
    );
    setIndeterminateAdminControl(false);
    setCheckAllAdminControl(e.target.checked);
  };

  return (
    <div className={style.rolesFormContainer}>
      <Form
        form={form}
        className={style.form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Row gutter={20}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item rules={setRules("Role")} label={"Role"} name={"name"}>
              <Input disabled={rowData?.viewOnly} />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              rules={setRules("Description")}
              label={"Description"}
              name={"description"}
            >
              <Input disabled={rowData?.viewOnly} />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.checkBoxRow}>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            disabled={rowData?.viewOnly}
          >
            <h5> Role Permissions</h5>
          </Checkbox>
          <CheckboxGroup
            options={permissionsData
              ?.filter((item) => item?.type === "rolePermission")
              ?.map((item) => {
                return {
                  label: item?.name,
                  value: item?.id,
                };
              })}
            value={checkedList}
            onChange={onChange}
            disabled={rowData?.viewOnly}
          />
        </Row>
        <Row className={style.checkBoxRow}>
          <Checkbox
            indeterminate={indeterminateImportantAlert}
            onChange={onCheckAllChangeImportantAlert}
            checked={checkAllImportantAlert}
            disabled={rowData?.viewOnly}
          >
            <h5>Important Alerts</h5>
          </Checkbox>
          <CheckboxGroup
            options={permissionsData
              ?.filter((item) => item?.type === "impAlerts")
              ?.map((item) => {
                return {
                  label: item?.name,
                  value: item?.id,
                };
              })}
            value={checkedListImportantAlert}
            onChange={onChangeImportantAlert}
            disabled={rowData?.viewOnly}
          />
        </Row>
        <Row className={style.checkBoxRow}>
          <Checkbox
            indeterminate={indeterminateAdminControl}
            onChange={onCheckAllChangeAdminControl}
            checked={checkAllAdminControl}
            disabled={rowData?.viewOnly}
          >
            <h5> Admin Control</h5>
          </Checkbox>
          <CheckboxGroup
            options={permissionsData
              ?.filter((item) => item?.type === "adminControl")
              ?.map((item) => {
                return {
                  label: item?.name,
                  value: item?.id,
                };
              })}
            value={checkedListAdminControl}
            onChange={onChangeAdminControl}
            disabled={rowData?.viewOnly}
          />
        </Row>
        {rowData?.viewOnly !== true && (
          <Row justify={"end"} span={24}>
            <CustomButton
              loading={loading}
              htmlType={"submit"}
              title={(rowData && "Update") || "Add"}
              variant="two"
              startData={(rowData && <EditOutlined />) || <PlusOutlined />}
            />
          </Row>
        )}
      </Form>
    </div>
  );
};

export default RolesForm;
