export const SET_USER_INFO = "SET_USER_INFO";
export const SIDEBAR_MENU = "SIDEBAR_MENU";
export const USER_PERMISSIONS = "USER_PERMISSIONS";

export const _setLoginUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_INFO,
      payload: data,
    });
  };
};

export const _addSidebarMenu = (data) => {
  return (dispatch) => {
    dispatch({
      type: SIDEBAR_MENU,
      payload: data,
    });
  };
};

export const _setUserPermissions = (data) => {
  return (dispatch) => {
    dispatch({
      type: USER_PERMISSIONS,
      payload: data,
    });
  };
};
