import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import CustomButton from "components/custom-button";
import { Col, Form, Input, Row, Spin, Tabs, Tooltip } from "antd";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { getManageAuctionsByIdApi } from "apis/auction/AuctionApi";
import { requestErrorHandel } from "utils/helpers/helpers";
import { renderLoader } from "utils/helpers/helpers";
import CustomModal from "components/CustomModal/CustomModal";
import { setRules } from "utils/common-functions";
import { cancelAuctionApi } from "apis/auction/AuctionApi";
import { successMessage } from "utils/helpers/helpers";
import { sortArrCompareMaxValues } from "utils/common-functions";
import { postSetWinnerApi } from "apis/bids";
import { addAuctionShipmentApi } from "apis/auction/AuctionApi";
import { EMPTY_NULL_DATA } from "utils/constants/const";
import { formatDate } from "utils/common-functions";
import { getTimeFromdateString } from "utils/common-functions";
import { renderStatusTag } from "components/export-common-components/table-columns-text-check";
import NoDataFound from "components/no-data-found";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { findAuctionPaymentStatus } from "utils/common-functions";
import { NotificationManager } from "react-notifications";
import { updateAuctionStatusMarkAsCompleteApi } from "apis/auction/AuctionApi";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";

const AuctionDetail = (props) => {
  const [form] = Form.useForm();
  const [formShipment] = Form.useForm();

  const { location } = props;
  const auctionId = location.state?.id;
  const [isActionable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [singleAuctionData, setSingleAuctionData] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleShipmentModal, setVisibleShipmentModal] = useState(false);
  const [visibleWinnerModal, setVisibleWinnerModal] = useState(false);
  const [allBidsData, setAllBidsData] = useState([]);

  useEffect(() => {
    if (auctionId) {
      fetchSingleAuction(auctionId);
    }
  }, [auctionId]);

  useEffect(() => {
    if (singleAuctionData) {
      setAllBidsData(
        singleAuctionData?.bids?.sort((a, b) =>
          sortArrCompareMaxValues(a, b, "amount")
        )
      );
    }

    formShipment.setFieldsValue({
      shipmentLink: singleAuctionData?.shipmentLink,
      trackingNo: singleAuctionData?.trackingNo,
    });
  }, [singleAuctionData]);

  const fetchSingleAuction = async (id) => {
    let res = null;
    try {
      setLoading(true);
      const response = await getManageAuctionsByIdApi(id);
      setLoading(false);
      res = response.data?.data;
      if (response.status === 200 && response.data?.data) {
        setSingleAuctionData(response.data?.data);
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  const onSetWinnerBid = async (id) => {
    let res = null;
    try {
      setLoading(true);
      const response = await postSetWinnerApi({ auctionId, bidId: id });
      setLoading(false);
      res = response.data?.data;
      if (response.status === 200) {
        await fetchSingleAuction(auctionId);
        setVisibleWinnerModal(false);
        successMessage({ message: "Bid winner has been announced." });
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  const markAsCompleteFun = async () => {
    try {
      setLoading(true);
      const response = await updateAuctionStatusMarkAsCompleteApi(auctionId);
      if (response.status === 200 && response.data) {
        NotificationManager.info(response?.data?.message);
        await fetchSingleAuction(auctionId);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  const descriptionItems = [
    {
      key: "1",
      label: `EN`,
      children: (
        <>
          <p>
            <b>Description: </b>
            {renderItemDataOrEmptyNull(
              singleAuctionData?.auctionTranslation?.find(
                (item) => item.languageCode === "en"
              ).description
            )}
          </p>
          {/* <p>Manufactor: Manufacture Name</p>
          <p>Carrier: </p>
          <p>Grade: A</p>
          <p>Year: 2022</p> */}
        </>
      ),
    },
    {
      key: "2",
      label: `CH`,
      children: (
        <>
          <p>
            <b>Description: </b>
            {renderItemDataOrEmptyNull(
              singleAuctionData?.auctionTranslation?.find(
                (item) => item.languageCode === "ch"
              ).description
            )}
          </p>
          {/* <p>Manufactor: Manufacture Name</p>
          <p>Carrier: </p>
          <p>Grade: A</p>
          <p>Year: 2022</p> */}
        </>
      ),
    },
    {
      key: "3",
      label: `RU`,
      children: (
        <>
          <p>
            <b>Description: </b>
            {renderItemDataOrEmptyNull(
              singleAuctionData?.auctionTranslation?.find(
                (item) => item.languageCode === "sp"
              ).description
            )}
          </p>
          {/* <p>Manufactor: Manufacture Name</p>
          <p>Carrier: </p>
          <p>Grade: A</p>
          <p>Year: 2022</p> */}
        </>
      ),
    },

    {
      key: "4",
      label: `AR`,
      children: (
        <>
          <p>
            <b>Description: </b>
            {renderItemDataOrEmptyNull(
              singleAuctionData?.auctionTranslation?.find(
                (item) => item.languageCode === "ar"
              ).description
            )}
          </p>
          {/* <p>Manufactor: Manufacture Name</p>
          <p>Carrier: </p>
          <p>Grade: A</p>
          <p>Year: 2022</p> */}
        </>
      ),
    },
  ];

  const temsConditionItems = [
    {
      key: "1",
      label: `EN`,
      children: (
        <>
          <p>
            <b>Terms & Conditions: </b>
            {renderItemDataOrEmptyNull(
              singleAuctionData?.auctionTranslation?.find(
                (item) => item.languageCode === "en"
              ).termConditions
            )}
          </p>
        </>
      ),
    },
    {
      key: "2",
      label: `CH`,
      children: (
        <>
          <p>
            <b>Terms & Conditions: </b>
            {renderItemDataOrEmptyNull(
              singleAuctionData?.auctionTranslation?.find(
                (item) => item.languageCode === "ch"
              ).termConditions
            )}
          </p>
        </>
      ),
    },
    {
      key: "3",
      label: `RU`,
      children: (
        <>
          <p>
            <b>Terms & Conditions: </b>
            {renderItemDataOrEmptyNull(
              singleAuctionData?.auctionTranslation?.find(
                (item) => item.languageCode === "sp"
              ).termConditions
            )}
          </p>
        </>
      ),
    },

    {
      key: "4",
      label: `AR`,
      children: (
        <>
          <p>
            <b>Terms & Conditions: </b>
            {renderItemDataOrEmptyNull(
              singleAuctionData?.auctionTranslation?.find(
                (item) => item.languageCode === "ar"
              ).termConditions
            )}
          </p>
        </>
      ),
    },
  ];

  const onChange = (key) => {
    console.log(key);
  };

  const onConfirmCancelAuction = async (values) => {
    await onCancelAuction(values);
  };

  const onCancelAuction = async (payload) => {
    let res = null;
    try {
      setLoading(true);
      const response = await cancelAuctionApi(auctionId, payload);
      setLoading(false);
      res = response.data?.data;
      if (response.status === 200 && response.data?.data) {
        await fetchSingleAuction(auctionId);
        setVisibleModal(false);
        successMessage({ message: "Auction cancelled successfully" });
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  const winnerModal = (
    <CustomModal
      visibility={visibleWinnerModal}
      handleCancel={() => setVisibleWinnerModal(false)}
      className={style.winnerModal}
      handleOk={() => {}}
      title={"Placed bids"}
    >
      <div className={style.commonDeleteModalContainer}>
        <Spin spinning={loading} indicator={renderLoader}>
          <Row className={style?.heading}>
            <h4>Announce Winner</h4>
          </Row>
          <div>
            <Row>
              <Col span={8}>
                <h5>Name</h5>
              </Col>
              <Col span={8}>
                <h5>Amount</h5>
              </Col>
              <Col span={8}>
                <h5>Winner TBD</h5>
              </Col>
            </Row>
            {allBidsData?.map((item) => {
              return (
                <Row>
                  <Col span={8}>
                    <p>{renderItemDataOrEmptyNull(item?.name)}</p>
                  </Col>
                  <Col span={8}>
                    <p>{renderItemDataOrEmptyNull(item?.amount)}</p>
                  </Col>
                  <Col span={8}>
                    {(item?.identifier === "B-0001" ||
                      item?.identifier === "B-0002") && (
                      <CustomButton
                        onClick={() => onSetWinnerBid(item?.id)}
                        title={"Winner"}
                        disabled={item?.identifier === "B-0002" ? true : false}
                      />
                    )}
                  </Col>
                </Row>
              );
            })}
          </div>
        </Spin>
      </div>
    </CustomModal>
  );

  const cancelAuctionModal = (
    <CustomModal
      visibility={visibleModal}
      handleCancel={() => {
        setVisibleModal(false);
      }}
      handleOk={() => {}}
      className={style.modalCancelAuction}
      title={"Confirmation Alert"}
    >
      <Form
        layout="vertical"
        className={style.cancelModalForm}
        form={form}
        onFinish={onConfirmCancelAuction}
      >
        <Row>
          <p style={{ marginLeft: 2 }}>
            Are you sure you want to cancel this auction?
            {/* It will be removed if
            there is any winner. */}
          </p>
        </Row>
        <div className={style.reasonInputContainer}>
          {/* <span> 120 </span> */}
          <Form.Item
            rules={setRules("Reason for cancel auction")}
            label={"Reason for cancelling"}
            name={"reason"}
          >
            <Input />
          </Form.Item>
        </div>
        <Row justify={"space-between"}>
          <CustomButton
            className={style.btn}
            onClick={() => setVisibleModal(false)}
            title={"Cancel"}
          />
          <CustomButton
            className={style.btn}
            loading={loading}
            title={"Submit"}
            variant="one"
            htmlType="submit"
          />
        </Row>
      </Form>
    </CustomModal>
  );

  const validateUrl = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return false;
    }
    return true;
  };

  const onSubmitShipment = async (values) => {
    if (!validateUrl(values.shipmentLink)) {
      NotificationManager.error(
        'Not a valid URL: must start with "http://" or "https://"'
      );
      return;
    }
    if (!values.shipmentLink.endsWith("/") && values.trackingNo !== "") {
      values.shipmentLink += "/";
    }
    let res = null;
    try {
      setLoading(true);
      const response = await addAuctionShipmentApi({
        auctionId,
        ...values,
      });
      setLoading(false);
      res = response.data?.data;
      if (response.status === 200) {
        await fetchSingleAuction(auctionId);
        setVisibleShipmentModal(false);
        successMessage({ message: "Shipment details has been added." });
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  return (
    <div className={style.auctionDetailsContainer}>
      <Spin spinning={loading} indicator={renderLoader}>
        {cancelAuctionModal}
        {winnerModal}
        {isActionable && (
          <Row justify={"end"} className={style.topBtnContainer}>
            {!singleAuctionData?.bids?.length &&
              singleAuctionData?.status !== "cancelled" && (
                <CustomButton
                  onClick={() => setVisibleModal(true)}
                  title={"Cancel"}
                  startData={<CloseOutlined />}
                  variant="two"
                />
              )}

            {!singleAuctionData?.winner &&
              singleAuctionData?.status !== "cancelled" && (
                <CustomButton
                  onClick={() => {
                    props.history.push("manage-auctions/create-auction", {
                      id: auctionId,
                    });
                  }}
                  title={"Edit"}
                  startData={<EditOutlined />}
                  variant="one"
                />
              )}
          </Row>
        )}
        {singleAuctionData?.winner && (
          <Row className={style.maxBidRow}>
            <Row
              flex="row"
              style={{ width: "100%", marginBottom: "20px" }}
              justify={"space-between"}
            >
              <div>
                <p>
                  <span>Winner:</span>{" "}
                  {renderItemDataOrEmptyNull(singleAuctionData?.winner?.name)}
                </p>
                <p>
                  <span>Payment Status:</span>{" "}
                  {findAuctionPaymentStatus(
                    singleAuctionData?.winner?.statusIdentifier
                  )}
                </p>
                <p>
                  <span>Total Amount Paid:</span>$
                  {renderItemDataOrEmptyNull(
                    singleAuctionData?.winner?.paidAmount ?? 0
                  )}
                </p>
              </div>
              <div>
                <p>
                  {" "}
                  <span>Bid Amount Per Unit:</span> $
                  {ColTextCheck(
                    renderItemDataOrEmptyNull(
                      singleAuctionData?.winner?.bidPrice /
                        singleAuctionData?.totalUnits,
                      2
                    )
                  )}
                </p>
                <p>
                  {" "}
                  <span>Total Bid Amount:</span> $
                  {renderItemDataOrEmptyNull(
                    singleAuctionData?.winner?.bidPrice
                  )}
                </p>
                {(singleAuctionData?.winner?.statusIdentifier === "B-0002" && (
                  <></>
                )) ||
                  (singleAuctionData?.winner?.statusIdentifier === "B-0005" && (
                    <>
                      <br />
                      <p>20% deposit amount added to service fees</p>
                    </>
                  )) ||
                  (singleAuctionData?.winner?.statusIdentifier === "B-0003" && (
                    <>
                      <p>
                        {" "}
                        <span>Delivery status:</span>{" "}
                        {(singleAuctionData?.shipmentLink && (
                          <Row gutter={10} align={"middle"}>
                            <a
                              href={
                                singleAuctionData?.shipmentLink +
                                singleAuctionData?.trackingNo
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              className={style.underlinedText}
                            >
                              Track Shipment
                            </a>
                            <EditOutlined
                              style={{ marginLeft: "5px" }}
                              onClick={() => setVisibleShipmentModal(true)}
                            />
                          </Row>
                        )) || (
                          <a
                            className={style.underlinedText}
                            onClick={() => setVisibleShipmentModal(true)}
                          >
                            {(singleAuctionData?.shipmentLink &&
                              "Update Tracking ID") ||
                              "Add Tracking ID"}
                          </a>
                        )}{" "}
                      </p>
                    </>
                  )) ||
                  (singleAuctionData?.winner?.statusIdentifier === "B-0003" && (
                    <>
                      <p className={style.deliveryStatus}>Delivery status:</p>
                      <p
                        className={style.underlinedText}
                        onClick={markAsCompleteFun}
                      >
                        Mark as completed
                      </p>
                    </>
                  ))}
                <p>
                  {" "}
                  <span>Date:</span>{" "}
                  {formatDate(singleAuctionData?.winner?.createdAt)}{" "}
                </p>
              </div>
            </Row>
            <Row flex="row" style={{ width: "100%" }} justify={"space-between"}>
              <div>
                <p>
                  <span>Email:</span>{" "}
                  {renderItemDataOrEmptyNull(singleAuctionData?.winner?.email)}
                </p>
                <p>
                  <span>Phone Number:</span>{" "}
                  {singleAuctionData?.winner?.phoneNo || EMPTY_NULL_DATA}
                </p>
                <p>
                  <span>Country:</span>{" "}
                  {renderItemDataOrEmptyNull(
                    singleAuctionData?.winner?.country?.name
                  )}
                </p>
                 <p>
                <span>Company Name:</span>{" "}
                {renderItemDataOrEmptyNull(
                  singleAuctionData?.winner?.companyName
                )}
              </p>
              </div>
            </Row>
            <Row
              flex="row"
              style={{ width: "100%" }}
              justify={"space-between"}
              align={"middle"}
            >
              <p>
                <span>Business Title:</span>{" "}
                {renderItemDataOrEmptyNull(
                  singleAuctionData?.winner?.businessAddress
                )}
              </p>
              <h4
                className={style.seeUserDetails}
                onClick={() => {
                  props.history.push("customers/user-details", {
                    id: singleAuctionData?.winner?.userId,
                  });
                }}
              >
                See Customer Details
              </h4>
            </Row>
          </Row>
        )}
        <Row className={style.headingRow}>
          <Col md={18} sm={24} xs={24}>
            <h2>{renderItemDataOrEmptyNull(singleAuctionData?.name)}</h2>
            <p className={style.auctionId}>
              Auction ID: <span> {singleAuctionData?.identifier} </span>
            </p>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <Row className={style.rightHeadingCol} justify={"end"}>
              <Col span={24}>{renderStatusTag(singleAuctionData?.status)}</Col>
              <Col span={24}>
                <p>{formatDate(singleAuctionData?.createdAt)}</p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className={style.auctionRow} align={"end"}>
          <h6> {singleAuctionData?.isFeature && "Featured Auction"} </h6>
        </Row>

        <div className={style.cardsAuctionRow} gutter={20}>
          <Row gutter={10} style={{ marginBottom: "20px" }}>
            <Col md={6} sm={12} xs={24}>
              <p>Serial ID</p>
              <h5>
                {renderItemDataOrEmptyNull(singleAuctionData?.serialNumber)}
              </h5>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <p>Sku</p>
              <h5>{renderItemDataOrEmptyNull(singleAuctionData?.sku)}</h5>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <p>Manufacturer</p>
              <h5>
                {" "}
                {renderItemDataOrEmptyNull(
                  singleAuctionData?.manufacture?.name
                )}{" "}
              </h5>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <p>Grade</p>
              <h5>
                {" "}
                {renderItemDataOrEmptyNull(
                  singleAuctionData?.grade?.gradeTranslation?.find(
                    (item) => item?.languageCode === "en"
                  )?.title
                )}{" "}
              </h5>
            </Col>
          </Row>
          <Row gutter={10} style={{ marginBottom: "20px" }}>
            {/* <Col md={6} sm={12} xs={24}>
              <p>Color</p>
              <h5>{renderItemDataOrEmptyNull(singleAuctionData?.color)}</h5>
            </Col> */}

            <Col md={6} sm={12} xs={24}>
              <p>QTY</p>
              <h5>
                {" "}
                {renderItemDataOrEmptyNull(singleAuctionData?.totalUnits)}{" "}
              </h5>
            </Col>

            <Col md={6} sm={12} xs={24}>
              <p>No of Bids</p>
              <h5>
                {renderItemDataOrEmptyNull(singleAuctionData?.bids?.length)}
              </h5>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <p>
                {" "}
                {singleAuctionData?.bids?.length > 0
                  ? "Last Bid"
                  : "Current Price"}
              </p>
              <h5>
                $
                {singleAuctionData?.bids?.length > 0 ? (
                  <>
                    {ColTextCheck(
                      renderItemDataOrEmptyNull(
                        singleAuctionData?.bids[0]?.amount /
                          singleAuctionData?.totalUnits,
                        2
                      )
                    )}
                  </>
                ) : (
                  <>
                    {ColTextCheck(
                      renderItemDataOrEmptyNull(
                        (singleAuctionData?.price /
                          singleAuctionData?.totalUnits,
                        2)
                      )
                    )}
                  </>
                )}
                /Per Unit
              </h5>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <p>Lock Status</p>
              <h5>
                {renderItemDataOrEmptyNull(singleAuctionData?.lockedStatus)}
              </h5>
            </Col>
          </Row>
        </div>
        <Row gutter={40}>
          {/* <Col md={10} sm={24} xs={24}>
            

            <div className={style.boxContainer}>
              <div className={style.textBoxContainer}>
                <Tabs
                  defaultActiveKey="1"
                  items={temsConditionItems}
                  onChange={onChange}
                />
              </div>
            </div>
          </Col> */}
          <Col className={style.rightCol} md={14} sm={24} xs={24}>
            {!singleAuctionData?.winner &&
              singleAuctionData?.bids?.length > 0 && (
                <Row className={style.maxBidRow}>
                  <Row
                    flex="row"
                    style={{ width: "100%" }}
                    justify={"space-between"}
                  >
                    <div>
                      <Row jus align={"middle"}>
                        <h4 style={{ marginRight: "20px" }}>
                          {renderItemDataOrEmptyNull(
                            singleAuctionData?.bids[0]?.name
                          )}
                        </h4>
                      </Row>
                      <p>Highest Bidder</p>
                    </div>
                    <div>
                      <h4 className={style.deliveryStatus}>
                        $
                        {renderItemDataOrEmptyNull(
                          singleAuctionData?.bids[0]?.amount /
                            singleAuctionData?.totalUnits
                        )}
                      </h4>
                      {!singleAuctionData?.winner &&
                        allBidsData?.length > 0 && (
                          <CustomButton
                            onClick={() =>
                              onSetWinnerBid(singleAuctionData?.bids[0]?.id)
                            }
                            loading={loading}
                            title="Announce Winner"
                            style={{ marginTop: "10px" }}
                          />
                        )}
                    </div>
                  </Row>
                  <Row flex="row" justify={"end"} style={{ width: "100%" }}>
                    {(singleAuctionData?.bid?.identifier === "B-0001" ||
                      singleAuctionData?.bid?.identifier === "B-0002") && (
                      <CustomButton
                        onClick={() =>
                          onSetWinnerBid(singleAuctionData?.bid?.id)
                        }
                        title={"Announce Winner"}
                        disabled={
                          singleAuctionData?.bid?.identifier === "B-0002"
                            ? true
                            : false
                        }
                      />
                    )}
                  </Row>
                </Row>
              )}

            {/* <Row gutter={5} className={style.bidAmount}>
              <p>
                Min bid amount:
                <span>
                  $
                  {renderItemDataOrEmptyNull(
                    (singleAuctionData?.price + singleAuctionData?.minIncrement,
                    2)
                  )}
                  {"  "}
                </span>
                {singleAuctionData?.bids?.length === 0 && (
                  <EditOutlined
                    className={style.editIcon}
                    onClick={() => {
                      props.history.push("manage-auctions/create-auction", {
                        id: auctionId,
                        noBids: true,
                      });
                    }}
                  />
                )}
                <Tooltip
                  placement="top"
                  title={"After first bid you can not edit minimum bid amount."}
                >
                  <InfoCircleOutlined className={style.infoIcon} />
                </Tooltip>
              </p>
              <p>
                Proxy Bid:
                <span>
                  {singleAuctionData?.isProxy ? "Enabled" : "Disabled"}{" "}
                </span>
              </p>
            </Row> */}

            <Row className={style.allBidCard}>
              <Row className={style.bidHeadingRow}>
                <h4>All Bids</h4>
                {(!singleAuctionData?.winner && allBidsData?.length > 0 && (
                  <CustomButton
                    onClick={async () => {
                      setVisibleWinnerModal(true);
                    }}
                    title={"Winner TBD"}
                  />
                )) || <p>Proxy Bid</p>}
              </Row>
              {(allBidsData?.length &&
                allBidsData?.map((item) => {
                  return (
                    <Row className={style.bidListRow} justify={"middle"}>
                      <h6>
                        {" "}
                        {renderItemDataOrEmptyNull(item?.name)}{" "}
                        {(item?.identifier === "B-0003" ||
                          item?.identifier === "B-0002") && (
                          <>
                            {" "}
                            <br /> <p style={{ color: "red" }}>
                              Is winner
                            </p>{" "}
                          </>
                        )}
                      </h6>
                      <Row
                        justify={"space-between"}
                        align={"middle"}
                        gutter={20}
                      >
                        {item?.identifier === "B-0003" ? (
                          singleAuctionData?.shipmentLink ? (
                            <Row gutter={10} align={"middle"}>
                              <a
                                href={
                                  singleAuctionData?.shipmentLink +
                                  singleAuctionData?.trackingNo
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className={style.underlinedText}
                              >
                                Track Shipment
                              </a>
                              <EditOutlined
                                style={{ marginLeft: "5px" }}
                                onClick={() => setVisibleShipmentModal(true)}
                              />
                            </Row>
                          ) : (
                            <p
                              className={style.underlinedText}
                              onClick={() => setVisibleShipmentModal(true)}
                            >
                              {singleAuctionData?.shipmentLink ? (
                                <CustomButton title={"Update Tracking ID"} />
                              ) : (
                                <CustomButton title={"Add Tracking ID"} />
                              )}{" "}
                            </p>
                          )
                        ) : null}
                        <h6 style={{ marginLeft: "10px" }}>
                          $
                          {ColTextCheck(
                            renderItemDataOrEmptyNull(
                              item?.amount / singleAuctionData?.totalUnits,
                              2
                            )
                          )}{" "}
                        </h6>
                      </Row>
                    </Row>
                  );
                })) || <NoDataFound />}
            </Row>

            <Row className={style.auctionHistory}>
              {singleAuctionData?.status === "cancelled" && (
                <>
                  <h2>Cancellation Reason</h2>
                  <p>
                    {singleAuctionData?.cancellationReason ??
                      "No reason was provided"}
                  </p>
                </>
              )}
              {singleAuctionData?.history?.length > 0 && (
                <h2>Auction History</h2>
              )}
              {singleAuctionData?.history?.map((item) => {
                return (
                  <Row gutter={10}>
                    <Col
                      className={style.leftHistoyCol}
                      md={18}
                      sm={12}
                      xs={24}
                    >
                      <p>
                        <b>{item.key}: </b>{" "}
                        {(item?.key === "auctionWon" &&
                          `${item?.logs?.winnerName} has won this auction`) ||
                          (item?.key === "payAuction" &&
                            ` has paid the auction`) ||
                          (item?.key === "auctionClosed" &&
                            ` has closed the auction`)}
                      </p>
                    </Col>
                    <Col
                      className={style.rightHistoyCol}
                      md={6}
                      sm={12}
                      xs={24}
                    >
                      <p>{getTimeFromdateString(item?.logs?.date)}</p>
                      <b>{formatDate(item?.logs?.date)}</b>
                    </Col>
                  </Row>
                );
              })}
            </Row>
          </Col>
        </Row>
        <CustomModal
          visibility={visibleShipmentModal}
          handleCancel={() => {
            setVisibleShipmentModal(false);
          }}
          handleOk={() => null}
          title={
            (singleAuctionData?.shipmentLink && "Update Shipment Details") ||
            "Add Shipment Details"
          }
        >
          <Spin spinning={loading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.modalForm}
              form={formShipment}
              name="control-hooks"
              onFinish={onSubmitShipment}
            >
              <Row gutter={[10]}>
                <Col span={24}>
                  <Form.Item
                    className={style.mb_1}
                    rules={setRules("Shipment Link")}
                    label={"Shipment Link"}
                    name={"shipmentLink"}
                  >
                    <Input placeholder="https://www.google.com/" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className={style.mb_1}
                    // rules={setRules("Tracking No")}
                    label={"Tracking No"}
                    name={"trackingNo"}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row justify={"center"}>
                    <CustomButton
                      className={style.btn}
                      title={
                        (singleAuctionData?.shipmentLink && "Update") || "Save"
                      }
                      disabled={loading}
                      htmlType="submit"
                      loading={loading}
                      destroyOnClose={true}
                    />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default AuctionDetail;
