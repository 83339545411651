import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { Tabs } from "antd";
import DepositRequest from "./deposit-request";
import NewAccountsRequestImportantAlert from "./accounts-request-important-alert";
import { requestErrorHandel } from "utils/helpers/helpers";
import { getDepositRequestApi } from "apis/important-alerts/deposit-request";
import { getNewAccountsRequestApi } from "apis/accounts-request";
import { getAuctionStatusApi } from "apis/auction/AuctionApi";
import { useDispatch, useSelector } from "react-redux";
import { _toggleNewDepositRequestBadage } from "actions/generalActions/generalAction";
import { _toggleNewAccountRequestBadage } from "actions/generalActions/generalAction";

const ImportantAlerts = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [depositListData, setDepositListData] = useState([]);
  const [accountRequestListData, setAccountRequestListData] = useState([]);
  const [auctionStatusData, setAuctionStatusData] = useState([]);

  const unreadNewAccountRequests = useSelector(
    (state) => state.general?.unreadNewAccountRequests
  );
  const unreadNewDepositRequests = useSelector(
    (state) => state.general?.unreadNewDepositRequests
  );
  const userPermissions = useSelector((state) => state.login.userPermissions);

  useEffect(() => {
    fetchDataDepositListApi();
    fetchDataAccountRequestApi();
    // fetchDataAuctionStatusApi();
  }, []);

  const fetchDataDepositListApi = async () => {
    try {
      setLoading(true);
      const response = await getDepositRequestApi(1, 1000);
      if (response.status === 200 && response.data?.data) {
        setDepositListData(response.data?.data);
        dispatch(_toggleNewDepositRequestBadage(response?.data?.total));
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  const fetchDataAccountRequestApi = async () => {
    try {
      setLoading(true);
      const response = await getNewAccountsRequestApi(1, 1000);
      if (response.status === 200 && response.data?.data) {
        setAccountRequestListData(response?.data?.data);
        dispatch(_toggleNewAccountRequestBadage(response?.data?.total));
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  const fetchDataAuctionStatusApi = async () => {
    try {
      setLoading(true);
      const response = await getAuctionStatusApi(1, 1000);
      if (response.status === 200 && response.data?.data) {
        setAuctionStatusData(response.data.data);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  const generateTabs = () => {
    const tabs = [];
    // Function to check if a user has a specific permission
    const hasPermission = (permissionName) => {
      return userPermissions?.some(
        (permission) => permission.name === permissionName
      );
    };
    // Adding tabs based on user permissions
    if (hasPermission("New Account Requests")) {
      tabs.push({
        key: "1",
        label: `New Account Requests(${unreadNewAccountRequests})`,
        children: <NewAccountsRequestImportantAlert />,
      });
    }
    if (hasPermission("Deposit Requests")) {
      tabs.push({
        key: "2",
        label: `Deposit Requests(${unreadNewDepositRequests})`,
        children: <DepositRequest />,
      });
    }
    return tabs;
  };

  const pagesItems = generateTabs();

  const onChange = (key) => { };

  return (
    <div>
      <div className={style.boxContainer}>
        <div className={style.textBoxContainer}>
          <Tabs defaultActiveKey="1" items={pagesItems} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

export default ImportantAlerts;
