import { getNewAccountsRequestApi } from "apis/accounts-request";
import { getDepositRequestApi } from "apis/important-alerts/deposit-request";

export const NEW_ACCOUNT_REQUESTS = "NEW_ACCOUNT_REQUESTS";
export const NEW_DEPOSIT_REQUESTS = "NEW_DEPOSIT_REQUESTS";

export const _toggleNewAccountRequestBadage = (toggle) => {
  return (dispatch) => {
    dispatch({
      type: NEW_ACCOUNT_REQUESTS,
      payload: toggle,
    });
  };
};
export const _toggleNewDepositRequestBadage = (toggle) => {
  return (dispatch) => {
    dispatch({
      type: NEW_DEPOSIT_REQUESTS,
      payload: toggle,
    });
  };
};

export const _getDepositRequestApi = () => {
  return (dispatch) => {
    getDepositRequestApi()
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: NEW_DEPOSIT_REQUESTS,
            payload: res?.data?.total,
          });
        }
      })
      .catch((e) => {});
  };
};
export const _getNewAccountsRequestApi = () => {
  return (dispatch) => {
    getNewAccountsRequestApi()
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: NEW_ACCOUNT_REQUESTS,
            payload: res?.data?.total,
          });
        }
      })
      .catch((e) => {});
  };
};
