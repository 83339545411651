import client from "apis/config";

export const getAllBidsApi = async (
  page,
  limit = 10,
  type = "",
  search = "",
  batchNo = "",
  categoryId = "",
  bidType = ""
) => {
  return await client.get(
    `dashBoard/bids?page=${page}&search=${search}&type=${type}&limit=${limit}&categoryId=${categoryId}&batchNo=${batchNo}&bidType=${bidType}`
  );
};

export const postSetWinnerApi = async (data) => {
  return await client.post(`auctions/winner/decide`, data);
};

export const exportSpecificAuctionsList = async (
  categoryId = "",
  BatchNo = "",
  customerId = ""
) => {
  return await client.get(
    `dashboard/auctions/bid?batchNo=${BatchNo}&categoryId=${categoryId}&customerId=${customerId}`
  );
};
export const getBatchNumbersRelatedToCategory = async ({ categoryId = "" }) => {
  return await client.get(`categories/batchNo/${categoryId}`);
};
