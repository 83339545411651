import * as React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { NotificationManager } from "react-notifications";
import { getSignUrl } from "apis/get-sign-url/getSignUrl";
import { Redirect } from "react-router";
import LoginForm from "views/Login";
import _ from "lodash";
import moment from "moment-timezone";

const dallasTimezone = "America/Chicago";

export const requestErrorHandel = async ({ error }) => {
  if (error?.response?.status === 401) {
    NotificationManager.error(
      error?.response?.data?.message ?? "Internal Server Errror"
    );
    setTimeout(() => {
      localStorage.clear();
      // window.location.href = "/";
    }, 500);
    return;
  } else if (error?.response?.data?.statusCode === 422) {
    if (error?.response?.data.message?.length > 0) {
      NotificationManager.error(
        error?.response?.data?.message ?? "Internal Server Errror"
      );
    } else if (error?.response?.data?.errors?.message?.length > 0) {
      NotificationManager.error(
        error?.response?.data?.errors?.message ?? "Internal Server Errror"
      );
    } else if (error?.response?.data?.statusCode === 422) {
      NotificationManager.error(
        Object.values(error?.response?.data?.errors)[0]
      );
    } else {
      NotificationManager.error(
        error?.response?.data?.message ?? "Internal Server Errror"
      );
    }
  } else if (!navigator.onLine) {
    NotificationManager.info(
      "No internet connection, Please check your internet"
    );
  } else {
    return NotificationManager.error(
      error?.response?.data?.message ?? "Internal Server Errror"
    );
  }
};

export const successMessage = ({ message }) => {
  return NotificationManager.success(message);
};

export const infoMessage = (info) => {
  return NotificationManager.info(info);
};

export const showValidationError = (errorMsg) => {
  const debounceAlert = _.debounce(
    () => NotificationManager.error(errorMsg),
    800
  );
  debounceAlert();
};

export const formateDateWithMoment = (date, formate = "DD-MM-YYYY") => {
  return moment.tz(date, dallasTimezone).format(formate);
};

export const Capitalize = (str) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const removeUndercore = (value) => {
  return value.replace(/_/g, " ");
};

export const convertToTitleCase = (value) => {
  return value.replace(/_/g, " ").toUpperCase();
};

export const renderLoader = (
  <LoadingOutlined
    style={{ fontSize: 45, textAlign: "center", alignSelf: "center" }}
  />
);

export const renderNoDataPlaceholder = (isLoading, msg, icon) => (
  <div
    style={{
      minHeight: "50vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {icon && icon}
    <br />
    {isLoading ? <></> : msg ? msg : <h4>No Data Found</h4>}
  </div>
);

export const uploadSingleFileToAWS = async (file) => {
  try {
    const fileType = file.type;
    let mimeType = fileType.split("/");
    mimeType = mimeType[mimeType.length - 1];
    const response = await getSignUrl(mimeType);
    const requestObject = {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file,
    };

    const awsresposne = await fetch(response.data.data, requestObject);
    return awsresposne;
  } catch (error) {
    console.log("error", error);
  }
};

export const validateSelectedFile = async (file) => {
  const MAX_FILE_SIZE = 5120; // 5MB
  const fileSizeKiloBytes = file.size / 1024;
  const { width, height } = await getImageDimensions(file);

  if (width < 500 || height < 350) {
    NotificationManager.error("Minimum dimension for image 500x350");
    return false;
  }

  if (fileSizeKiloBytes > MAX_FILE_SIZE) {
    NotificationManager.error("File size is greater than 5MB");
    return false;
  }
  return true;
};

export const getImageDimensions = (file) => {
  var _URL = window.URL || window.webkitURL;
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () =>
      resolve({
        width: img.width,
        height: img.height,
      });
    img.onerror = (error) => reject(error);
    img.src = _URL.createObjectURL(file);
  });
};

export const validateSelectedVideoFile = async (file) => {
  const MAX_FILE_SIZE = 256000; // 50MB
  const fileSizeKiloBytes = file.size / 1024;
  const duration = await getVideoDuration(file);

  if (duration.toFixed(0) > 60) {
    NotificationManager.error("Video must be shorter than 60 seconds");
    return false;
  }

  if (fileSizeKiloBytes > MAX_FILE_SIZE) {
    NotificationManager.error("File size is greater than 50MB");
    return false;
  }
  return true;
};

export const getVideoDuration = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result);
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });

const uploadFileToAWS = async (
  singedUrl,
  file,
  mimeType,
  isDocument = false
) => {
  try {
    const requestObject = {
      method: "PUT",

      body: file,
    };
    if (!isDocument) {
      requestObject.headers = {
        "Content-Type": mimeType,
      };
    }
    const awsresposne = await fetch(singedUrl, requestObject);
    return awsresposne;
  } catch (error) {
    console.log("error in aws uplaod", error);
  }
};

export const uploadFilesToAWS = async ({ files, hasCover, isDocument }) => {
  if (!files && !files?.length) return;
  let imageUrls = [];
  const AWSFILES = files.map((item, index) => {
    return new Promise(async (resolve, reject) => {
      const path = item.url.type.split("/");
      const fileType = path[path.length - 1];
      const isVideo =
        [
          "webm",
          "mpg",
          "mp2",
          "mpeg",
          "mpe",
          "mpv",
          "ogg",
          "mp4",
          "m4p",
          "m4v",
          "avi",
          "wmv",
          "mov",
          "qt",
          "flv",
          "swf",
          "avchd",
        ].indexOf(fileType.toLowerCase()) > -1;
      let response = await getSignUrl({ type: fileType });
      const singedUrl = response.data.data;
      const AWSresponse = await uploadFileToAWS(
        singedUrl,
        item.url,
        fileType,
        isDocument
      );
      if (AWSresponse.status === 200) {
        const filePath = singedUrl.split("?");
        const fileName = filePath[0];
        const data = {
          file: fileName,
          fileType: isDocument ? fileType : isVideo ? "video" : "image",
        };
        imageUrls.push(data);
        if (!hasCover) {
          let index = 0;
          for (const fileObj of imageUrls) {
            if (!isVideoFiles(fileObj)) {
              imageUrls[index].fileType = "cover";
              break;
            }
            index++;
          }
        }
        resolve();
      } else {
        reject();
      }
    });
  });
  await Promise.all(AWSFILES);
  return imageUrls;
};

export const isVideoFiles = (file) => {
  if (file) {
    const path = file?.url?.type
      ? file?.url?.type.split("/")
      : file?.file.split(".");
    const mimeType = path[path.length - 1];
    return (
      [
        "webm",
        "mpg",
        "mp2",
        "mpeg",
        "mpe",
        "mpv",
        "ogg",
        "mp4",
        "m4p",
        "m4v",
        "avi",
        "wmv",
        "mov",
        "qt",
        "flv",
        "swf",
        "avchd",
      ].indexOf(mimeType.toLowerCase()) > -1
    );
  }
};

export const loginRedirect = (props) => {
  const token = localStorage.getItem("userToken");
  return token ? (
    <Redirect to={"/admin/dashboard"} />
  ) : (
    <LoginForm {...props} />
  );
};

export const stringifyNumber = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
};

export const roundToTwo = (num) => {
  return +(Math.round(num + "e+2") + "e-2");
};

export const handleDownloadFiles = (filePath, fileName = "file") => {
  const downloadLink = document.createElement("a");
  downloadLink.href = filePath;
  downloadLink.download = fileName; // Use the provided file name
  downloadLink.click();
  successMessage({ message: `${fileName} downloaded successfully.` });
};

export const SampleFileDownload = (
  filePath,
  fileNameWithExtention = "SampleFile.pdf"
) => {
  fetch(filePath)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileNameWithExtention; // Set the desired file name
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      successMessage({
        message: `${fileNameWithExtention} downloaded successfully.`,
      });
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
};

export const formatDateToString = (date) => {
  if (date instanceof Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return date; // If it's not a Date, return it as is
};

const isDallasInDaylightSavingTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const dstStart = new Date(`03/14/${year} 02:00:00`);
  const dstEnd = new Date(`11/07/${year} 02:00:00`);
  return now > dstStart && now < dstEnd;
};

export const convertTimeZoneOffset = (dateTimeString) => {
  const dallasTimezoneOffset = isDallasInDaylightSavingTime()
    ? "-05:00"
    : "-06:00";

  // Split the date-time string into date, time, and offset parts
  const parts = dateTimeString.split("T");
  if (parts.length === 2) {
    const datePart = parts[0];
    const timeOffsetPart = parts[1];

    // Replace the existing offset with the Dallas offset
    const updatedDateTimeString =
      datePart +
      "T" +
      timeOffsetPart.replace(/[-+]\d{2}:\d{2}/, dallasTimezoneOffset);

    return updatedDateTimeString;
  }
  console.log(dateTimeString);

  return dateTimeString; // Return as is if the format is unexpected
};

// export const convertTimeZoneOffset = (dateTimeString) => {
//   // Split the date-time string into date, time, and offset parts
//   const parts = dateTimeString.split('T');
//   if (parts.length === 2) {
//     const datePart = parts[0];
//     const timeOffsetPart = parts[1];

//     // Check if the offset is positive
//     if (timeOffsetPart.includes('+')) {
//       // Replace '+' with '-' to change from positive to negative offset
//       const updatedDateTimeString = datePart + 'T' + timeOffsetPart.replace('+', '-');
//       return updatedDateTimeString;
//     }
//   }
//   return dateTimeString; // Return as is if the offset is already negative or in an unexpected format
// }

// export const excelSerialToJSDate = (serial) => {
//   const utcDays = Math.floor(serial - 25569); // Adjust for Excel's date epoch (January 1, 1970)
//   const millisecondsPerDay = 24 * 60 * 60 * 1000; // Milliseconds in a day
//   const milliseconds = utcDays * millisecondsPerDay;
//   const date = new Date(milliseconds);
//   return date;
// };

export const excelSerialToJSDate = (
  serial,
  hours = 0,
  minutes = 0,
  seconds = 0
) => {
  const utcDays = Math.floor(serial - 25569); // Adjust for Excel's date epoch (January 1, 1970)
  const millisecondsPerDay = 24 * 60 * 60 * 1000; // Milliseconds in a day
  const milliseconds =
    utcDays * millisecondsPerDay +
    hours * 60 * 60 * 1000 +
    minutes * 60 * 1000 +
    seconds * 1000;
  const date = new Date(milliseconds);
  return date;
};
