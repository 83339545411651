import { EMPTY_NULL_DATA } from "utils/constants/const";
import moment from "moment-timezone";
import { statusConditionalColors } from "utils/constants";

export const dallasTimezone = "America/Chicago";
export const defaultValueDate = moment.tz(dallasTimezone);

// B-0001 highest
// B-0002 won but not paid / pending
// B-0003 won and also paid / completed
// B-0004 Lost (Out Bid)
// B-0005 lost the bid / failed / winner not paid

export const addDebounce = (fn, delay) => {
  let timer;
  return (() => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(), delay);
  })();
};

export const onChangeCustomDebounce = (
  delay = 2000,
  setCheck = "Set Bolean state",
  value = "Here you need to pass value of the onChange like e.target.value",
  eventStateValue = "Your state value which you have to setState(e.target.value)"
) => {
  setTimeout(() => {
    setCheck(true);
  }, delay);
  if (eventStateValue?.length > value?.length) {
    setCheck(false);
  }
  if (eventStateValue.length + 1 === value.length) {
    setCheck(false);
  }
  // Note:
  // Use this check settled by
  // setCheck state === true
  // then call the function using it to as dependency.
};

export const setRules = (title) => {
  return [
    {
      required: true,
      message: `${title} is required`,
    },
  ];
};

export const sortCol = (a, b, dataIndex) => {
  if (a[dataIndex]?.length > 0 && b[dataIndex]?.length > 0) {
    return a[dataIndex].length - b[dataIndex].length;
  } else {
    return null;
  }
};

export const renderItemDataOrEmptyNull = (text, fixedDigits) => {
  if (text) {
    if (typeof text === "number" && !isNaN(text)) {
      if (typeof fixedDigits === "number") {
        return numberWithCommas(text?.toFixed(fixedDigits));
      } else {
        return numberWithCommas(text);
      }
    } else {
      return text;
    }
  } else {
    if (text === 0) {
      return 0;
    } else {
      return EMPTY_NULL_DATA;
    }
  }
};

export function numberWithCommas(value) {
  if (
    value === "NaN" ||
    Number.isNaN(value) ||
    value === "" ||
    value === undefined
  ) {
    return "";
  } else {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export const copyToClipboard = (text) => {
  var textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

export const scrollToElement = (ref) => {
  if (ref.current) {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }
};

export const dallasTime = (inputDate) => {
  // Set the time zone for Dallas
  const timeZone = "America/Chicago";
  // Create a formatter for the specified time zone
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
  // Format the inputDate in Dallas time zone
  const time = formatter.format(inputDate);
  const parts = time.split(/[\/,:]/);
  const dateTime = `${parts[2]}-${parts[0]}-${parts[1]}T${parts[3]}:${parts[4]}:${parts[5]}Z`;
  return dateTime.replace(/\s/g, ""); // Remove any spaces from the formatted date and time
};

export const subtractFiveHoursFromISO = (isoDateTime) => {
  const date = new Date(isoDateTime); // Create a Date object from the ISO string
  date.setHours(date.getHours() - 5); // Subtract 5 hours

  // Convert the adjusted date back to ISO 8601 format
  const adjustedISO = date.toISOString();

  return adjustedISO;
};

export const formatDate = (dateInput, format = "DD-MM-YYYY") => {
  const date = moment(dateInput).format(format); // Current date and time
  return date;
};

// export const formatDate = (dateInput, format = "DD-MM-YYYY") => {
//   const date = moment.tz(dateInput, dallasTimezone); // Current date and time
//   const formattedDate = date.format(format); // Format the date as YYYY-MM-DD
//   return formattedDate;
// };

export const getTimeFromOperationsWithDays = (
  firstTime,
  lastTime,
  Operation = "-"
) => {
  let timeOperated = "";
  const startTime = new Date(firstTime);
  const endTime = new Date(lastTime);

  switch (Operation) {
    case "+":
      timeOperated = startTime + endTime;
      break;
    case "-":
      timeOperated = startTime - endTime;

      break;
    case "*": {
      timeOperated = startTime * endTime;
      break;
    }
    case "/":
      timeOperated = startTime / endTime;
      break;
    default:
      timeOperated = new Date();
  }

  return convertMillisecondsToDigitalTime(timeOperated);
};

export const convertMillisecondsToDigitalTime = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const days = Math.floor(totalSeconds / (24 * 60 * 60));
  const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
  const seconds = totalSeconds % 60;

  return `${days} days ${hours}:${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;
};

export const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const goBack = (res = null, props) => {
  if (res?.data?.statusCode) {
    props.history.goBack();
  } else if (res === null) {
    props.history.goBack();
  }
};

export const findGrade = (grade) => {
  switch (grade) {
    case "A":
      return 1;
    case "B":
      return 2;
    case "C":
      return 3;
    case "D":
      return 4;
    case "1":
      return "A";
    case "2":
      return "B";
    case "3":
      return "C";
    case "4":
      return "D";
    default:
      break;
  }
};

export const findAuctionTranslationObj = (arr, languageKey = "en") => {
  return arr.find((item) => item.languageCode === languageKey);
};

export const sortArrCompareMaxValues = (a, b, key = "value") => {
  return b[key] - a[key];
};

export const getTimeFromdateString = (dateString) => {
  // Convert the date string to a Date object
  const dateObj = new Date(dateString);

  // Extract the time from the Date object
  return dateObj.toTimeString().slice(0, 8);
};

// B-0001 highest
// B-0002 won but not paid
// B-0003 won and also paid
// B-0004 Lost (Out Bid)
// B-0005 lost the bid

export const findDataOnStatus = (status) => {
  switch (status) {
    case "B-0001":
      return "Open";
    case "B-0002":
      return "Won (Not Paid)";
    case "B-0003":
      return "Won (Paid)";
    case "B-0004":
      return "Lost (Out Bid)";
    default:
      return EMPTY_NULL_DATA;
  }
};

export const findAuctionPaymentStatus = (status) => {
  switch (status) {
    case "B-0001":
      return "Highest";
    case "B-0002":
      return "Pending";
    case "B-0003":
      return "Completed";
    case "B-0004":
      return "Failed";
    default:
      return EMPTY_NULL_DATA;
  }
};

export const renderStatusColor = (status) => {
  if (status === "") {
    return null;
  }
  if (status === "B-0001" || status === "Open" || status === "open") {
    return statusConditionalColors?.statusOpenColor;
  } else if (status === "B-0002") {
    return statusConditionalColors.statusWonNotPaidColor;
  } else if (status === "B-0003" || status === "won" || status === "Won") {
    return statusConditionalColors.statusWonColor;
  } else if (
    status === "B-0005" ||
    status === "B-0004" ||
    status === "lost" ||
    status === "Lost"
  ) {
    return statusConditionalColors.statusLostColor;
  } else if (status === "cancelled" || status === "Cancelled") {
    return statusConditionalColors.stautsCancelledColor;
  } else if (
    status === "declined" ||
    status === "Declined" ||
    status === "decline" ||
    status === "Decline"
  ) {
    return statusConditionalColors.statusDeclineColor;
  } else if (
    status === "approved" ||
    status === "Approved" ||
    status === "approve" ||
    status === "Approve"
  ) {
    return statusConditionalColors.statusApproveColor;
  } else return "";
};

export const renderStatusBackgroundColor = (status) => {
  if (status === "") {
    return null;
  }
  if (status === "B-0001" || status === "Open" || status === "open") {
    return statusConditionalColors?.statusOpenBgColor;
  } else if (status === "B-0002") {
    return statusConditionalColors.statusWonNotPaidBgColor;
  } else if (status === "B-0003" || status === "won" || status === "Won") {
    return statusConditionalColors.statusWonBgColor;
  } else if (
    status === "B-0005" ||
    status === "B-0004" ||
    status === "lost" ||
    status === "Lost"
  ) {
    return statusConditionalColors.statusLostBgColor;
  } else if (status === "cancelled" || status === "Cancelled") {
    return statusConditionalColors.stautsCancelledBgColor;
  } else if (
    status === "declined" ||
    status === "Declined" ||
    status === "decline" ||
    status === "Decline"
  ) {
    return statusConditionalColors.statusDeclineBgColor;
  } else if (
    status === "approved" ||
    status === "Approved" ||
    status === "approve" ||
    status === "Approve"
  ) {
    return statusConditionalColors.statusApproveBgColor;
  } else return "";
};
