import {
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Image,
  Col,
  Switch,
  Spin,
} from "antd";
import React, { useState } from "react";
import placeholderImage from "assets/img/default.png";
import style from "./style.module.scss";
import TableHeader from "components/TableHeader/TableHeader";
import { successMessage, requestErrorHandel } from "utils/helpers/helpers";
import { setRules } from "utils/common-functions";
import { renderLoader } from "utils/helpers/helpers";
import { postAuctionApi } from "apis/auction/AuctionApi";
import { useEffect } from "react";
import { getGradesApi } from "apis/user";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { getManageCategoriesApi } from "apis/manage-categories";
import { getManageAuctionsByIdApi } from "apis/auction/AuctionApi";
import { updateAuctionApi } from "apis/auction/AuctionApi";
import { goBack } from "utils/common-functions";
import { uploadSingleFileToAWS } from "utils/helpers/helpers";
import CustomButton from "components/custom-button";
import { getManufacturersApi } from "apis/manufacturere-models";
import { getModelsByManufacturerIdApi } from "apis/manufacturere-models";
import moment from "moment-timezone";
import { TO_FIXED_LENGTH } from "utils/constants/const";
import { initialTermsConditions } from "./const";
import { getGradeByCarriersdApi } from "apis/manage-categories";

const { Option } = Select;

const AuctionForm = (props) => {
  const { location } = props;
  const auctionData = location.state;
  const { TextArea } = Input;
  const noBids = location.state?.noBids;
  const [form] = Form.useForm();
  const dallasTimezone = "America/Chicago";
  const defaultValueDate = moment.tz(dallasTimezone);

  const [uploading, setUpLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFeature, setIsFeature] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [reportFile, setReportFile] = useState();
  const [singleAuctionData, setSingleAuctionData] = useState(null);
  const [isDisabledHasBids, setIsDisabledHasBids] = useState(false);
  const [manufacturerModelListData, setManufacturerModelListData] = useState(
    []
  );
  const [modelsByManufacturer, setModelsByManufacturer] = useState([]);
  const [gradesByCarrier, setGradesByCarrier] = useState([]);
  const [noOfUnits, setNoOfUnits] = useState("");
  const [initialUnitPrice, setinitialUnitPrice] = useState("");
  const [deliveryFees, setDeliveryFees] = useState("");
  const [carrierDefaultId, setCarrierDefaultId] = useState();
  const [selectedCarrer, setSelectedCarrer] = useState(null);
  const [selectedGrades, setSelectedGrades] = useState([]);

  const fetchData = async () => {
    const ctgryRes = await getManageCategoriesApi(1);
    if (ctgryRes?.data?.statusCode) {
      setCategoriesData(ctgryRes?.data?.data);
    }
  };

  const fetchManufacturerApi = async () => {
    let res = null;
    try {
      setLoading(true);
      const response = await getManufacturersApi(1, 10000);
      if (response.status === 200 && response.data?.data) {
        setManufacturerModelListData(response.data.data);
      } else {
        throw response;
      }
      res = response;
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  const fetchModelsOfManufacturerApi = async (id) => {
    let res = null;
    try {
      setLoading(true);
      const response = await getModelsByManufacturerIdApi(id);
      if (response.status === 200 && response.data?.data) {
        setModelsByManufacturer(response.data.data);
      } else {
        throw response;
      }
      res = response;
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  const fetchGradesOfCarriersApi = async (id) => {
    let res = null;
    try {
      setLoading(true);
      const response = await getGradeByCarriersdApi(id);
      if (response.status === 200 && response.data?.data) {
        setGradesByCarrier(response.data.data);
      } else {
        throw response;
      }
      res = response;
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  const fetchSingleUser = async () => {
    let res = null;
    try {
      setLoading(true);
      const response = await getManageAuctionsByIdApi(auctionData?.id);
      setLoading(false);
      res = response.data?.data;
      if (response.status === 200 && response.data?.data) {
        setSingleAuctionData(response.data?.data);
        setIsDisabledHasBids(response.data?.data?.bids?.length ? true : false);
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  useEffect(() => {
    if (!gradesByCarrier?.length) {
      fetchData();
    }
    fetchManufacturerApi();
  }, []);

  const auctionDataFill = async () => {
    await fetchSingleUser().then(async (res) => {
      if (res) {
        await fetchManufacturerApi().then(async () => {
          await fetchModelsOfManufacturerApi(res?.manufactureId).then(
            async (modelRes) => {
              if (modelRes?.data?.data?.length > 0) {
                await onSetFieldsValues(res);
              }
            }
          );
          await fetchGradesOfCarriersApi(res?.categoryId).then(
            async (modelRes) => {
              if (modelRes?.data?.data?.length > 0) {
                await onSetFieldsValues(res);
              }
            }
          );
        });
      }
    });
  };

  useEffect(() => {
    if (auctionData) {
      auctionDataFill();
    }
  }, [auctionData]);

  useEffect(() => {
    form.setFieldValue({
      gradeId: gradesByCarrier[0]?.id,
    });
  }, [gradesByCarrier, form]);

  const onSetFieldsValues = (res) => {
    setCarrierDefaultId(res?.category?.categoryTranslation[0].categoryId);
    form.setFieldsValue({
      categoryId: res?.category?.categoryTranslation[0].title,
      batchNo: res?.batchNo,
      serialNumber: Number(res?.serialNumber),
      sku: res?.sku,
      name: res?.name,
      deliveryFee: Number(res?.deliveryFee),
      gradeId: Number(res?.grade?.id),
      lastBidPrice: res?.lastBidPrice,
      lockedStatus: res?.lockedStatus,
      minIncrement: res?.minIncrement,
      price: res?.price,
      unitPrice: (Number(res?.price) / Number(res?.totalUnits))?.toFixed(
        TO_FIXED_LENGTH
      ),
      totalUnits: res?.totalUnits,
      startAt: moment(res?.startAt).tz(dallasTimezone),
      endAt: moment(res?.endAt).tz(dallasTimezone),
      memory: res?.memory,
      color: res?.color || "",
      manufactureId: Number(res?.manufactureId),
      modelId: Number(res?.modelId),
      reportFile: reportFile,
    });
    setReportFile(res?.reportFile);
    setIsFeature(res?.isFeature);
    setSelectedCarrer(res?.category?.id);
  };

  const renderHeader = (title) => {
    return (
      <>
        <TableHeader
          onAddNew={() => {}}
          headerTitle={title}
          headerBtnTitle={""}
          loading={false}
        />
      </>
    );
  };

  const onSubmit = async (values) => {
    setLoading(true);
    if (singleAuctionData) {
      // updating auction
      const payload = { ...values, isFeature };
      payload["manufactureId"] = Number(values?.manufactureId);
      payload["modelId"] = Number(values?.modelId);
      payload["serialNumber"] = Number(values?.serialNumber);
      payload["sku"] = values?.sku;
      payload["name"] = values?.name;
      payload["startAt"] = values?.startAt.format();
      payload["endAt"] = values?.endAt.format();
      payload["totalUnits"] = Number(values?.totalUnits);
      // payload["categoryId"] = Number(values?.categoryId);
      payload["categoryId"] =
        typeof values?.categoryId === "number" && !isNaN(values?.categoryId)
          ? Number(values?.categoryId)
          : parseInt(carrierDefaultId);
      payload["unitPrice"] = Number(values?.unitPrice);
      payload["gradeId"] = Number(values?.gradeId) || gradesByCarrier[0]?.id;
      payload["price"] = Number(values?.price);
      payload["minIncrement"] = Number(values?.minIncrement);
      payload["deliveryFee"] = Number(values?.deliveryFee);
      payload["reportFile"] = reportFile ? reportFile : "";
      payload["lockedStatus"] = values?.lockedStatus || "locked";
      payload["id"] = singleAuctionData?.id;
      try {
        await updateAuctionApi(payload).then((res) => {
          if (res.status === 200) {
            goBack(res, props);
            successMessage({ message: "Auction has been updated" });
          }
        });
      } catch (e) {
        requestErrorHandel({ error: e });
      } finally {
        setLoading(false);
      }
    } else {
      // creating auction
      const payload = { ...values, isFeature };
      payload["manufactureId"] = Number(values?.manufactureId);
      payload["modelId"] = Number(values?.modelId);
      payload["serialNumber"] = Number(values?.serialNumber);
      payload["sku"] = values?.sku;
      payload["name"] = values?.name;
      payload["startAt"] = values?.startAt.format();
      payload["endAt"] = values?.endAt.format();
      payload["totalUnits"] = Number(values?.totalUnits);
      payload["categoryId"] = Number(values?.categoryId);
      payload["unitPrice"] = Number(values?.unitPrice);
      payload["gradeId"] = Number(values?.gradeId) || gradesByCarrier[0]?.id;
      payload["price"] = Number(values?.price);
      payload["minIncrement"] = Number(values?.minIncrement);
      payload["deliveryFee"] = Number(values?.deliveryFee);
      payload["reportFile"] = reportFile ? reportFile : "";
      payload["lockedStatus"] = values?.lockedStatus || "locked";
      await createAuction(payload);
    }
    setLoading(false);
  };

  const createAuction = async (data) => {
    let res = null;
    try {
      setLoading(true);
      const response = await postAuctionApi(data);
      setLoading(false);
      res = response;
      if (response.status === 200) {
        successMessage({ message: "Auction created successfully" });
        props.history.goBack();
      } else throw response;
    } catch (e) {
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
      return res;
    }
  };

  const [imageName, setImageName] = useState("");

  const onChooseFileOfReport = async (e) => {
    try {
      if (e.target.files?.length) {
        setImageName(e.target.files[0].name);
        setUpLoading(true);
        const awsFile = await uploadSingleFileToAWS(e.target.files[0]);
        let splitUrl = awsFile.url.split("?");
        splitUrl = splitUrl[0];
        setReportFile(splitUrl);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    }
  };

  const onChangeManufacturer = async (e) => {
    await fetchModelsOfManufacturerApi(e);
  };

  useEffect(() => {
    if (selectedCarrer) {
      const selectedCategory = categoriesData?.find(
        (c) => c.id === Number(selectedCarrer)
      );
      if (selectedCategory) {
        setSelectedGrades(selectedCategory?.grades);
      }
    }
  }, [selectedCarrer]);

  const onChangeCarrier = async (e) => {
    form.resetFields(["gradeId"]);
    const selectedCategory = categoriesData?.find((c) => c.id === e);
    setSelectedCarrer(e);
    if (selectedCategory) {
      setSelectedGrades(selectedCategory?.grades);
    }
    // await fetchGradesOfCarriersApi(e);
  };

  return (
    <div className={style?.auctionFormContainer}>
      <Spin spinning={loading || uploading} indicator={renderLoader}>
        <Row justify={"space-between"} align={"center"}>
          <Row style={{ paddingTop: "20px" }}>
            {renderHeader(
              `${singleAuctionData ? "Update Auction" : "Create Auction"}`
            )}
          </Row>

          <div className={style.featuredAuctionSwitch}>
            <Switch
              // disabled={auctionData}
              checked={isFeature}
              onChange={(checked) => setIsFeature(checked)}
            />
            <label>Featured Auction</label>
          </div>
        </Row>

        <Form
          layout="vertical"
          className={style.createAuctionForm}
          form={form}
          name="formAuction"
          onFinish={onSubmit}
          // initialValues={{
          //   price: "",
          //   lockedStatus: "locked",
          //   startAt: defaultValueDate,
          //   endAt: defaultValueDate,
          //   // termConditions: initialTermsConditions,
          // }}
          onValuesChange={(e) => {
            form.setFieldsValue({
              price: (
                Number(e?.unitPrice || form.getFieldValue("unitPrice")) *
                Number(e?.totalUnits || form.getFieldValue("totalUnits"))
              )?.toFixed(TO_FIXED_LENGTH),
            });
          }}
        >
          <Row justify="space-between" gutter={[20]}>
            <Col md={12} xs={24}>
              <Form.Item
                rules={setRules("Name/Description")}
                label={"Name/Description"}
                name={"name"}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                rules={setRules("Carrier")}
                label={"Carrier"}
                name={"categoryId"}
              >
                <Select onChange={onChangeCarrier}>
                  {categoriesData?.length > 0 &&
                    categoriesData?.map((item) => {
                      return (
                        <Option value={item?.id}>
                          {renderItemDataOrEmptyNull(item?.title)}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                rules={setRules("Batch No")}
                label={"Batch No"}
                name={"batchNo"}
              >
                <Input disabled={isDisabledHasBids} type="text" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                rules={setRules("Serial ID")}
                label={"Serial ID"}
                name={"serialNumber"}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item rules={setRules("Sku")} label={"Sku"} name={"sku"}>
                <Input type="text" />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                rules={setRules("Manufacturer")}
                label={"Manufacturer"}
                name={"manufactureId"}
              >
                <Select
                  // disabled={auctionData}
                  onChange={onChangeManufacturer}
                >
                  {manufacturerModelListData?.length > 0 &&
                    manufacturerModelListData?.map((item) => {
                      return (
                        <Option value={Number(item?.id)}>
                          {renderItemDataOrEmptyNull(item?.name)}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                rules={setRules("Start Time")}
                label={"Start Date"}
                name={"startAt"}
              >
                <DatePicker
                  disabled={isDisabledHasBids}
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  onChange={(e) => {
                    if (e === null) {
                      form.setFieldsValue({
                        startAt: moment.tz(new Date(), dallasTimezone),
                      });
                    } else {
                      form.setFieldsValue({
                        startAt: moment(e).tz(dallasTimezone),
                      });
                    }
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                rules={setRules("End Time")}
                label={"End Time"}
                name={"endAt"}
              >
                <DatePicker
                  showTime
                  defaultValue={defaultValueDate}
                  onChange={(e) => {
                    if (e === null) {
                      form.setFieldsValue({
                        endAt: moment(new Date()).tz(dallasTimezone),
                      });
                    } else {
                      form.setFieldsValue({
                        endAt: moment(e).tz(dallasTimezone),
                      });
                    }
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label={"Lock Status"}
                name={"lockedStatus"}
                rules={setRules("Lock Status")}
              >
                <Select defaultValue={"locked"}>
                  <Option value={"locked"}>{"Locked"}</Option>
                  <Option value={"unLocked"}>{"Unlocked"}</Option>
                  <Option value={"notApplicable"}>{"Not Applicable"}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                rules={setRules("Grade")}
                label={"Grade"}
                name={"gradeId"}
              >
                <Select
                // defaultValue={gradesByCarrier[0]?.id}
                // value={gradesByCarrier[0]?.id}
                // disabled={auctionData}
                >
                  {categoriesData?.length > 0 &&
                    selectedGrades?.map((item) => {
                      return (
                        <Option value={item?.id}>
                          {renderItemDataOrEmptyNull(item?.title)}{" "}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                // className={style.forthFieldMargin}
                label={"No. of units"}
                rules={setRules("No. of units")}
                name={"totalUnits"}
              >
                <Input
                  //  disabled={auctionData}
                  type="number"
                  value={noOfUnits}
                  onChange={(e) => {
                    if (e.target.value?.length < 5) {
                      form.setFieldsValue({
                        totalUnits: e.target.value,
                      });
                      setNoOfUnits(e.target.value);
                    } else {
                      form.setFieldsValue({
                        totalUnits: e?.target?.value?.substring(0, 5),
                      });
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                // className={style.forthFieldMargin}
                label={"Initial Unit Price"}
                name={"unitPrice"}
                rules={setRules("Initial Unit Price")}
              >
                <Input
                  type="number"
                  onChange={(e) => {
                    setinitialUnitPrice(e.target.value);
                    form.setFieldsValue({
                      unitPrice: e.target.value,
                    });
                  }}
                  value={initialUnitPrice}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" align={"center"} gutter={[20]}>
            <Col md={12} xs={24}>
              <Form.Item
                rules={setRules("Starting Amount")}
                label={"Starting Amount"}
                name={"price"}
              >
                {/* <Input disabled={isDisabledHasBids} type="number" /> */}
                <Input disabled={true} type="number" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                rules={setRules("Min Increment")}
                label={"Minimum Increment"}
                name={"minIncrement"}
              >
                <Input
                  //  disabled={auctionData}
                  type="number"
                />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label={"Delivery Fees"}
                rules={setRules("Delivery Fees")}
                name={"deliveryFee"}
              >
                <Input
                  // disabled={auctionData}
                  type="number"
                  value={deliveryFees}
                  onChange={(e) => {
                    if (e.target.value?.length < 5) {
                      form.setFieldsValue({
                        deliveryFee: e.target.value,
                      });
                      setDeliveryFees(e.target.value);
                    } else {
                      form.setFieldsValue({
                        deliveryFee: e?.target?.value?.substring(0, 5),
                      });
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row justify="space-between">
            <CustomButton
              title={"Cancel"}
              style={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
              }}
              onClick={() => {
                goBack(null, props);
              }}
            />
            <CustomButton
              htmlType="submit"
              disabled={loading || uploading}
              loading={loading || uploading}
              variant={!singleAuctionData && "two"}
              style={{ backgroundColor: "#36454f", color: "#fff" }}
              title={
                loading
                  ? singleAuctionData
                    ? "Updating"
                    : "Saving..."
                  : uploading
                  ? "Uploading..."
                  : singleAuctionData
                  ? "Update"
                  : "Save"
              }
            />
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default AuctionForm;
