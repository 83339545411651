export const initialTermsConditions = `GENERAL TERMS AND CONDITIONS EXHIBIT FOR MASTER AGENCY AND EQUIPMENT PREPARATION AGREEMENT 
A. Nonpayment. In the event the Payment Amount is not timely delivered, Agent shall have all rights and remedies available to it under law or equity, and the 
election of any right shall not preclude or eliminate Agent's ability to exercise any other right simultaneously or later. Without limiting the foregoing, in the event of Principal's failure to timely deliver the entire Payment Amount: (1) Agent may sell the involved
CPE units to another person, or put the involved CPE units into Agent's own retail
inventory or any combination thereof; and (2) Agent is not required to seek the highest
or a reasonable price for the CPE units, nor is Agent required to advertise the
availability of the CPE units for purchase. Any actions by Agent to ameliorate its
damages due to non-delivery of the Payment Amount shall be without prejudice to
Agent's right to obtain the remainder of its damages from the Principal, or to obtain
any other relief in law or equity.
B. CPE Units Sold As-Is. Principal understands and acknowledges that
Auction Conductor sells the CPE Units on an as-is basis, with no warranties except
title, and that Principal is taking the CP Units on the same as-is basis, with no
warranties from Agent, except: (1) upon Agent's timely receipt of the entire Payment
Amount and Agent's physical receipt of the CPE Units at the Facility, Agent warrants
Principal's title to the CPE Units; and (2) Agent warrants that upon completion of the
Preparation, the personal information of the former end users shall have been wiped
from the CPE Units.
C. Principal Covenant re Resale/Export. Principal covenants not to sell
or otherwise convey, directly or indirectly, any CPE acquired by Principal pursuant to
this Agreement, to any country to which such resale or other reconveyance is
prohibited or otherwise restricted under the export laws of the United States of
America, or any agent of any such prohibited/restricted country.
D. Injunctive Relief. In addition to and not in lieu of any other remedies
available to Agent, Agent shall have the right to equitable relief in the event of
Principal's violation of any of its covenants hereunder, including without limitation
specific performance and temporary and permanent injunctive relief.
E. Choice of Law/Venue. This Agreement is to be interpreted under the
laws of the state of TEXAS, without regard to its law concerning choice of law. Venue
in any action respecting this Agreement, including without limitation any suit alleging
breach hereof, shall be in the courts of general jurisdiction of Dallas County, Texas, or
in the federal courts.
F. Costs. Each party has incurred and is responsible for its own costs for
the negotiation and performance of this Agreement. In the event of any suit arising out
of or alleging a breach of this Agreement, then, in addition to and not in lieu of any
other remedies, the party found by the court to have substantially prevailed in the
litigation shall be entitled to an award for all of its reasonable costs of litigation,
including without limitation reasonable attorneys' fees. G. Term. This Agreement shall be in effect for one year from the date set
forth herein, unless earlier terminated pursuant to this Section, and shall automatically
renew for successive one-year periods unless one party timely sends to the other party
a notice of non-renewal at least thirty days prior to the then expiration date. Either
party may, by notice sent to the other party, terminate this Agreement in the event of:
(1) The bankruptcy or other insolvency of the other party hereto (including
without limitation any filing under Chapter 11 of the Bankruptcy Code); or
(2) A material breach of this Agreement which is not cured timely, where the
cure period is one business day with respect to the delivery of the Payment
Amount (such cure to include not only the Payment Amount but any amount
needed to cover Agent's damages incurred due to the delay in payment), and
ten (10) business days with respect to any other breach hereof.
H. Interest. Interest shall accrue on any amounts due hereunder and not
timely delivered, at the lower of: (1) the highest rate allowed by law; or (2) twelve
percent per annum, compounded monthly. Interest shall be in addition to, and not in
lieu of, any other remedies.
I. Confidentiality. The parties hereto acknowledge and determine that
any oral or written information exchanged between them in connection with this
Agreement is confidential. The parties hereto shall keep all such information
confidential and shall not disclose any of such information to any third party without
the prior written consent of the other party, except that: (a) the information is required
by applicable laws or regulations to be disclosed; or (b) the information needs to be
disclosed by a party to its board of directors or legal or financial advisors for the
purpose of the transaction under this Agreement, provided that the directors and legal
or financial advisors are also held obligated to keep confidentiality in a way similar to
this confidentiality provision. The leak of the information, by a staff member or a
recruited agency of a party hereto, shall be deemed as a leak by this party, in which
case this party shall be liable for breach of contract in accordance with this Agreement.
This provision shall survive regardless of the termination of this Agreement for a period
of one (1) year.
J. Construction. Each party participated in the negotiation and drafting of
this Agreement; is not to be construed against either party.`