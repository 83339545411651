// import { useEffect, useState } from "react";
// import moment from "moment-timezone";

// const TimeRemainingTimer = ({ endAt }) => {
//   const [timeRemaining, setTimeRemaining] = useState("");
//   const dallasTimezone = "America/Chicago";

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       const currentTime = moment.tz(Date.now(), dallasTimezone);
//       const endsAtTime = moment.tz(endAt, dallasTimezone);

//       const duration = moment.duration(endsAtTime.diff(currentTime));

//       if (duration.asSeconds() <= 0) {
//         // The auction has ended, so clear the timer
//         clearInterval(intervalId);
//         setTimeRemaining("Expired");
//         // setTimeRemaining('0d:0h:0m:0s');
//         return;
//       }

//       const days = duration.days();
//       const hours = duration.hours();
//       const minutes = duration.minutes();
//       const seconds = duration.seconds();

//       let remainingTime = "";

//       if (days > 0) {
//         remainingTime = `${days}d:`;
//       }

//       remainingTime += `${hours}h:${minutes}m:${seconds}s`;
//       setTimeRemaining(remainingTime);
//     }, 1000);

//     return () => {
//       // Clear the interval when the component unmounts
//       clearInterval(intervalId);
//     };
//   }, [endAt]);

//   return timeRemaining;
// };

// export default TimeRemainingTimer;

// import { useEffect, useState } from "react";
// import moment from "moment-timezone";

// const TimeRemainingTimer = ({ endAt }) => {
//   const [timeRemaining, setTimeRemaining] = useState("");
//   const dallasTimezone = "America/Chicago";

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       const currentTime = moment.tz(Date.now(), dallasTimezone);
//       const endsAtTime = moment.tz(endAt, dallasTimezone);

//       const duration = moment.duration(endsAtTime.diff(currentTime));

//       if (duration.asSeconds() <= 0) {
//         // The auction has ended, so clear the timer
//         clearInterval(intervalId);
//         setTimeRemaining("Expired");
//         return;
//       }

//       const months = duration.months();
//       const days = duration.days();
//       const hours = duration.hours();
//       const minutes = duration.minutes();
//       const seconds = duration.seconds();

//       let remainingTime = "";

//       if (months > 0) {
//         remainingTime += `${months}M:`;
//       }
//       if (days > 0 || months > 0) {
//         remainingTime += `${days}d:`;
//       }
//       remainingTime += `${hours}h:${minutes}m:${seconds}s`;

//       setTimeRemaining(remainingTime);
//     }, 1000);

//     return () => {
//       // Clear the interval when the component unmounts
//       clearInterval(intervalId);
//     };
//   }, [endAt]);

//   return timeRemaining;
// };

// export default TimeRemainingTimer;

import { useEffect, useState } from "react";
import moment from "moment-timezone";

const TimeRemainingTimer = ({ endAt }) => {
  const [timeRemaining, setTimeRemaining] = useState("");
  const dallasTimezone = "America/Chicago";

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = moment.tz(Date.now(), dallasTimezone);
      const endsAtTime = moment.tz(endAt, dallasTimezone);

      const duration = moment.duration(endsAtTime.diff(currentTime));

      if (duration.asSeconds() <= 0) {
        // The auction has ended, so clear the timer
        clearInterval(intervalId);
        setTimeRemaining("Expired");
        return;
      }

      const years = duration.years();
      const months = duration.months();
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      let remainingTime = "";

      if (years > 0) {
        remainingTime += `${years}y:`;
      }
      if (months > 0 || years > 0) {
        remainingTime += `${months}M:`;
      }
      if (days > 0 || months > 0 || years > 0) {
        remainingTime += `${days}d:`;
      }
      remainingTime += `${hours}h:${minutes}m:${seconds}s`;

      setTimeRemaining(remainingTime);
    }, 1000);

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, [endAt]);

  return timeRemaining;
};

export default TimeRemainingTimer;
