import CommonTable from "components/Tables/CommonTable";
import { Input, Row, Select, Spin, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { getManageAuctionsApi } from "apis/auction/AuctionApi";
import { capitalizeString } from "utils/common-functions";
import { EyeOutlined } from "@ant-design/icons";
import { onChangeCustomDebounce } from "utils/common-functions";
import TimeRemainingTimer from "components/RemainingTimeTimer";

const AuctionStatusList = (props) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [auctionStatusListData, setAuctionStatusListData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [waitTimeCheck, setWaitTimeCheck] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState("")
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);


  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getManageAuctionsApi(page, pageSize, searchValue, filterByStatus);
      if (response.status === 200 && response.data?.data) {
        setAuctionStatusListData(response.data.data);
        setTotalNumberOfRecords(response.data?.total);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!auctionStatusListData) {
      fetchDataApi();
    }
  }, []);

  useEffect(() => {
    if (waitTimeCheck) {
      fetchDataApi();
    }
  }, [waitTimeCheck]);

  useEffect(() => {
    fetchDataApi();
  }, [page, pageSize, filterByStatus])

  const columnsData = [
    {
      title: "Auction ID",
      key: "manufacture",
      dataIndex: "manufacture",
      render: (text, row) => {
        return (
          <div
            onClick={() => {
              props.history.push("auction-detail", row);
            }}
            className={style.onClickName}
          >
            <span> {row?.identifier} </span>
          </div>
        );
      },
    },
    {
      title: "Batch No",
      key: "manufacture",
      dataIndex: "manufacture",
      render: (text, row) => {
        return (
          <div
          >
            <span>
              {renderItemDataOrEmptyNull(text?.batchNo)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Carrier",
      key: "manufacture",
      dataIndex: "manufacture",
      render: (text, row) => {
        return (
          <div
          >
            <span>
              {renderItemDataOrEmptyNull(row?.category?.title)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (text, row) => {
        return (
          <div
          >
            <span>
              {renderItemDataOrEmptyNull(row?.name)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Current Bid",
      key: "lastBidPrice",
      dataIndex: "lastBidPrice",
      render: (text, row) => {
        return (
          <Row className={style.amount} alignItem={"center"}>
            <p>$</p>
            {ColTextCheck(text, 2)}
          </Row>
        );
      },
    },
    {
      title: "Bids",
      key: "totalBids",
      dataIndex: "totalBids",
      render: ColTextCheck,
    },
    {
      title: "Payment Status",
      key: "isPaid",
      dataIndex: "isPaid",
      render: (text) => {
        return (
          <dvi>
            <span> {text ? "Paid" : "Pending"} </span>
          </dvi>
        );
      },
    },
    // {
    //   title: "Auction",
    //   key: "manufacture",
    //   dataIndex: "manufacture",
    //   render: (text, row) => {
    //     return (
    //       <div
    //       >
    //         <span>
    //           {renderItemDataOrEmptyNull(text?.name)} {" | "}
    //           {renderItemDataOrEmptyNull(row?.model?.name)}{" "}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Bidding Amount",
    //   key: "lastBidPrice",
    //   dataIndex: "lastBidPrice",
    //   render: (text) => {
    //     return (
    //       <Row className={style.amount} alignItem={"center"}>
    //         <p>$</p>
    //         {ColTextCheck(renderItemDataOrEmptyNull(text, 2))}
    //       </Row>
    //     );
    //   },
    // },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text) => {
        return (
          <span> {capitalizeString(renderItemDataOrEmptyNull(text))} </span>
        );
      },
    },
    {
      title: "Time Remaining",
      key: "email",
      dataIndex: "email",
      render: (_, row) => {
        return (
          <span>
            {/* {renderItemDataOrEmptyNull(
              getTimeFromOperationsWithDays(row?.endAt, row?.startAt, "-")
            )} */}
            <TimeRemainingTimer endAt={row?.endAt} />
          </span>
        );
      },
    },
    {
      title: "is featured",
      key: "isFeature",
      dataIndex: "isFeature",
      render: (text, row) => {
        return (
          <Switch
            // defaultChecked={text}
            checked={text}
          // onChange={(e) => {
          //   onChangeAuctionFeature(e, row);
          // }}
          />
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <Row className={style.actions}>
            <EyeOutlined
              style={{ color: "#899499", fontSize: "15px", cursor: "pointer" }}
              onClick={() => {
                props.history.push("auction-detail", row);
              }}
            />
          </Row>
        );
      },
    },
  ];

  const onChangeSearch = (e) => {
    setSearchValue(e.target.value);
    onChangeCustomDebounce(2000, setWaitTimeCheck, e.target.value, searchValue);
  };

  const onChangeStatus = (e) => {
    setFilterByStatus(e)
  }

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <Row
          justify={"end"}
          alignItem="center"
          className={style.searchBoxContainer}
        >
          <Select
            defaultValue=""
            style={{ minWidth: 150 }}
            onChange={onChangeStatus}
            className={style.selectBox}
            options={[
              { value: "", label: "Status" },
              { value: "open", label: "Open" },
              { value: "won", label: "Won" },
              { value: "cancelled", label: "Cancelled" },
              { value: "close", label: "Close" },
            ]}
          />
          <Input
            placeholder="Search Auction Status"
            value={searchValue}
            onChange={onChangeSearch}
            style={{ width: "22%", height: "40px", fontSize: "15px" }}
          />
        </Row>

        <CommonTable
          className={style.customerTable}
          data={auctionStatusListData}
          fetchRecords={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
          pageSize={pageSize}
        />
      </Spin>
    </div>
  );
};

export default AuctionStatusList;
