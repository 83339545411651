import CommonTable from "components/Tables/ManageAuctionTable";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
} from "antd";

import React, { useEffect, useRef, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import { getManageAuctionsApi } from "apis/auction/AuctionApi";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import DeleteRedIcon from "assets/img/red/delete.svg";
import { capitalizeString } from "utils/common-functions";
import CustomButton from "components/custom-button";
import CustomModal from "components/CustomModal/CustomModal";
import { setAuctionFeatureApi } from "apis/auction/AuctionApi";
import * as XLSX from "xlsx";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import CommonDeleteModal from "components/common-delete-modal";
import { deleteManageAuctionsByIdApi } from "apis/auction/AuctionApi";
import { EyeOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import SampleAuctionFile from "../../../assets/files/sample-auction.xlsx";
import { Link } from "react-router-dom";
import { onChangeCustomDebounce } from "utils/common-functions";
import { postAuctionApiByCSV } from "apis/auction/AuctionApi";
import moment from "moment-timezone";
import TimeRemainingTimer from "components/RemainingTimeTimer";
import { formatDateToString } from "utils/helpers/helpers";
import { convertTimeZoneOffset } from "utils/helpers/helpers";
import { getManageCategoriesApi } from "apis/manage-categories";
import { _deleteAuctionsList } from "apis/auction/AuctionApi";
import { _getcarriersList } from "apis/auction/AuctionApi";
import { useDispatch } from "react-redux";
import { _carrierListingsAction } from "actions/auctions/auctions-details";
import { _getBatchNoList } from "apis/auction/AuctionApi";
const ManageAuctionList = (props) => {
  const fileInputRef = useRef(null);
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [
    originalManageAuctionsListApiRes,
    setOriginalManageAuctionsListApiRes,
  ] = useState(null);
  const [manageAuctionsListData, setManageAuctionsListData] = useState(null);
  // console.log(manageAuctionsListData, "manageauctionList");
  const [loading, setLoading] = useState(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [uploading, setUpLoading] = useState(false);
  const [auctionPayloadExcel, setAuctionPayloadExcel] = useState([]);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [waitTimeCheck, setWaitTimeCheck] = useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [manageCategoriesListData, setManageCategoriesListData] =
    useState(null);
  const [filterListingsData, setFilterListingsData] = useState({
    carrierList: [],
    batchNoList: [],
  });
  const [filters, setFilters] = useState({
    Status: "",
    batchNo: "",
    carrier: "",
  });
  const [importCategoryId, setImportCategoryId] = useState("");
  const [importBatchNo, setImportBatchNo] = useState("");
  const handleChange = (key) => (value) => {
    setFilters((prevFilters) => {
      // Check if carrier has changed
      if (key === "carrier" && prevFilters.carrier !== value) {
        // Clear batchNo when carrier changes
        return {
          ...prevFilters,
          [key]: value,
          batchNo: "", // Clear batchNo
        };
      }
      // Update other key-value pairs
      return {
        ...prevFilters,
        [key]: value,
      };
    });
    setIsFilterChanged(true);
  };

  useEffect(() => {
    if (!manageCategoriesListData) {
      fetchCategoriesApi();
    }
  }, []);

  const fetchCategoriesApi = async () => {
    try {
      setLoading(true);
      const response = await getManageCategoriesApi(
        page,
        pageSize,
        searchValue
      );
      if (response.status === 200 && response.data?.data) {
        setManageCategoriesListData(response.data.data);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  const fetchCarriersList = async () => {
    try {
      setLoading(true);
      const response = await _getcarriersList();
      if (response.status === 200 && response.data?.data) {
        dispatch(_carrierListingsAction(response.data?.data));
        setFilterListingsData((prevState) => ({
          ...prevState,
          carrierList: response?.data?.data || [],
        }));
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  const fetchBatchNosList = async () => {
    try {
      setLoading(true);
      const response = await _getBatchNoList(filters?.carrier);
      if (response.status === 200 && response.data?.data) {
        dispatch(_carrierListingsAction(response.data?.data));
        setFilterListingsData((prevState) => ({
          ...prevState,
          batchNoList: response?.data?.data || [],
        }));
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  const fetchDataApi = async () => {
    try {
      if (!isFilterChanged && filters["Status"] == "") {
        filters["Status"] = "Open";
      }
      setLoading(true);
      const response = await getManageAuctionsApi(
        page,
        pageSize,
        searchValue,
        filters?.Status,
        filters?.batchNo,
        filters?.carrier
      );
      if (response.status === 200 && response.data?.data) {
        setManageAuctionsListData(response.data.data);
        setTotalNumberOfRecords(response.data?.total);
        setOriginalManageAuctionsListApiRes(response?.data?.data);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
    fetchCarriersList();
    if (filters?.carrier !== "") fetchBatchNosList(filters?.carrier);
  }, [filters]);

  useEffect(() => {
    if (waitTimeCheck) {
      fetchDataApi();
    }
  }, [waitTimeCheck]);

  useEffect(() => {
    fetchDataApi();
  }, [page, pageSize, filters]);

  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  const onSubmit = async (values) => {
    const payload = {
      categoryId: importCategoryId,
      batchNo: importBatchNo,
      auctions: auctionPayloadExcel,
    };
    await createAuction(payload);
    form.resetFields();
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const onSuccessApi = () => {
    fetchDataApi();
    setVisibleModal(false);
  };

  const onChangeAuctionFeature = async (check, row) => {
    try {
      setLoading(true);
      if (row) {
        const response = await setAuctionFeatureApi(row?.id);
        if (response.status === 200 && response.data?.data) {
          onSuccessApi();
        } else {
          throw response;
        }
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  const onReadExcelSheet = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const reader = new FileReader();
        reader.onload = async (event) => {
          const workbook = XLSX.read(event.target.result, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          const formatDateTimeString = (cell) => {
            if (cell.t === "d") {
              const dateObj = XLSX.SSF.parse_date_code(cell.v);
              if (dateObj.H || dateObj.M || dateObj.S) {
                const year = dateObj.Y;
                const month = String(dateObj.M).padStart(2, "0");
                const day = String(dateObj.D).padStart(2, "0");
                const hour = String(dateObj.H).padStart(2, "0");
                const minute = String(dateObj.M).padStart(2, "0");
                const second = String(dateObj.S).padStart(2, "0");
                return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
              } else {
                // Date without time
                return formatDateToString(dateObj);
              }
            }
            return cell.v;
          };

          const data = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            raw: false,
            dateNF: "YYYY-MM-DD HH:mm:ss", // Format for date-time cells
            cellDates: true,
            dateGetter: formatDateTimeString,
          });

          await makePayloadReady(data)
            .then((auctionPayload) => {
              setAuctionPayloadExcel(auctionPayload);
            })
            .catch((error) => {
              console.error("An error occurred:", error);
            });
        };
        reader.readAsBinaryString(e.target.files[0]);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    }
  };

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const onSelectAllAuctionIdsChange = (e) => {
    setSelectedRowIds(
      e.target.checked
        ? manageAuctionsListData
            .filter((item) => item.totalBids === 0)
            .map((item) => item.id)
        : []
    );
  };

  const onSelectIndividualAuctionIds = (record, selected) => {
    const selectedRows = [...selectedRowIds];
    if (selected) {
      selectedRows.push(record.id); // Assuming each row has a unique key property
    } else {
      const index = selectedRows.indexOf(record.id);
      if (index > -1) {
        selectedRows.splice(index, 1);
      }
    }
    setSelectedRowIds(selectedRows);
  };
  const columnsData = [
    {
      title: (
        <Checkbox
          className={style.checkbox_style}
          indeterminate={
            selectedRowIds?.length > 0 &&
            selectedRowIds?.length <
              manageAuctionsListData?.filter((item) => item.totalBids === 0)
                .length
          }
          checked={isSelectAllChecked}
          // checked={
          //   selectedRowIds?.length ===
          //   manageAuctionsListData?.filter((item) => item.totalBids === 0)
          //     .length
          // }
          onChange={onSelectAllAuctionIdsChange}
          disabled={
            manageAuctionsListData?.filter((item) => item.totalBids === 0)
              .length === 0
          }
        />
      ),
      dataIndex: "checkbox",
      render: (_, row) =>
        row?.totalBids === 0 && (
          <Checkbox
            onChange={(e) =>
              onSelectIndividualAuctionIds(row, e.target.checked)
            }
            checked={selectedRowIds?.includes(row.id)}
          />
        ),
    },

    {
      title: "Auction ID",
      key: "manufacture",
      dataIndex: "manufacture",
      render: (text, row) => {
        return (
          <div className={style.onClickName}>
            <span
              onClick={() => {
                props.history.push("auction-detail", { id: row?.id });
              }}
            >
              {row?.identifier}
            </span>
          </div>
        );
      },
    },
    {
      title: "Serial ID",
      key: "serialNumber",
      dataIndex: "serialNumber",
      render: (text, row) => {
        return <div>{renderItemDataOrEmptyNull(row?.serialNumber)}</div>;
      },
    },
    {
      title: "Batch No",
      key: "manufacture",
      dataIndex: "manufacture",

      render: (text, row) => {
        return (
          <div style={{ width: 100 }}>
            <p>{renderItemDataOrEmptyNull(row?.batchNo)}</p>
          </div>
        );
      },
    },
    {
      title: "Sku",
      key: "sku",
      dataIndex: "sku",
      render: (text, row) => {
        return <div>{renderItemDataOrEmptyNull(row?.sku)}</div>;
      },
    },

    {
      title: "Carrier",
      key: "manufacture",
      dataIndex: "manufacture",
      render: (text, row) => {
        return (
          <div>
            <p>{renderItemDataOrEmptyNull(row?.category?.title)}</p>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "manufacture",
      dataIndex: "manufacture",
      render: (text, row) => {
        return (
          <div>
            <p>{renderItemDataOrEmptyNull(row?.name)}</p>
          </div>
        );
      },
    },
    {
      title: "Quantity",
      key: "quantity",
      dataIndex: "quantity",
      render: (text, row) => {
        return (
          <div>
            <p>{renderItemDataOrEmptyNull(row?.totalUnits)}</p>
          </div>
        );
      },
    },
    {
      title: "Current bid",
      key: "lastBidPrice",
      // dataIndex: "lastBidPrice",
      render: (item) => {
        return (
          <Row className={style.amount} alignItem={"center"}>
            <p>$</p>
            {ColTextCheck(
              renderItemDataOrEmptyNull(
                item?.lastBidPrice / item?.totalUnits,
                2
              )
            )}
          </Row>
        );
      },
    },
    // {
    //   title: "Minimum Increment",
    //   key: "minIncrement",
    //   dataIndex: "minIncrement",
    //   render: (text) => {
    //     return (
    //       <Row className={style.amount} alignItem={"center"}>
    //         <p>$</p>
    //         {ColTextCheck(renderItemDataOrEmptyNull(text, 2))}
    //       </Row>
    //     );
    //   },
    // },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text) => {
        return (
          <span> {capitalizeString(renderItemDataOrEmptyNull(text))} </span>
        );
      },
    },
    {
      title: "Bids",
      key: "totalBids",
      dataIndex: "totalBids",
      render: ColTextCheck,
    },
    {
      title: "Payment Status",
      key: "isPaid",
      dataIndex: "isPaid",
      render: (text) => {
        return (
          <dvi>
            <span> {text ? "Paid" : "Pending"} </span>
          </dvi>
        );
      },
    },
    {
      title: "Time Remaining",
      key: "email",
      dataIndex: "email",
      render: (_, row) => {
        return (
          <span>
            <TimeRemainingTimer endAt={row?.endAt} />
          </span>
        );
      },
    },
    {
      title: "is featured",
      key: "isFeature",
      dataIndex: "isFeature",
      render: (text, row) => {
        return (
          <Switch
            // defaultChecked={text}
            checked={text}
            onChange={(e) => {
              onChangeAuctionFeature(e, row);
            }}
          />
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <Row justify={"center"} className={style.actions}>
            <Tooltip placement="top" title={"Edit"}>
              <button
                disabled={
                  row?.status === "won" || row?.status === "closed"
                    ? true
                    : false
                }
                className={style.eyeIconContainer}
              >
                <EyeOutlined
                  style={{
                    color: "#899499",
                    fontSize: "15px",
                    cursor: "pointer",
                    disable: true,
                  }}
                  onClick={() => {
                    props.history.push("manage-auctions/create-auction", row);
                  }}
                />
              </button>
            </Tooltip>
            {row?.totalBids === 0 && (
              <Tooltip placement="top" title={"Delete"}>
                <img
                  onClick={() => {
                    setVisibleDeleteModal(true);
                    setRowId(row?.id);
                  }}
                  src={DeleteRedIcon}
                  alt="icon"
                />
              </Tooltip>
            )}
          </Row>
        );
      },
    },
  ];

  const onChangeSearch = (e) => {
    setSearchValue(e.target.value);
    onChangeCustomDebounce(2000, setWaitTimeCheck, e.target.value, searchValue);
  };

  const convertDateFormat = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [month, day, year] = datePart.split("/").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);
    const adjustedYear = year < 100 ? 2000 + year : year;
    const date = new Date(
      adjustedYear,
      month - 1,
      day,
      hours,
      minutes,
      seconds
    );
    const formattedDay = String(date.getDate()).padStart(2, "0");
    const formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
    const formattedYear = String(date.getFullYear());
    const formattedHours = String(date.getHours()).padStart(2, "0");
    const formattedMinutes = String(date.getMinutes()).padStart(2, "0");
    const formattedSeconds = String(date.getSeconds()).padStart(2, "0");
    const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    return convertDateToISO(formattedDate);
  };
  const convertDateToISO = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);
    const adjustedYear = year < 100 ? 2000 + year : year;
    const date = new Date(
      adjustedYear,
      month - 1,
      day,
      hours,
      minutes,
      seconds
    );
    return date.toISOString();
  };
  const makePayloadReady = (data) => {
    return new Promise((resolve, reject) => {
      const multipleAuctionPayload = [];
      data
        .filter((item) => {
          return item?.length > 0;
        })
        .forEach((element, index) => {
          let startDate = element[6];
          let endDate = element[7];
          let unitPrice = element[8];
          let minIncrement = element[9];
          startDate = ["", undefined, "undefined", null].includes(startDate)
            ? ""
            : startDate;
          endDate = ["", undefined, "undefined", null].includes(endDate)
            ? ""
            : endDate;
          unitPrice = ["", undefined, "undefined", null].includes(unitPrice)
            ? ""
            : unitPrice;
          minIncrement = ["", undefined, "undefined", null].includes(
            minIncrement
          )
            ? ""
            : minIncrement;
          const payload = {};
          if (index !== 0) {
            payload["serialId"] = element[0];
            payload["manufacture"] = element[1];
            payload["sku"] = element[2];
            payload["name"] = element[3];
            payload["grade"] = element[4];
            payload["totalUnits"] = Number(element[5]);
            // payload["startAt"] = startDate=='' ? '' :convertTimeZoneOffset(
            //   moment(startDate).format()
            // );
            // payload["endAt"] = convertTimeZoneOffset(
            //   moment(endDate).format()
            // );
            // payload["startAt"] =
            //   startDate == "" ? "" : convertDateFormat(startDate);
            payload["startAt"] =
              startDate == ""
                ? ""
                : convertTimeZoneOffset(moment(startDate).format());
            payload["endAt"] = convertTimeZoneOffset(moment(endDate).format());
            payload["unitPrice"] =
              unitPrice == ""
                ? ""
                : isNaN(Number(unitPrice))
                ? 0
                : Number(unitPrice);
            payload["minIncrement"] =
              minIncrement == ""
                ? ""
                : isNaN(Number(minIncrement))
                ? 0
                : Number(minIncrement);
          }

          index !== 0 && multipleAuctionPayload.push(payload);
        });

      const result = multipleAuctionPayload?.filter(
        (item) => item?.manufacture !== undefined
      );
      resolve(result);
    });
  };

  const onCreateAllAuctions = async () => {
    await createAuction({
      // categoryId: importCategoryId,
      // batchNo: importBatchNo,
      auctions: auctionPayloadExcel,
    });
  };

  const createAuction = async (data) => {
    let res = null;
    try {
      setLoading(true);
      const response = await postAuctionApiByCSV(data);
      setLoading(false);
      res = response;
      if (response.status === 200) {
        successMessage({ message: "Auction via excel created successfully" });
        setVisibleModal(false);
        fetchDataApi();
      } else throw response;
    } catch (e) {
      console.log({ e });
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      return res;
    }
  };

  const onConfirmDelete = async () => {
    try {
      setLoading(true);
      const response = await deleteManageAuctionsByIdApi(rowId);
      if (response?.data?.statusCode) {
        successMessage(response?.data);
        fetchDataApi();
        setVisibleDeleteModal(false);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  const deleteSelectedAuctions = async () => {
    const payload = {
      auctions: selectedRowIds,
    };
    let res = null;
    try {
      const response = await _deleteAuctionsList(payload);
      res = response;
      if (response.status === 200) {
        successMessage({ message: "Auctions deleted successfully" });
        if (page > 1) {
          setPage((page) => page - 1);
        }
        fetchDataApi();
        setSelectedRowIds([]);
        setIsSelectAllChecked(false);
      } else throw response;
    } catch (e) {
      console.log({ e });
      requestErrorHandel({ error: e });
    } finally {
      return res;
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading || uploading} indicator={renderLoader}>
        <CommonDeleteModal
          visibleModal={visibleDeleteModal}
          title={"Delete Auction"}
          confimBtnTitle={"Confirm"}
          cancelBtnTitle="Cancel"
          loadingConfirmBtn={loading}
          onClickConfirm={onConfirmDelete}
          handleCancel={() => setVisibleDeleteModal(false)}
        />
        <Row
          justify={"end"}
          alignItem="center"
          className={style.searchBoxContainer}
        >
          {selectedRowIds?.length > 0 && (
            <CustomButton
              title="Delete"
              onClick={() => deleteSelectedAuctions()}
              className={style.deleteButtonStyle}
            />
          )}
          <Select
            defaultValue=""
            style={{ minWidth: 115 }}
            onChange={handleChange("carrier")}
            className={style.selectBoxCarrier}
          >
            <Option value="">Carrier</Option>
            {filterListingsData?.carrierList?.length > 0 &&
              filterListingsData?.carrierList.map((item) => (
                <Option value={item?.id}>{item?.title}</Option>
              ))}
          </Select>

          <Select
            defaultValue=""
            style={{ minWidth: 115 }}
            onChange={handleChange("batchNo")}
            value={filters?.batchNo}
            className={style.selectBoxBatchNo}
          >
            <Option value="">Batch No</Option>
            {filterListingsData?.batchNoList?.length > 0 &&
              filterListingsData?.batchNoList.map((item) => (
                <Option value={item}>{item}</Option>
              ))}
          </Select>

          <Select
            defaultValue="Open"
            style={{ minWidth: 150 }}
            className={style.selectBox}
            onChange={handleChange("Status")}
          >
            <Option value="">Status</Option>
            <Option value="Open">Open</Option>
            <Option value="Won">Won</Option>
            <Option value="Cancelled">Cancelled</Option>
            <Option value="Close">Close</Option>
          </Select>
          <CustomButton
            title="Import Auctions"
            onClick={() => setVisibleModal(true)}
            variant="two"
            startData={<UploadOutlined />}
          />
          <CustomButton
            title="Create New Auction"
            onClick={() => props.history.push("manage-auctions/create-auction")}
            variant="one"
            startData={<PlusOutlined color="black" />}
          />
          <Input
            placeholder="Search Auctions"
            value={searchValue}
            onChange={onChangeSearch}
            style={{ width: "15%", height: "40px", fontSize: "15px" }}
          />
        </Row>

        <CommonTable
          className={style.customerTable}
          data={manageAuctionsListData}
          fetchRecords={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
          pageSize={pageSize}
        />

        <CustomModal
          visibility={visibleModal}
          handleCancel={() => {
            setVisibleModal(false);
            setAuctionPayloadExcel([]);
          }}
          handleOk={() => {}}
          className={style.modalAuctionExcel}
          title={"Import Auctions via Excel"}
        >
          <Form
            layout="vertical"
            className={style.auctionUploadViaExcelForm}
            form={form}
            onFinish={onSubmit}
          >
            <Form.Item label={"Carrier"} name={"categoryId"}>
              <Select onChange={(e) => setImportCategoryId(e)}>
                {manageCategoriesListData?.length > 0 &&
                  manageCategoriesListData?.map((item, index) => {
                    return (
                      <Option value={item?.id} key={index}>
                        {renderItemDataOrEmptyNull(item?.title)}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Batch No"
              // name="batchNo"
              // rules={[{ required, message: "Field is required!" }]}
              name="batchName"
              initialValue="" // Pre-fill the input
              rules={setRules("Batch number")}
              style={{ marginBottom: "25px" }}
            >
              <Input
                type="text"
                onChange={(e) => setImportBatchNo(e.target.value)}
                value={importBatchNo}
              />
            </Form.Item>
            <Row>
              <p style={{ marginLeft: 2 }}>Upload File</p>
            </Row>
            <Row justify={"center"} align="center">
              <Col className={style.imgInputContainer}>
                <Col>
                  {
                    <Image
                      width={"5rem"}
                      src={
                        "https://logowik.com/content/uploads/images/microsoft-excel-new.jpg"
                      }
                    />
                  }
                </Col>

                <input
                  type="file"
                  onChange={(e) => onReadExcelSheet(e)}
                  name="image"
                  style={{ marginLeft: 10 }}
                  accept=".xlsx, .xls, application/vnd.ms-excel"
                  ref={fileInputRef}
                />
                <br />
              </Col>
            </Row>
            <Row justify={"center"} align="center">
              <Link
                to={SampleAuctionFile}
                target="_blank"
                rel="noopener noreferrer"
                download={"SampleAuctionFile.xlsx"}
              >
                <p className={style.downloadSampleFile}>Download Sample File</p>
              </Link>
            </Row>
            <Row>
              <Col span={24}>
                <CustomButton
                  // disabled={!auctionPayloadExcel?.length}
                  className={style.btn}
                  // onClick={onCreateAllAuctions}
                  loading={loading}
                  title={"Upload"}
                  htmlType="submit"
                />
              </Col>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default ManageAuctionList;
