// import * as React from "react";
// import { Table } from "antd";
// import style from './style.module.scss'

// const ManageAuctionTable = ({
//   data,
//   header,
//   columns,
//   loading,
//   fetchRecords,
//   totalRecord,
//   hidePagination,
//   pageSize = 10,
//   className,
//   ...rest
// }) => {
//   const [pagination, setPagination] = React.useState({
//     pageSize: pageSize,
//     total: totalRecord,
//     showTotal: (total, range) =>
//       `${range[0]}-${range[1]} of ${total} items`,
//     onChange: (page, pageSize) => {
//       fetchRecords(page, pageSize);
//     },
//   });

//   React.useEffect(() => {
//     // Update pagination total when totalRecord changes
//     setPagination(prevPagination => ({
//       ...prevPagination,
//       total: totalRecord
//     }));
//   }, [totalRecord]);

//   return (
//     <div>
//       <Table
//         dataSource={data}
//         title={header}
//         columns={columns}
//         className={`${style.commonTableContainer} ${className}`}
//         rowKey={columns[0]?.key}
//         pagination={hidePagination ? false : pagination}
//         {...rest}
//       ></Table>
//     </div>
//   );
// };

// export default ManageAuctionTable;
import * as React from "react";
import { Table } from "antd";
import style from './style.module.scss';

const ManageAuctionTable = ({
  data,
  header,
  columns,
  loading,
  fetchRecords,
  totalRecord,
  hidePagination,
  pageSize = 10,
  className,
  ...rest
}) => {
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: pageSize,
    total: totalRecord,
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} of ${total} items`,
    onChange: (page, pageSize) => {
      setPagination(prevPagination => ({
        ...prevPagination,
        current: page,
        pageSize: pageSize,
      }));
      fetchRecords(page, pageSize);
    },
  });

  React.useEffect(() => {
    setPagination(prevPagination => ({
      ...prevPagination,
      total: totalRecord
    }));
  }, [totalRecord]);

  React.useEffect(() => {
    setPagination(prevPagination => ({
      ...prevPagination,
      pageSize: pageSize
    }));
  }, [pageSize]);

  return (
    <div>
      <Table
        dataSource={data}
        title={header}
        columns={columns}
        loading={loading}
        className={`${style.commonTableContainer} ${className}`}
        rowKey={record => record.key}
        pagination={hidePagination ? false : pagination}
        {...rest}
      />
    </div>
  );
};

export default ManageAuctionTable;
