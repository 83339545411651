import CommonTable from "components/Tables/CommonTable";
import { DatePicker, Input, Row, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import { EyeOutlined } from "@ant-design/icons";
import { getAllUsersApi } from "apis/user";
import { onChangeCustomDebounce } from "utils/common-functions";
import { renderItemDataOrEmptyNull, formatDate } from "utils/common-functions";
import moment from "moment";

const UsersList = (props) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [customerListData, setCustomersListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [waitTimeCheck, setWaitTimeCheck] = useState(false);
  const [defaultValueDate, setDefaultValueDate] = useState("");

  const fetchDataApi = async () => {
    setCustomersListData([]);
    try {
      setLoading(true);
      const response = await getAllUsersApi(
        page,
        pageSize,
        searchValue,
        defaultValueDate
      );
      if (response.status === 200 && response.data?.data) {
        setCustomersListData(response.data.data);
        setTotalNumberOfRecords(response?.data?.total);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    if (waitTimeCheck) {
      fetchDataApi();
    }
  }, [waitTimeCheck]);

  useEffect(() => {
    fetchDataApi();
  }, [page, pageSize, defaultValueDate]);

  const columnsData = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (text, row) => {
        return (
          <p
            className={style.onClickName}
            onClick={() => {
              props.history.push("customers/user-details", row);
            }}
          >
            {ColTextCheck(text)}
          </p>
        );
      },
    },
    {
      title: "Company Name",
      key: "companyName",
      dataIndex: "companyName",
      render: ColTextCheck,
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: ColTextCheck,
    },
     
    {
      title: "Phone No.",
      key: "phoneNo",
      dataIndex: "phoneNo",
      render: ColTextCheck,
    },
    {
      title: "Registration Date",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (text) => {
        return <>{formatDate(text)}</>;
      },
    },
    {
      title: "Deposit Amount",
      key: "amount",
      dataIndex: "amount",
      render: (text) => {
        return (
          <Row className={style.amount} alignItem={"center"}>
            <p>$</p>
            {ColTextCheck(renderItemDataOrEmptyNull(text, 2))}
          </Row>
        );
      },
    },
    {
      title: "Auction Participated",
      key: "totalParticipants",
      dataIndex: "totalParticipants",
      render: ColTextCheck,
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div
            onClick={() => {
              props.history.push("customers/user-details", row);
            }}
          >
            <Tooltip placement="top" title={"Edit"}>
              <EyeOutlined className={style.eyeIcon} />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onChangeSearch = (e) => {
    setSearchValue(e.target.value);
    onChangeCustomDebounce(2000, setWaitTimeCheck, e.target.value, searchValue);
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <Row
          justify={"end"}
          alignItem="center"
          className={style.searchBoxContainer}
        >
          <DatePicker
            format="DD-MM-YYYY"
            defaultValue={defaultValueDate}
            placeholder="Filter by date"
            onChange={(e) => {
              if (e === null) {
                setDefaultValueDate("");
              } else {
                setDefaultValueDate(moment(e).format("YYYY-MM-DD"));
              }
            }}
            style={{ width: "200px" }}
          />
          <Input
            placeholder="Search users"
            value={searchValue}
            onChange={onChangeSearch}
            style={{ width: "20%", height: "40px", fontSize: "15px" }}
          />
        </Row>

        <CommonTable
          className={style.customerTable}
          data={customerListData}
          fetchRecords={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
          pageSize={pageSize}
        />
      </Spin>
    </div>
  );
};

export default UsersList;
