import CommonTable from "components/Tables/CommonTable";
import { Button, Col, Input, Row, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import CustomModal from "components/CustomModal/CustomModal";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { getDepositRequestApi } from "apis/important-alerts/deposit-request";
import { formateDateWithMoment } from "utils/helpers/helpers";
import { acceptDeclineDepositRequestApi } from "apis/important-alerts/deposit-request";
import { requestErrorHandel } from "utils/helpers/helpers";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { renderStatusBackgroundColor } from "utils/common-functions";
import { useDispatch } from "react-redux";
import { _toggleNewDepositRequestBadage } from "actions/generalActions/generalAction";

const DepositRequest = (props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [
    originalAccountRequestListApiRes,
    setOriginalAccountRequestListApiRes,
  ] = useState(null);
  const [accountsRequestListData, setAccountsRequestListData] = useState([]);
  const [accountRequestRes, setAccountRequestRes] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visibleDetailsModal, setVisibleDetailsModal] = useState(false);
  const [rowData, setRowData] = useState(null);

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getDepositRequestApi(page, pageSize);
      if (response.status === 200 && response.data?.data) {
        setAccountsRequestListData([]);
        setAccountsRequestListData(response?.data?.data);
        setAccountRequestRes(response?.data?.total);
        setOriginalAccountRequestListApiRes(response?.data?.data);
        dispatch(_toggleNewDepositRequestBadage(response?.data?.total));
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, [page, pageSize]);

  useEffect(() => {
    fetchDataApi();
  }, [page, pageSize]);

  const onDetailsClick = (data) => {
    setRowData(data);
    setVisibleDetailsModal(true);
  };

  const onUpdateAcceptDecline = async (id, action) => {
    try {
      const payload = { id: id, action };
      const response = await acceptDeclineDepositRequestApi(payload);
      if (response.status === 200 && response.data) {
        NotificationManager.info(response?.data?.message);
        fetchDataApi();
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ error: e });
    }
  };

  const columnsData = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (text, row) => {
        return (
          <p className={style.onClickName} onClick={() => onDetailsClick(row)}>
            {ColTextCheck(text)}
          </p>
        );
      },
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      render: (text) => {
        return (
          <Row className={style.amount} alignItem={"center"}>
            <p>$</p>
            {ColTextCheck(renderItemDataOrEmptyNull(text, 2))}
          </Row>
        );
      },
    },
    {
      title: "Receipt",
      key: "receipt",
      dataIndex: "receipt",
      render: (text) => {
        return (
          <a href={text} target="_blank" rel="noopener noreferrer">
            <img width={40} src={text} alt="icon" />
          </a>
        );
      },
    },
    {
      title: "Date",
      key: "depositDate",
      dataIndex: "depositDate",
      render: (text) => {
        return <p>{formateDateWithMoment(text)}</p>;
      },
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div className={style.actions}>
            <div>
              <Tooltip placement="top" title={"Approve"}>
                <CheckOutlined
                  onClick={() => onUpdateAcceptDecline(row?.id, "approved")}
                  style={{
                    color: renderStatusBackgroundColor("approved"),
                  }}
                />
              </Tooltip>
              <Tooltip placement="top" title={"Reject"}>
                <CloseOutlined
                  onClick={() => onUpdateAcceptDecline(row?.id, "rejected")}
                  style={{
                    color: renderStatusBackgroundColor("Decline"),
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <CommonTable
          className={style.customerTable}
          data={accountsRequestListData}
          fetchRecords={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          totalRecord={accountRequestRes}
          columns={columnsData}
          pageSize={pageSize}
        />

        <CustomModal
          visibility={visibleDetailsModal}
          handleCancel={() => setVisibleDetailsModal(false)}
          handleOk={() => null}
          title={"Details"}
        >
          <Spin spinning={loading} indicator={renderLoader}>
            <Row className={style.modalHeading}>
              <Col span={12}>
                <p> Name </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull(rowData?.name)} </p>{" "}
              </Col>
              <Col span={12}>
                <p> Email </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull(rowData?.email)} </p>{" "}
              </Col>
              <Col span={12}>
                <p> Phone Number </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull(rowData?.phoneNo)} </p>{" "}
              </Col>
              <Col className={style.docs} span={12}>
                <p> Docs </p>
              </Col>
              <Col span={12}>
                <div>
                  <img src={rowData?.license} alt="" />
                  <img src={rowData?.photo} alt="" />
                </div>
              </Col>
              <Col span={12}>
                <p> Country </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull("UAE")} </p>{" "}
              </Col>
              <Col span={12}>
                <p> Business Name </p>
              </Col>
              <Col span={12}>
                {" "}
                <p> {renderItemDataOrEmptyNull(rowData?.email)} </p>{" "}
              </Col>
              <Col span={12}>
                <p> Business Address </p>
              </Col>
              <Col span={12}>
                {" "}
                <p>
                  {" "}
                  {renderItemDataOrEmptyNull(rowData?.businessAddress)}{" "}
                </p>{" "}
              </Col>
            </Row>
          </Spin>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default DepositRequest;
