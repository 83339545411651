import { CARRIER_LISTINGS } from "actions/auctions/auctions-details";

const initialState = {
  carrier_list: [],
};

export default function _carrierListingsReducer(state = initialState, action) {
  switch (action.type) {
    case CARRIER_LISTINGS:
      return {
        ...state,
        carrier_list: action.payload,
      };
      break;

    default:
      return state;
  }
}
