import CommonTable from "components/Tables/CommonTable";
import { Col, Form, Image, Input, Row, Select, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import classNames from "classnames";
import CustomButton from "components/custom-button";
import CustomModal from "components/CustomModal/CustomModal";
import * as XLSX from "xlsx";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import CommonDeleteModal from "components/common-delete-modal";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import { getManufacturersApi } from "apis/manufacturere-models";
import { postManufacturerApi } from "apis/manufacturere-models";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { postModelsOfManufacturerApi } from "apis/manufacturere-models";
import SampleFile from "../../../assets/files/Manufacture.xlsx";
import { postAllManufacturerModelsApi } from "apis/manufacturere-models";
import { Link } from "react-router-dom";
import EditBlackIcon from "assets/img/black/edit.svg";
import DeleteRedIcon from "assets/img/red/delete.svg";
import { deleteModelsOfManufacturerApi } from "apis/manufacturere-models";
import { updateModelsOfManufacturerApi } from "apis/manufacturere-models";
import { deleteManufacturerApi } from "apis/manufacturere-models";
import { updateManufacturerApi } from "apis/manufacturere-models";

const ManufacturerModelList = (props) => {
  const [form] = Form.useForm();
  const [formModels] = Form.useForm();
  const { Option } = Select;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [manufacturerModelListData, setManufacturerModelListData] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModelModal, setVisibleModelModal] = useState(false);
  const [visibleManufacturerModal, setVisibleManufacturerModal] =
    useState(false);
  const [uploading, setUpLoading] = useState(false);
  const [manufacturerModelPayloadExcel, setManufacturerModelPayloadExcel] =
    useState({ manufactures: [] });
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  const [visibleDeleteModelModal, setVisibleDeleteModelModal] = useState(false);
  const [rowDataModels, setRowDataModels] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [visibleDeleteManufacturerModal, setVisibleDeleteManufacturerModal] =
    useState(false);

  const onSuccessApi = async () => {
    await fetchDataApi();
    setVisibleModal(false);
    setVisibleManufacturerModal(false);
    setVisibleModelModal(false);
    setVisibleDeleteModelModal(false);
    setVisibleDeleteManufacturerModal(false);
  };

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getManufacturersApi(page, pageSize);
      if (response.status === 200 && response.data?.data) {
        setManufacturerModelListData(response.data.data);
        setTotalNumberOfRecords(response.data?.total);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  const createManufacturer = async (data) => {
    try {
      setLoading(true);
      if (rowData) {
        const payload = {
          id: rowData?.id,
          name: data?.name,
        };
        const response = await updateManufacturerApi(payload);
        if (response.status === 200 && response.data?.data) {
          successMessage({
            message: "Manufacturer has been updated successfully.",
          });
        }
      } else {
        const response = await postManufacturerApi(data);
        if (response.status === 200 && response.data?.data) {
          successMessage({ message: "Manufacturer created successfully." });
        }
      }
      onSuccessApi();
    } catch (e) {
      console.log({ e });
      requestErrorHandel({ error: e });
    } finally {
      setLoading(false);
    }
  };

  const createModels = async (data) => {
    try {
      setLoading(true);
      if (rowDataModels) {
        const payload = {
          id: rowDataModels?.id,
          name: data?.models,
        };
        const response = await updateModelsOfManufacturerApi(payload);
        if (response.status === 200 && response.data?.data) {
          successMessage({ message: "Model has been updated successfully." });
        }
      } else {
        const response = await postModelsOfManufacturerApi(data);
        if (response.status === 200 && response.data?.data) {
          successMessage({ message: "Model has been created successfully." });
        }
      }
      onSuccessApi();
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    fetchDataApi();
  }, [page, pageSize]);

  const onSubmitAddManufacturer = async (values) => {
    await createManufacturer(values);
  };

  const onSubmitModel = async (values) => {
    const payload = { id: values.id, models: [values?.models] };
    await createModels(payload);
  };

  const onSetFieldsValues = () => {
    if (rowData) {
      form.setFieldsValue({
        name: rowData?.name,
      });
    }
    if (rowDataModels) {
      formModels.setFieldsValue({
        models: rowDataModels?.name,
      });
    }
  };

  useEffect(() => {
    onSetFieldsValues();
  }, [rowData, rowDataModels]);

  const onSubmit = () => { 
    console.log("farhadkhan",manufacturerModelPayloadExcel);

  };

  const onReadExcelSheet = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const reader = new FileReader();
        reader.onload = (event) => {
          const workbook = XLSX.read(event.target.result, { type: "binary" });
          const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one you want to read
          const worksheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          makePayloadReady(data);
        };
        reader.readAsBinaryString(e.target.files[0]);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    }
  };

  const columnsData = [
    {
      title: "Manufacturer",
      key: "name",
      dataIndex: "name",
      render: ColTextCheck,
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div className={style.actions}>
            <Tooltip placement="top" title={"Edit"}>
              <img
                onClick={() => {
                  setRowData(row);
                  setVisibleManufacturerModal(true);
                }}
                src={EditBlackIcon}
                alt="icon"
              />
            </Tooltip>
            <Tooltip placement="top" title={"Delete"}>
              <img
                onClick={() => {
                  setRowData(row);
                  setVisibleDeleteManufacturerModal(true);
                }}
                src={DeleteRedIcon}
                alt="icon"
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const makePayloadReady = (data) => {
    const multipleManufacturerModelsPayload = [];
    data.forEach((element, index) => {
     multipleManufacturerModelsPayload.push(element[0]);
    });
    setManufacturerModelPayloadExcel({manufactures:data});
  };

  const onCreateAllManufacturerModels = async () => {
    await createManufacturerModels(manufacturerModelPayloadExcel, true);
    return;
    manufacturerModelPayloadExcel.manufactures.forEach(
      async (element, index) => {
        if (manufacturerModelPayloadExcel.manufactures.length - 1 === index) {
          await createManufacturerModels({ manufactures: [element] }, true);
        } else {
          await createManufacturerModels({ manufactures: [element] }, false);
        }
      }
    );
  };

  const createManufacturerModels = async (data, isLast) => {
    let res = null;
    try {
      setLoading(true);
      const response = await postAllManufacturerModelsApi(data);
      setLoading(false);
      res = response;
      if (response.status === 200) {
        successMessage({
          message: "Manufacturer and models created successfully",
        });
        setVisibleModal(false);
        isLast && fetchDataApi();
      } else throw response;
    } catch (e) {
      console.log({ e });
      setLoading(false);
      requestErrorHandel({ error: e });
    } finally {
      return res;
    }
  };

  const onConfirmManufacturerDelete = async () => {
    try {
      setLoading(true);
      if (rowData) {
        const res = await deleteManufacturerApi(rowData?.id);
        if (res?.data?.statusCode === 200) {
          onSuccessApi();
          successMessage({
            message: "Manufacturer has been deleted successfully",
          });
        }
      }
      setLoading(false);
    } catch (error) {
      requestErrorHandel({ error: error });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onConfirmDeleteModel = async () => {
    try {
      setLoading(true);
      if (rowDataModels) {
        const res = await deleteModelsOfManufacturerApi(rowDataModels?.id);
        if (res?.data?.statusCode === 200) {
          onSuccessApi();
          successMessage({ message: "Model has been deleted successfully" });
        }
      }
      setLoading(false);
    } catch (error) {
      requestErrorHandel({ error: error });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const columnsDataGrades = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      width: "20%",
      render: (text, row) => {
        return (
          <p className={style.onClickName} onClick={() => { }}>
            {ColTextCheck(text)}
          </p>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (text, row) => {
        return <p onClick={() => { }}>{ColTextCheck(text)}</p>;
      },
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div className={style.actions}>
            <Tooltip placement="top" title={"Edit"}>
              <img
                onClick={() => {
                  setRowDataModels(row);
                  setVisibleModelModal(true);
                }}
                src={EditBlackIcon}
                alt="icon"
              />
            </Tooltip>
            <Tooltip placement="top" title={"Delete"}>
              <img
                onClick={() => {
                  setRowDataModels(row);
                  setVisibleDeleteModelModal(true);
                }}
                src={DeleteRedIcon}
                alt="icon"
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const validateField = (field, value) => {
    if (!value) {
      return Promise.reject(`${field} is required`);
    }
    // Add more validation rules as needed

    // If validation passes, return a resolved Promise
    return Promise.resolve();
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading || uploading} indicator={renderLoader}>
        <CommonDeleteModal
          visibleModal={visibleDeleteManufacturerModal}
          title={"Delete Manufacturer"}
          confimBtnTitle={"Confirm"}
          cancelBtnTitle="Cancel"
          loadingConfirmBtn={loading}
          onClickConfirm={onConfirmManufacturerDelete}
          handleCancel={() => setVisibleDeleteManufacturerModal(false)}
        />
        <CommonDeleteModal
          visibleModal={visibleDeleteModelModal}
          title={"Delete Model"}
          confimBtnTitle={"Confirm"}
          cancelBtnTitle="Cancel"
          loadingConfirmBtn={loading}
          onClickConfirm={onConfirmDeleteModel}
          handleCancel={() => setVisibleDeleteModelModal(false)}
        />
        <Row
          justify={"end"}
          alignItem="center"
          className={style.searchBoxContainer}
        >
          <CustomButton
            title="Upload via Excel"
            onClick={() => setVisibleModal(true)}
            className={style.uploadViaExcel}
            startData={<UploadOutlined />}
          />
          <CustomButton
            title={"Add New Manufacturers"}
            variant="one"
            onClick={() => {
              form.resetFields();
              setVisibleManufacturerModal(true);
            }}
            startData={<PlusOutlined color="black" />}
          />
          {/* <CustomButton
            title="Add New Model"
            onClick={() => {
              setRowDataModels(null);
              formModels.resetFields();
              setVisibleModelModal(true);
            }}
            variant="two"
            startData={<PlusOutlined color="black" />}
          /> */}
        </Row>

        <CommonTable
          className={style.customerTable}
          data={manufacturerModelListData}
          fetchRecords={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
          pageSize={pageSize}
          // expandable={{
          //   expandedRowRender: (record) => {
          //     return (
          //       <div className={style.expandedRow}>
          //         <h6>Models</h6>
          //         {/* {record?.models?.map((item) => {
          //           return (
          //             <div className={style.modelsContainer}>
          //               <p>{renderItemDataOrEmptyNull(item?.name)}</p>
          //             </div>
          //           );
          //         })} */}
          //         <div className={style.modelsContainer}>
          //           <CommonTable
          //             className={style.gradesTable}
          //             data={record?.models}
          //             columns={columnsDataGrades}
          //             hidePagination={true}
          //           />
          //         </div>
          //       </div>
          //     );
          //   },
          //   width: "10%",
          // }}
        />

        <CustomModal
          visibility={visibleModal}
          handleCancel={() => {
            setVisibleModal(false);
            setManufacturerModelPayloadExcel({});
          }}
          handleOk={() => { }}
          className={style.modalAuctionExcel}
          title={"Upload Manufacturer & Model via Excel"}
        >
          <Form
            layout="vertical"
            className={style.auctionUploadViaExcelForm}
            form={form}
            onFinish={onSubmit}
          >
            <Row>
              <p style={{ marginLeft: 2 }}>Upload File</p>
            </Row>
            <Row justify={"center"} align="center">
              <Col className={style.imgInputContainer}>
                <Col>
                  {
                    <Image
                      width={"5rem"}
                      src={
                        "https://logowik.com/content/uploads/images/microsoft-excel-new.jpg"
                      }
                    />
                  }
                </Col>

                <input
                  type="file"
                  onChange={(e) => onReadExcelSheet(e)}
                  name="image"
                  style={{ marginLeft: 10 }}
                  accept=".xlsx, .xls, application/vnd.ms-excel"
                />
                <br />
              </Col>
            </Row>
            <Row justify={"center"} align="center">
              <Link
                to={SampleFile}
                target="_blank"
                rel="noopener noreferrer"
                download={"SampleManufacturerModelFile.xlsx"}
              >
                <p className={style.downloadSampleFile}>Download Sample File</p>
              </Link>
            </Row>
            <Row>
              <Col span={24}>
                <CustomButton
                  disabled={!manufacturerModelPayloadExcel.manufactures?.length}
                  className={style.btn}
                  onClick={onCreateAllManufacturerModels}
                  loading={loading}
                  title={"Upload"}
                />
              </Col>
            </Row>
          </Form>
        </CustomModal>
        <CustomModal
          visibility={visibleModelModal}
          handleCancel={() => {
            setVisibleModelModal(false);
          }}
          handleOk={() => null}
          title={rowDataModels ? "Update Model" : "Add New Model"}
          className={style.modelModal}
          bodyStyle={{ height: '1000px !Important',}}
        >
          <Spin spinning={loading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.modalForm}
              form={formModels}
              name="control-hooks"
              onFinish={onSubmitModel}
            >
              <Row align="bottom" gutter={[10]}>
                <Col span={24}>
                  {!rowDataModels && (
                    <Form.Item className={style.mb_1} label={"Manufacturer"} name={"id"} rules={[
                      {
                        validator: (_, value) => validateField('Manufacturer', value),
                      },
                    ]}>
                      <Select>
                        {manufacturerModelListData?.length > 0 &&
                          manufacturerModelListData?.map((item, index) => {
                            return (
                              <Option value={item?.id} key={index}>
                                {renderItemDataOrEmptyNull(item?.name)}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
                <Col span={24}>
                  <Form.Item className={style.mb_1} label={"Model"} name={"models"} rules={[
                    {
                      validator: (_, value) => validateField('Model', value),
                    },
                  ]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Row justify={"center"}>
                    <CustomButton
                      className={style.btn}
                      title={rowDataModels ? "Update" : "Add"}
                      disabled={loading}
                      htmlType="submit"
                      variant="one"
                      width={"100%"}
                      loading={loading}
                    />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </CustomModal>
        <CustomModal
          visibility={visibleManufacturerModal}
          handleCancel={() => {
            setVisibleManufacturerModal(false);
          }}
          handleOk={() => null}
          title={rowData ? " Update Manufacturer" : "Add New Manufacturer"}
          className={style.modelModal}
        >
          <Spin spinning={loading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.modalForm}
              form={form}
              name="control-hooks"
              onFinish={onSubmitAddManufacturer}
            >
              <Row align="bottom" gutter={[10]}>
                <Col span={24}>
                  <Form.Item label={"Manufacturer"} name={"name"}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row justify={"center"}>
                    <CustomButton
                      className={style.btn}
                      title={rowData ? "Update" : "Add"}
                      disabled={loading}
                      htmlType="submit"
                      variant="one"
                      width={"100%"}
                      loading={loading}
                      destroyOnClose={true}
                    />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default ManufacturerModelList;
