/* eslint-disable eqeqeq */
import React from "react";
import Login from "../components/Forms/LoginForm.jsx";
import { login } from "../apis/login-apis/login-user-api";
import { NotificationContainer } from "react-notifications";
import { requestErrorHandel } from "utils/helpers/helpers";
import { _setLoginUser } from "actions/auth/authAction.js";
import { connect } from "react-redux";
// test

import admissionStatusIcon from "../assets/img/sibar/admissionStatusIcon.png";
import CMSRoutes from "routes/CMSRoutes.js";
import { _addSidebarMenu } from "actions/auth/authAction.js";
import subMenu from "routes/SubMenu.js";
import { _setUserPermissions } from "actions/auth/authAction.js";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.userRoutes = [];
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const params = {
        email: this.state.email,
        password: this.state.password,
      };
      this.setState({
        isLoading: true,
      });
      const response = await login(params);
      if (response.status == 200 && response.data) {
        localStorage.setItem(
          "userToken",
          JSON.stringify(response.data.data?.authToken)
        );
        // logindata
        localStorage.setItem("loginData", JSON.stringify(response.data?.data));
        localStorage.setItem("currentUserRol", JSON.stringify("admin"));
        this.props.setUserInfo({
          // role: response.data.data?.role?.name === "Super-Admin" ? "admin" : "employee",
          role: "admin",
          userName: response.data.data?.name,
        });
        this.setAccessibleMenuItems(response.data?.data);
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      await requestErrorHandel({ error: error });
    }
  };

  // Function to check if a user has a specific permission
  hasPermission = (permissionName, userPermissions) => {
    return userPermissions.some(
      (permission) => permission.name === permissionName
    );
  };

  hasImportantAlertsPermission = (userPermissions) => {
    const importantAlertsPermissions = [
      "Deposit Requests",
      "New Account Requests",
    ];
    return userPermissions.some(
      (permission) =>
        importantAlertsPermissions.includes(permission.name) &&
        permission.type === "impAlerts"
    );
  };

  setAccessibleMenuItems = (_user) => {
    // Helper function to check if a user has a specific permission
    const hasPermission = (permissionName, permissions) => {
      return permissions?.some(
        (permission) => permission.name === permissionName
      );
    };

    // Helper function to check for special case of "Important Alerts"
    const hasImportantAlertsPermission = (permissions) => {
      const importantAlertsPermissions = [
        "Deposit Requests",
        "New Account Requests",
      ];
      return permissions?.some(
        (permission) =>
          importantAlertsPermissions.includes(permission.name) &&
          permission.type === "impAlerts"
      );
    };

    const subeMenuItems = subMenu.filter((item) => {
      if (item.name === "Important Alerts") {
        return hasImportantAlertsPermission(_user.role?.permissions);
      }
      if (item.name === "Crypto Details") {
        // Special handling for "Important Alerts"
        if (
          _user?.role?.name === "Supper Admin" ||
          _user?.role?.name === "Super-Admin"
        )
          return true;
        else return false;
      }
      // General permission handling
      return (
        item.access === "openForAll" ||
        (item.access === "admin" && _user?.role?.name === "Super-Admin") ||
        (item.access === "rolePermission" &&
          hasPermission(item.name, _user.role?.permissions))
      );
    });

    this.userRoutes.push({
      path: "/administration",
      name: "Administration",
      icon: admissionStatusIcon,
      component: null,
      layout: "/admin",
      subPath: true,
      type: "administration",
      subMenu: subeMenuItems.length > 0 ? subeMenuItems : null,
    });

    this.props.routesData(this.userRoutes);
    this.props.userPermissions(_user.role?.permissions);
    this.props.history.push("/admin/dashboard");
  };

  render() {
    return (
      <>
        <Login
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          isLoading={this.state.isLoading}
        />
        <NotificationContainer />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setUserInfo: (payload) => dispatch(_setLoginUser(payload)),
  routesData: (payload) => dispatch(_addSidebarMenu(payload)),
  userPermissions: (payload) => dispatch(_setUserPermissions(payload)),
});

export default connect(null, mapDispatchToProps)(LoginForm);
