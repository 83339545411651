import client from "apis/config";

export const getManageCategoriesApi = async (page, limit = 10, search = "") => {
  return await client.get(`categories?page=${page}&search=${search}&limit=${limit}`);
};

export const getSingleCategoryApi = async (categoryId) => {
  return await client.get(`categories?categoryId=${categoryId}`);
};


export const postGrades = async (body) => {
  return await client.post("grades", body);
};

export const updateGradesApi = async (body) => {
  return await client.put("grades", body);
};

export const deleteGradeApi = async (id) => {
  return await client.delete(`grades/${id}`);
};

export const postCategories = async (body) => {
  return await client.post("categories", body);
};

export const updateCategoriesApi = async (body) => {
  return await client.put("categories", body);
};

export const deleteCategoryApi = async (id) => {
  return await client.delete(`categories/${id}`);
};

export const getGradeByCarriersdApi = async (id) => {
  return await client.get(`categories/grades?id=${id}`);
};
