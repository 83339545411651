import client from "apis/config";

export const getManufacturersApi = async (page = 1, limit = 10) => {
  return await client.get(`manufacturers?page=${page}&limit=${limit}`);
};

export const getModelsByManufacturerIdApi = async (id) => {
  return await client.get(`manufacture/${id}`);
};

export const postManufacturerApi = async (data) => {
  return await client.post(`manufacture`, data);
};

export const updateManufacturerApi = async (data) => {
  return await client.patch(`manufacture`, data);
};

export const deleteManufacturerApi = async (id) => {
  return await client.delete(`manufacture/${id}`);
};

export const postModelsOfManufacturerApi = async (data) => {
  return await client.post(`manufacture/models`, data);
};

export const updateModelsOfManufacturerApi = async (data) => {
  return await client.patch(`manufacture/models`, data);
};

export const deleteModelsOfManufacturerApi = async (id) => {
  return await client.delete(`manufacture/models/${id}`);
};


export const postAllManufacturerModelsApi = async (body) => {
  return await client.post("manufacture/upload", body);
};
