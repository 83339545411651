import client from "apis/config";

export const getAllUsersApi = async (page, limit = 10, search = "", date = "") => {
  return await client.get(`dashBoard/customers?search=${search}&page=${page}&limit=${limit}&date=${date}`);
};

export const getSignleCustomerApi = async (id) => {
  return await client.get(`dashBoard/customers/${id}`);
};

export const getGradesApi = async () => {
  return await client.get(`grades`);
};

export const postAdminDepositApi = async (payload) => {
  return await client.post("dashBoard/deposits", payload);
};

export const getAllEmployeeUsersApi = async (page = 1, limit = 10) => {
  return await client.get(`users?page=${page}&limit=${limit}`);
};

export const addEmployeeUsersApi = async (data) => {
  return await client.post(`users`, data);
};

export const updateEmployeeUsersApi = async (data) => {
  return await client.patch(`users`, data);
};

export const getAllCustomers = async (limit = 10000) => {
  return await client.get(`dashBoard/customers?limit=${limit}`);
};
