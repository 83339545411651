import client from "apis/config";

export const createRolesApi = async (data) => {
  return await client.post(`roles`, data);
};

export const updateRolesApi = async (data) => {
  return await client.put(`roles`, data);
};

export const getRolesApi = async (page = 1, limit = 10, search = "") => {
  return await client.get(`roles?page=${page}&search=${search}&limit=${limit}`);
};

export const deleteRolesApi = async (id) => {
  return await client.delete(`roles/${id}`);
};

export const getPermissionsApi = async () => {
  return await client.get(`permissions`);
};

