import { NEW_DEPOSIT_REQUESTS } from "actions/generalActions/generalAction";
import { NEW_ACCOUNT_REQUESTS } from "actions/generalActions/generalAction";
import { IMPORTANT_ALERTS_BADGE } from "actions/generalActions/generalAction";

const initialState = {
  unreadNewAccountRequests: 0,
  unreadNewDepositRequests: 0,
};

export default function generalReducer(state = initialState, action) {
  switch (action.type) {
    case NEW_ACCOUNT_REQUESTS:
      return {
        ...state,
        unreadNewAccountRequests: action.payload,
      };
      break;
    case NEW_DEPOSIT_REQUESTS:
      return {
        ...state,
        unreadNewDepositRequests: action.payload,
      };
      break;
    default:
      return state;
  }
}
