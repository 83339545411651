import CommonTable from "components/Tables/CommonTable";
import { Col, Form, Image, Input, Row, Select, Spin, Tooltip } from "antd";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import {
  getManageCategoriesApi,
  deleteCategoryApi,
  postCategories,
  updateCategoriesApi,
} from "apis/manage-categories";
import EditBlackIcon from "assets/img/black/edit.svg";
import DeleteRedIcon from "assets/img/red/delete.svg";
import CustomModal from "components/CustomModal/CustomModal";
import placeholderImage from "assets/img/default.png";
import { setRules } from "utils/common-functions";
import CustomButton from "components/custom-button";
import { uploadSingleFileToAWS } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import CommonDeleteModal from "components/common-delete-modal";
import { PlusOutlined } from "@ant-design/icons";
import { successMessage } from "utils/helpers/helpers";
import { onChangeCustomDebounce } from "utils/common-functions";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { postGrades } from "apis/manage-categories";
import { deleteGradeApi } from "apis/manage-categories";
import { updateGradesApi } from "apis/manage-categories";

const ManageCategoriesList = (props) => {
  const fileUpload = useRef(null);
  const [form] = Form.useForm();
  const [formGrades] = Form.useForm();
  const { Option } = Select;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [manageCategoriesListData, setManageCategoriesListData] =
    useState(null);
  console.log(manageCategoriesListData?.length, "data", page);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [uploading, setUpLoading] = useState(false);
  const [file, setFile] = useState();
  const [rowData, setRowData] = useState(null);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleDeleteGradesModal, setVisibleDeleteGradesModal] =
    useState(false);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  const [waitTimeCheck, setWaitTimeCheck] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [visibleGrades, setVisibleGrades] = useState(false);
  const [rowDataGrades, setRowDataGrades] = useState(null);

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getManageCategoriesApi(
        page,
        pageSize,
        searchValue
      );
      if (response.status === 200 && response.data?.data) {
        setManageCategoriesListData(response.data.data);
        setTotalNumberOfRecords(response?.data?.total);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!manageCategoriesListData) {
      fetchDataApi();
    }
  }, []);

  useEffect(() => {
    if (waitTimeCheck) {
      fetchDataApi();
    }
  }, [waitTimeCheck]);

  useEffect(() => {
    fetchDataApi();
  }, [page, pageSize]);

  const memoizedPage = useMemo(() => {
    if (manageCategoriesListData && manageCategoriesListData.length === 0) {
      setPage(1);
      return 1;
    }
    return page; // Return the current value of page if the condition is not met
  }, [manageCategoriesListData, page]); // Depend on manageCategoriesListData and page

  // console.log("Memoized page:", memoizedPage);

  const onSetFieldsValues = () => {
    if (rowData) {
      form.setFieldsValue({
        name: rowData?.title,
      });
      setFile(rowData?.image);
    }
    if (rowDataGrades) {
      formGrades.setFieldsValue({
        name: rowDataGrades?.title,
      });
    }
  };

  useEffect(() => {
    onSetFieldsValues();
  }, [rowData, rowDataGrades]);

  const onSuccessApi = () => {
    fetchDataApi();
    setVisibleModal(false);
    setVisibleDeleteModal(false);
    setVisibleDeleteGradesModal(false);
    setVisibleGrades(false);
    setRowData(null);
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const data = { ...values, image: file };
      if (rowData) {
        const updatePayload = { id: Number(rowData?.id), ...data };
        const res = await updateCategoriesApi(updatePayload);
        if (res?.data?.statusCode === 200) {
          onSuccessApi();
        }
      } else {
        const res = await postCategories(data);
        if (res?.data?.statusCode === 200) {
          onSuccessApi();
          setFile();
        }
      }
      setLoading(false);
      if (fileUpload.current) {
        fileUpload.current.value = "";
      }
      form.resetFields();
    } catch (error) {
      requestErrorHandel({ error: error });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onChooseFile = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const awsFile = await uploadSingleFileToAWS(e.target.files[0]);
        let splitUrl = awsFile.url.split("?");
        splitUrl = splitUrl[0];
        setFile(splitUrl);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    } finally {
      setUpLoading(false);
    }
  };

  const columnsData = [
    {
      title: "Carrriers ID",
      key: "id",
      dataIndex: "id",
      render: (text, row) => {
        return (
          <p className={style.onClickName} onClick={() => {}}>
            {ColTextCheck(text)}
          </p>
        );
      },
    },
    {
      title: "Name",
      key: "title",
      dataIndex: "title",
      render: (text, row) => {
        return <p onClick={() => {}}>{ColTextCheck(text)}</p>;
      },
    },
    {
      title: "Icons",
      key: "image",
      dataIndex: "image",
      render: (text, row) => {
        return (
          <a href={text} target="_blank" rel="noopener noreferrer">
            <img width={40} src={text} alt="icon" />
          </a>
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div className={style.actions}>
            <div onClick={() => {}}>
              <Tooltip placement="top" title={"Edit"}>
                <img
                  onClick={() => {
                    setRowData(row);
                    setVisibleModal(true);
                  }}
                  src={EditBlackIcon}
                  alt="icon"
                />
              </Tooltip>
              <Tooltip placement="top" title={"Delete"}>
                <img
                  onClick={() => {
                    setRowData(row);
                    setVisibleDeleteModal(true);
                  }}
                  src={DeleteRedIcon}
                  alt="icon"
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const columnsDataGrades = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      width: "20%",
      render: (text, row) => {
        return (
          <p className={style.onClickName} onClick={() => {}}>
            {ColTextCheck(text)}
          </p>
        );
      },
    },
    {
      title: "Name",
      key: "title",
      dataIndex: "title",
      render: (text, row) => {
        return <p onClick={() => {}}>{ColTextCheck(text)}</p>;
      },
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div className={style.actions}>
            <div onClick={() => {}}>
              <Tooltip placement="top" title={"Edit"}>
                <img
                  onClick={() => {
                    setRowDataGrades(row);
                    setVisibleGrades(true);
                  }}
                  src={EditBlackIcon}
                  alt="icon"
                />
              </Tooltip>
              <Tooltip placement="top" title={"Delete"}>
                <img
                  onClick={() => {
                    setRowDataGrades(row);
                    setVisibleDeleteGradesModal(true);
                  }}
                  src={DeleteRedIcon}
                  alt="icon"
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const onChangeSearch = (e) => {
    setSearchValue(e.target.value);
    onChangeCustomDebounce(2000, setWaitTimeCheck, e.target.value, searchValue);
  };

  const onSubmitGrades = async (values) => {
    try {
      setLoading(true);
      if (rowDataGrades) {
        const payload = {
          id: rowDataGrades?.id,
          name: values?.name,
        };
        const res = await updateGradesApi(payload);
        if (res?.data?.statusCode === 200) {
          successMessage({ message: "Grade has been updated successfully" });
        }
      } else {
        const res = await postGrades(values);
        if (res?.data?.statusCode === 200) {
          successMessage({ message: "Grade added successfully" });
        }
      }
      onSuccessApi();
      setLoading(false);
    } catch (error) {
      requestErrorHandel({ error: error });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onConfirmDelete = async () => {
    try {
      setLoading(true);
      if (rowData) {
        const res = await deleteCategoryApi(rowData?.id);
        if (res?.data?.statusCode === 200) {
          onSuccessApi();
          successMessage({ message: "Carrier deleted successfully" });
        }
      }
      setLoading(false);
    } catch (error) {
      requestErrorHandel({ error: error });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onConfirmDeleteGrades = async () => {
    try {
      setLoading(true);
      if (rowDataGrades) {
        const res = await deleteGradeApi(rowDataGrades?.id);
        if (res?.data?.statusCode === 200) {
          onSuccessApi();
          successMessage({ message: "Grade deleted successfully" });
        }
      }
      setLoading(false);
    } catch (error) {
      requestErrorHandel({ error: error });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <Row
          justify={"end"}
          alignItem="center"
          className={style.searchBoxContainer}
        >
          <CustomButton
            title="Add New Carrier"
            onClick={() => {
              setVisibleModal(true);
              setRowData(null);
              form.setFieldValue("name", "");
            }}
            variant="two"
            startData={<PlusOutlined color="black" />}
          />

          <CustomButton
            title="Add New Grade"
            onClick={() => {
              setRowDataGrades(null);
              formGrades.resetFields();
              setVisibleGrades(true);
            }}
            variant="one"
            startData={<PlusOutlined color="black" />}
          />

          <Input
            placeholder="Search Carriers"
            value={searchValue}
            onChange={onChangeSearch}
            style={{ width: "22%", height: "40px", fontSize: "15px" }}
          />
        </Row>

        <CommonTable
          className={style.customerTable}
          data={manageCategoriesListData}
          fetchRecords={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
          pageSize={pageSize}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <div className={style.expandedRow}>
                  <h6>Grades</h6>
                  <div className={style.modelsContainer}>
                    <CommonTable
                      className={style.gradesTable}
                      data={record?.grades}
                      columns={columnsDataGrades}
                      hidePagination={true}
                    />
                  </div>
                </div>
              );
            },
            width: "10%",
          }}
        />
        <CustomModal
          visibility={visibleModal}
          handleCancel={() => {
            setRowData(null);
            setVisibleModal(false);
          }}
          handleOk={() => null}
          title={rowData ? "Update The Carrier" : "Add New Carrier"}
          className={style.categoryModal}
        >
          <Spin spinning={uploading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.manageCategoriesModalForm}
              form={form}
              name="control-hooks"
              onFinish={onSubmit}
            >
              <Row align="bottom" gutter={[10]}>
                <Col span={24}>
                  <Form.Item
                    rules={setRules("Carrier Name")}
                    label={"Carrier Name"}
                    name={"name"}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Col>
                    {<Image width={"10rem"} src={file ?? placeholderImage} />}
                  </Col>

                  <input
                    type="file"
                    onChange={(e) => onChooseFile(e)}
                    name="image"
                    style={{ marginLeft: 10 }}
                    accept="image/png, image/gif, image/jpeg"
                    ref={fileUpload}
                  />
                  <br />
                </Col>

                <Col span={24}>
                  <Row justify={"center"}>
                    <CustomButton
                      title={rowData ? "Update" : "Add"}
                      disabled={uploading}
                      htmlType="submit"
                      loading={loading}
                      destroyOnClose={true}
                    />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </CustomModal>
        <CommonDeleteModal
          visibleModal={visibleDeleteModal}
          title={"Delete Carrier"}
          confimBtnTitle={"Confirm"}
          cancelBtnTitle="Cancel"
          loadingConfirmBtn={loading}
          onClickConfirm={onConfirmDelete}
          handleCancel={() => setVisibleDeleteModal(false)}
        />
        <CommonDeleteModal
          visibleModal={visibleDeleteGradesModal}
          title={"Delete Grades"}
          confimBtnTitle={"Confirm"}
          cancelBtnTitle="Cancel"
          loadingConfirmBtn={loading}
          onClickConfirm={onConfirmDeleteGrades}
          handleCancel={() => setVisibleDeleteGradesModal(false)}
        />
        <CustomModal
          visibility={visibleGrades}
          handleCancel={() => {
            setVisibleGrades(false);
          }}
          handleOk={() => null}
          title={rowDataGrades ? "Update The Grade" : "Add New Grade"}
          className={style.gradeModal}
        >
          <Spin spinning={loading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.modalForm}
              form={formGrades}
              name="control-hooks"
              onFinish={onSubmitGrades}
            >
              <Row align="bottom" gutter={[10]}>
                <Col span={24}>
                  {!rowDataGrades && (
                    <Form.Item label={"Carrier"} name={"categoryId"}>
                      <Select>
                        {manageCategoriesListData?.length > 0 &&
                          manageCategoriesListData?.map((item, index) => {
                            return (
                              <Option value={item?.id} key={index}>
                                {renderItemDataOrEmptyNull(item?.title)}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
                <Col span={24}>
                  <Form.Item label={"Grade"} name={"name"}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Row justify={"center"}>
                    <CustomButton
                      className={style.btn}
                      title={rowDataGrades ? "Update" : "Add"}
                      disabled={loading}
                      htmlType="submit"
                      variant="one"
                      width={"100%"}
                      loading={loading}
                    />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default ManageCategoriesList;
