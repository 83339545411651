import CommonTable from "components/Tables/CommonTable";
import { Col, Form, Image, Input, Row, Spin, Switch, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import CustomModal from "components/CustomModal/CustomModal";
import CustomButton from "components/custom-button";
import { updateEmployeeUsersApi } from "apis/user";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { PlusOutlined } from "@ant-design/icons";
import { EMPTY_NULL_DATA } from "utils/constants/const";
import { uploadSingleFileToAWS } from "utils/helpers/helpers";
import placeholderImage from "assets/img/default.png";
import { getCryptoDetailsApi } from "apis/crypto-details";
import { postCryptoDetailsApi } from "apis/crypto-details";
import { verifyCryptoUserOtp } from "apis/crypto-details";

const CryptoDetails = () => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [upLoading, setUpLoading] = useState(false);
  const [qrCodeFile, setQrCodeFile] = useState();
  const [originalCryptoDataApiRes, setOriginalCryptoDataApiRes] =
    useState(null);
  const [usersData, setUsersData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const targetPermissionName = "Site Settings";
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [token, setToken] = useState(null);
  const [cryptoDetails, setCryptoDetails] = useState({
    action: "",
    id: "",
  });

  const isPermissionExists =
    Array.isArray(loginData.role.permissions) &&
    loginData.role.permissions.find(
      (permission) => permission.name === targetPermissionName
    ) !== undefined;
  console.log(isPermissionExists, "permission");

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getCryptoDetailsApi(page, pageSize);
      if (response.status === 200 && response.data?.data) {
        setUsersData(response.data.data);

        setTotalNumberOfRecords(response?.data?.total);
        setOriginalCryptoDataApiRes(response?.data?.data);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    fetchDataApi();
  }, [page, pageSize]);

  useEffect(() => {
    if (rowData) {
      form.setFieldsValue({
        email: rowData?.email,
        roleId: rowData?.role.id,
      });
    }
  }, [rowData]);

  const onDetailsClick = (data) => {
    setRowData(data);
    setVisibleModal(true);
  };

  const onUpdateEmployeeUser = async (data) => {
    try {
      setLoading(true);
      const response = await updateEmployeeUsersApi(data);
      if (response.status === 200 && response.data) {
        successMessage({ message: "Cypto details has been updated." });
        fetchDataApi();
        setVisibleModal(false);
      } else {
        throw response;
      }
    } catch (error) {
      requestErrorHandel({ error });
    } finally {
      setLoading(false);
    }
  };

  const onCryptoDetails = async (data) => {
    try {
      setLoading(true);
      const response = await postCryptoDetailsApi(data);
      if (response.status === 200 && response.data) {
        successMessage({ message: "token has been generated." });
        fetchDataApi();
        setVisibleModal(false);
        setOpenOtpModal(true);
        setToken(response?.data?.data);
      } else {
        throw response;
      }
    } catch (error) {
      requestErrorHandel({ error });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onChangeEnableCrypto = async (check, row) => {
    const payload = {
      action: "operation",
      walletId: row?.id,
    };
    if (row) {
      await onCryptoDetails(payload);
    }
  };

  const onChooseFileQRCode = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const awsFile = await uploadSingleFileToAWS(e.target.files[0]);
        let splitUrl = awsFile.url.split("?");
        splitUrl = splitUrl[0];
        setQrCodeFile(splitUrl);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    } finally {
      setUpLoading(false);
    }
  };

  const columnsData = [
    {
      title: "Wallet",
      width: "25%",
      key: "name",
      dataIndex: "name",
      render: ColTextCheck,
    },
    {
      title: "ID",
      width: "25%",
      key: "value",
      dataIndex: "value",
      render: ColTextCheck,
    },
    {
      title: "QR",
      width: "25%",
      key: "image",
      dataIndex: "image",
      render: (text) => {
        return (
          <>
            {(text && (
              <a href={text} target="_blank" rel="noopener noreferrer">
                <img width={40} src={text} alt="icon" />
              </a>
            )) || <p>{EMPTY_NULL_DATA}</p>}
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "isEnable",
      width: "25%",
      dataIndex: "isEnable",
      render: (text, row) => {
        console.log(text, "text");
        return (
          <div className={style.actions}>

            {isPermissionExists ? (
              <Tooltip placement="top" title={text ? "Disable" : "Enable"}>
                <Switch
                  defaultChecked={text}
                  disabled={text}
                  onChange={(e) => {
                    onChangeEnableCrypto(e, row);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip placement="top" title={"disabled"}>
                <Switch
                  defaultChecked={text}
                  disabled={true}
                  onChange={(e) => {
                    onChangeEnableCrypto(e, row);
                  }}
                />
              </Tooltip>
            )}
          </div >
        );
      },
    },
  ];

  const onSubmit = async (values) => {
    const payload = {
      action: "add",
      meta: {
        name: values?.wallet,
        value: values?.walletID,
        image: qrCodeFile,
        isEnable: true,
      },
    };
    if (rowData) {
      await onCryptoDetails({
        name: values?.wallet,
        value: values?.walletID,
        image: values,
      });
    } else {
      await onCryptoDetails(payload);
    }
  };

  const validateField = (field, value) => {
    if (!value) {
      return Promise.reject(`${field} is required`);
    }
    return Promise.resolve();
  };

  // otp verification code

  const [errors, setErrors] = useState({
    otp: "",
  });

  const handleInputFocus = (fieldName) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  const [otp, setOtp] = useState(["", "", "", ""]); // Initialize with 4 empty strings

  const handleInputChange = (index, event) => {
    const value = event.target.value;

    // Check if the input is a digit and update the state
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;

      // Move to the next input field automatically
      if (index < 3 && value !== "") {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }

      setOtp(newOtp);
    }
  };

  const handleOtp = async () => {
    const areAllDigitsEmpty = otp.every((digit) => digit.trim() === "");
    const hasNonEmptyDigit = otp.some((digit) => digit.trim() === "");

    if (areAllDigitsEmpty) {
      setErrors({ ...errors, otp: "Otp is required" });
      return;
    }

    if (hasNonEmptyDigit) {
      setErrors({
        ...errors,
        otp: "OTP must be 4 digits. Please enter a valid OTP",
      });
      return;
    }
    const concatenatedOtp = getConcatenatedOtp();
    setOpenOtpModal(false);
    let payload = {
      token: token,
      otp: concatenatedOtp,
    };
    try {
      const response = await verifyCryptoUserOtp(payload);
      if (response.status === 200) {
        setUsersData();
        successMessage({
          message:
            cryptoDetails?.action === "add"
              ? "Crypto user has been created successfully."
              : "Crypto user has been updated successfully.",
        });
        fetchDataApi();
        setOtp(["", "", "", ""])
      }
    } catch (error) {
      setUsersData();
      fetchDataApi();
      requestErrorHandel({ error: error });
    }
  };

  const getConcatenatedOtp = () => {
    return otp.join("");
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <Row justify={"end"}>
          <CustomButton
            title={"Add New"}
            onClick={() => {
              setVisibleModal(true);
              setCryptoDetails({ action: "add", id: "" });
            }}
            variant="two"
            startData={<PlusOutlined />}
          />
        </Row>
        <CommonTable
          className={style.customerTable}
          data={usersData}
          fetchRecords={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
          pageSize={pageSize}
        />

        <CustomModal
          visibility={visibleModal}
          handleCancel={() => {
            setRowData(null);
            setVisibleModal(false);
          }}
          handleOk={() => null}
          title={rowData ? "Update" : "Add New"}
          className={style.modalEmployee}
        >
          <Spin spinning={loading || upLoading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.modalHeading}
              form={form}
              name="control-hooks"
              onFinish={onSubmit}
              initialValues={{ wallet: "", walletID: "" }}
            >
              <Row align="bottom" gutter={[10]}>
                <Col span={24}>
                  <Form.Item
                    className={style.mb_1}
                    label={"Wallet"}
                    name={"wallet"}
                    rules={[
                      {
                        validator: (_, value) => validateField("Wallet", value),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className={style.mb_1}
                    label={"Wallet ID"}
                    name={"walletID"}
                    rules={[
                      {
                        validator: (_, value) =>
                          validateField("Wallet ID", value),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <label>QR Code</label>
                  <Col>
                    {
                      <Image
                        width={"10rem"}
                        src={qrCodeFile ?? placeholderImage}
                      />
                    }
                  </Col>
                  <input
                    type="file"
                    onChange={(e) => onChooseFileQRCode(e)}
                    name="image"
                    style={{ marginLeft: 10 }}
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <br />
                </Col>
              </Row>

              <Row justify={"center"}>
                <CustomButton
                  title={(rowData && "Update") || "Add"}
                  disabled={loading}
                  htmlType="submit"
                  loading={loading}
                  variant="two"
                  width="200px"
                  startData={<PlusOutlined />}
                />
              </Row>
            </Form>
          </Spin>
        </CustomModal>

        {/* otp modal */}
        <CustomModal
          visibility={openOtpModal}
          handleCancel={() => {
            setOpenOtpModal(false);
            setUsersData();
            fetchDataApi();
          }}
          // handleOk={() => null}
          // title={rowData ? "Update" : "Add New"}
          className={style.modalOtpVerification}
          closable={true}
        >
          <div className="col-md-6 offset-md-3">
            <div className={`${style.otpModalContainer} `}>
              <h1>OTP</h1>
              <p>Enter a 4 digit OTP we sent on your mobile number.</p>
              <div
                className={
                  errors.otp === ""
                    ? style.otp_field_container
                    : style.otp_field_container_error
                }
              >
                {otp.map((digit, index) => (
                  <React.Fragment key={index}>
                    <input
                      id={`otp-input-${index}`}
                      type="text"
                      name={otp}
                      value={digit}
                      maxLength="1"
                      onChange={(event) => handleInputChange(index, event)}
                      style={{ textAlign: "center", marginRight: "5px" }}
                      onFocus={() => handleInputFocus("otp")}
                    />
                    {index < 3 && (
                      <span>
                        <hr></hr>
                      </span>
                    )}
                  </React.Fragment>
                ))}
              </div>
              <div className={style.text_danger}>
                <span>{errors.otp}</span>
              </div>
              <CustomButton
                title={"Verify"}
                onClick={() => handleOtp()}
                className={style.verifyOtp}
              />
            </div>
          </div>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default CryptoDetails;
