import Dashboard from "views/screens/dashboard";
import { constRoute } from "utils/constants";
import DashboardRed from "assets/img/sibar/red/dashboard.svg";
import CustomerRed from "assets/img/sibar/red/customer.svg";
import NewAccountRequestRed from "assets/img/sibar/red/new-account.svg";
import ManageCategoriesRed from "assets/img/sibar/red/manage-categories.svg";
import ManageAuctionsRed from "assets/img/sibar/red/manage-auction.svg";
import AuctionStatusRed from "assets/img/sibar/red/auction-status.svg";
import AllBiddingsRed from "assets/img/sibar/red/all-biding.svg";
import ImportantAlertsRed from "assets/img/sibar/red/important-alert.svg";
import SiteSettingRed from "assets/img/sibar/red/site-setting.svg";
import PagesRed from "assets/img/sibar/red/pages.svg";
import RolesRed from "assets/img/sibar/red/roles.svg";
import UsersRed from "assets/img/sibar/red/users.svg";
import CryptoRed from "assets/img/sibar/red/crypto.svg";

import DashboardGrey from "assets/img/sibar/grey/dashboard.svg";
import CustomerGrey from "assets/img/sibar/grey/customers.svg";
import NewAccountRequestGrey from "assets/img/sibar/grey/new-auction-requirement.svg";
import ManageCategoriesGrey from "assets/img/sibar/grey/manage-categories.svg";
import ManageAuctionsGrey from "assets/img/sibar/grey/manage-auctions.svg";
import AuctionStatusGrey from "assets/img/sibar/grey/auction-status.svg";
import AllBiddingsGrey from "assets/img/sibar/grey/all-biddings.svg";
import ImportantAlertsGrey from "assets/img/sibar/grey/important-alert.svg";
import SiteSettingGrey from "assets/img/sibar/grey/site-setting.svg";
import PagesGrey from "assets/img/sibar/grey/pages.svg";
import RolesGrey from "assets/img/sibar/grey/roles.svg";
import UsersGrey from "assets/img/sibar/grey/users.svg";
import CryptoGrey from "assets/img/sibar/grey/crypto.svg";

import UsersList from "views/screens/users/index.jsx";
import NewAccountsRequestList from "views/screens/accounts-request";
import ManageCategoriesList from "views/screens/manage-categories";
import ManageAuctionList from "views/screens/manage-auction";
import AuctionStatusList from "views/screens/auction-status";
import BidsList from "views/screens/bids";
import RolesList from "views/screens/roles-and-permission";
import PagesList from "views/screens/Pages";
import ImportantAlerts from "views/screens/important-alerts";
import EmployeeUsers from "views/screens/employee-users";
import SiteSetting from "views/screens/Site-Setting";
import ManufacturerModelList from "views/screens/manufacturer-models";
import CryptoDetails from "views/screens/crypto-details";
import admissionStatusIcon from "../assets/img/sibar/admissionStatusIcon.png";

const subMenu = [
  {
    path: constRoute?.dashboard,
    name: "Dashbord",
    icon: DashboardRed,
    activeIcon: DashboardGrey,
    component: Dashboard,
    layout: "/admin",
    subPath: true,
    sideMenuTitle: "Dashboard",
  },
  {
    path: constRoute?.customers,
    name: "Customers",
    icon: CustomerRed,
    activeIcon: CustomerGrey,
    component: UsersList,
    layout: "/admin",
    subPath: true,
    sideMenuTitle: "Customers",
  },
  {
    path: constRoute?.newAccountRequest,
    name: "New Account Requests",
    icon: NewAccountRequestRed,
    activeIcon: NewAccountRequestGrey,
    component: NewAccountsRequestList,
    layout: "/admin",
    subPath: true,
    sideMenuTitle: "New Accounts Request",
  },
  {
    path: constRoute.manufacturerAndModels,
    name: "Manufacturer & Models",
    icon: ManageAuctionsRed,
    activeIcon: ManageAuctionsGrey,
    component: ManufacturerModelList,
    layout: "/admin",
    subPath: true,
    sideMenuTitle: "Manufacturer & Models",
  },
  {
    path: constRoute?.cryptoDetails,
    name: "Crypto Details",
    icon: CryptoRed,
    activeIcon: CryptoGrey,
    component: CryptoDetails,
    layout: "/admin",
    subPath: true,
    sideMenuTitle: "Crypto Details",
  },
  {
    path: constRoute?.manageCategories,
    name: "Manage Carriers",
    icon: ManageCategoriesRed,
    activeIcon: ManageCategoriesGrey,
    component: ManageCategoriesList,
    layout: "/admin",
    subPath: true,
    sideMenuTitle: "Manage Carriers",
  },
  {
    path: constRoute?.manageAuctions,
    name: "Manage Auctions",
    icon: ManageAuctionsRed,
    activeIcon: ManageAuctionsGrey,
    component: ManageAuctionList,
    layout: "/admin",
    subPath: true,
    sideMenuTitle: "Manage Auctions",
  },
  // {
  //   path: constRoute?.auctionStatus,
  //   name: "Auction Status",
  //   icon: AuctionStatusRed,
  //   activeIcon: AuctionStatusGrey,
  //   component: AuctionStatusList,
  //   layout: "/admin",
  //   subPath: true,
  //   sideMenuTitle: "Auctions Status",
  // },
  {
    path: constRoute?.allBiddings,
    name: "All Biddings",
    icon: AllBiddingsRed,
    activeIcon: AllBiddingsGrey,
    component: BidsList,
    layout: "/admin",
    subPath: true,
    sideMenuTitle: "All Biddings",
  },
  {
    path: constRoute?.importantAlerts,
    // name: "Important Alerts",
    name: "Deposit Requests",
    icon: ImportantAlertsRed,
    activeIcon: ImportantAlertsGrey,
    component: ImportantAlerts,
    layout: "/admin",
    subPath: true,
    sideMenuTitle: "Important Alerts",
  },
  {
    path: constRoute?.siteSetting,
    name: "Site Settings",
    icon: SiteSettingRed,
    activeIcon: SiteSettingGrey,
    component: SiteSetting,
    layout: "/admin",
    subPath: true,
    sideMenuTitle: "Site Settings",
  },
  {
    path: constRoute?.pages,
    name: "Pages",
    icon: PagesRed,
    activeIcon: PagesGrey,
    component: PagesList,
    layout: "/admin",
    subPath: true,
    sideMenuTitle: "Pages",
  },
  {
    path: constRoute?.roles,
    name: "Roles",
    icon: RolesRed,
    activeIcon: RolesGrey,
    component: RolesList,
    layout: "/admin",
    subPath: true,
    sideMenuTitle: "Roles",
  },
  {
    path: constRoute?.users,
    name: "Users",
    icon: UsersRed,
    activeIcon: UsersGrey,
    component: EmployeeUsers,
    layout: "/admin",
    subPath: true,
    sideMenuTitle: "Users",
  },
];

const AdministrationRoutes = [
  {
    path: "/administration",
    name: "Administration",
    icon: admissionStatusIcon,
    component: null,
    layout: "/admin",
    subPath: true,
    type: "administration",
    subMenu: subMenu,
  },
];
export default AdministrationRoutes;
