import { Col, DatePicker, Form, Image, Input, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader, uploadSingleFileToAWS } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import PlusSignRed from "assets/img/red/add-red-icon.svg";
import AttachmentIcon from "assets/img/attachment.png";
import { getSignleCustomerApi } from "apis/user";
import { formatDate } from "utils/common-functions";
import NoDataFound from "components/no-data-found";
import CustomModal from "components/CustomModal/CustomModal";
import placeholderImage from "assets/img/default.png";
import { setRules } from "utils/common-functions";
import moment from "moment";
import CustomButton from "components/custom-button";
import { postAdminDepositApi } from "apis/user";
import { EMPTY_NULL_DATA } from "utils/constants/const";
import { getTimeFromdateString } from "utils/common-functions";
import { PlusOutlined } from "@ant-design/icons";
import { updateAuctionActionApi } from "apis/accounts-request";
import { NotificationManager } from "react-notifications";
import { defaultValueDate } from "utils/common-functions";
import { dallasTimezone } from "utils/common-functions";

const UserDetails = (props) => {
  const [form] = Form.useForm();
  const { location } = props;
  const userData = location.state;
  const [singleCustomerData, setSingleCustomerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [uploading, setUpLoading] = useState(false);
  const [file, setFile] = useState();
  const [depositAmount, setDepositAmount] = useState(null);
  const [userName, setUserName] = useState("");
  
  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getSignleCustomerApi(userData?.id);
      if (response.status === 200 && response.data?.data) {
        setSingleCustomerData(response.data.data);
        setUserName(response.data.data.name)
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, [userData?.id]);

  const onSubmit = async (values) => {
    
    const payload = { ...values, receipt: file, userId: userData?.id };
    payload["date"] = values?.date.format();
    payload["amount"] = depositAmount;
    try {
      setLoading(true);
      await postAdminDepositApi(payload).then(async (res) => {
        if (res?.data?.statusCode) {
          await fetchDataApi();
          setVisibleModal(false);
          form.setFieldsValue({
            amount: null,
          });
          setDepositAmount(null);
          setFile();
        }
      });
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const showModalClick = () => {
    setVisibleModal(true);
  };

  const onChooseFile = async (e) => {
    try {
      if (e.target.files.length) {
        setUpLoading(true);
        const awsFile = await uploadSingleFileToAWS(e.target.files[0]);
        let splitUrl = awsFile.url.split("?");
        splitUrl = splitUrl[0];
        setFile(splitUrl);
        setUpLoading(false);
      }
    } catch (error) {
      setUpLoading(false);
    }
  };

  const renderUserInfo = () => {
    return (
      <Row className={style.userInfoRow} gutter={20}>
        <Col md={14} sm={24} xs={24} className={style.userInfoCol}>
          <div className={style.cardContainer}>
            <Row style={{ padding: "10px 0px" }}>
              <Col className={style.leftUserInfoCol} sm={18} xs={24}>
                <Row className={style.leftUserInfo} justify={"start"}>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <h5>Name</h5>
                    </Row>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <p>
                        {renderItemDataOrEmptyNull(singleCustomerData?.name)}
                      </p>
                    </Row>
                  </Col>
                </Row>

                <Row className={style.leftUserInfo} justify={"start"}>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <h5>Email</h5>
                    </Row>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <p>
                        {renderItemDataOrEmptyNull(singleCustomerData?.email)}
                      </p>
                    </Row>
                  </Col>
                </Row>
                <Row className={style.leftUserInfo} justify={"start"}>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <h5>Phone No.</h5>
                    </Row>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <p>
                        {renderItemDataOrEmptyNull(singleCustomerData?.phoneNo)}
                      </p>
                    </Row>
                  </Col>
                </Row>
                <Row className={style.leftUserInfo} justify={"start"}>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <h5>Registration Date</h5>
                    </Row>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Row className={style.valueRow}>
                      <p>{formatDate(singleCustomerData?.createdAt)}</p>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className={style.rightUserInfoCol} sm={6} xs={24}>
                <Row className={style.countryValueRow}>
                  <h5 style={{ paddingRight: "10px" }}>Country: </h5>
                  <p>
                    {" "}
                    {renderItemDataOrEmptyNull(
                      singleCustomerData?.country?.name
                    )}{" "}
                  </p>
                </Row>
                <Row className={style.addressUserInfoRight}>
                  <Col md={24}>
                    <h5>Business Title:</h5>
                  </Col>
                  <Col span={24}>
                    <p>
                      {singleCustomerData?.addresses?.length >= 0
                        ? renderItemDataOrEmptyNull(singleCustomerData?.businessAddress
                        )
                        : EMPTY_NULL_DATA}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={10} xs={24} sm={24} className={style.auctionParticipatedCol}>
          <div className={style.cardContainer}>
            <Row className={style.aucPartHeader}>
              <Col>
                <h4>Auction Participated</h4>
              </Col>
              <Col>
                <h4>
                  {renderItemDataOrEmptyNull(
                    singleCustomerData?.auctions?.length
                  )}
                </h4>
              </Col>
            </Row>
            {(singleCustomerData?.auctions?.length > 0 &&
              singleCustomerData?.auctions?.map((item, index) => {
                return (
                  <Row
                    className={style.aucPartValuesContainer}
                    justify={"space-between"}
                    key={index}
                  >
                    <Col span={18}>
                      <h5> {renderItemDataOrEmptyNull(item?.title)} </h5>
                    </Col>
                    <Col span={6}>
                      <p>{renderItemDataOrEmptyNull(formatDate(item?.date))}</p>
                    </Col>
                  </Row>
                );
              })) || <NoDataFound height={100} />}
          </div>
        </Col>
      </Row>
    );
  };

  const depositManagementCard = () => {
    return (
      <div className={style.depostManagementCard}>
        <Row className={style.depositManagementHeader}>
          <Col>
            <h4>Deposit Management</h4>
          </Col>
          <Col className={style.depositBtnCol}>
            <h4>Add Deposit Amount</h4>
            <img
              onClick={() => showModalClick()}
              src={PlusSignRed}
              alt=""
              width={25}
            />
          </Col>
        </Row>
        <Row className={style.managementValues}>
          <Col md={8} sm={24} xs={24}>
            <h5>Current Depost</h5>
            <p>
              $
              {renderItemDataOrEmptyNull(singleCustomerData?.currentBalance, 2)}
            </p>
          </Col>
          <Col md={8} sm={24} xs={24}>
            <h5>Bidding Limit</h5>
            <p>
              ${renderItemDataOrEmptyNull(singleCustomerData?.totalBalance, 2)}
            </p>
          </Col>
          <Col md={8} sm={24} xs={24}>
            <h5>Available Limit</h5>
            <p>
              $
              {renderItemDataOrEmptyNull(
                singleCustomerData?.availableBalance,
                2
              )}
            </p>
          </Col>
        </Row>
      </div>
    );
  };

  const renderHistory = () => {
    return (
      <div className={style.historyContainer}>
        <Row className={style.historyHeader}>
          <h4>History</h4>
        </Row>
        <div className={style.historybody}>
          {singleCustomerData?.history
            ?.filter((item) => item?.key !== "deposit")
            ?.map((item, index) => {
              return (
                <Row className={style.historyItemRow} key={index}>
                  <Col md={16} className={style.historyTitle}>
                    {/* <h5>{item?.key} Rehan Ahmed won the auction</h5> */}
                    <p>
                      <b>{item.key}: </b>{" "}
                      {(item?.key === "auctionWon" &&
                        `${item?.logs?.winnerName
                        } has won this auction ${renderItemDataOrEmptyNull(
                          item?.logs?.auctionName
                        )}`) ||
                        (item?.key === "payAuction" &&
                          `${renderItemDataOrEmptyNull(item?.logs?.auctionName
                          )} has been paid`) ||
                        (item?.key === "auctionClosed" &&
                          `${renderItemDataOrEmptyNull(
                            item?.logs?.auctionName
                          )}  has been closed`) ||
                        (item?.key === "lostBid" &&
                          `${userName} has lost ${renderItemDataOrEmptyNull(
                            item?.logs?.auctionName
                          )}`)}
                    </p>
                  </Col>
                  <Col md={8} className={style.historyDates}>
                    <p>{getTimeFromdateString(item?.logs?.date)} </p>
                    <p>{formatDate(item?.logs?.date)}</p>
                  </Col>
                </Row>
              );
            })}

          {singleCustomerData?.history
            ?.filter((item) => item.key === "DepositApproved")
            ?.map((item, index) => {
              return (
                <Row className={style.historyItemRow} key={index}>
                  <Col md={16} className={style.historyTitle}>
                    <p>
                      {item?.logs?.name} updated deposit from $
                      {renderItemDataOrEmptyNull(
                        item?.logs?.amount1,
                        2
                      )}{" "}
                      to $
                      {renderItemDataOrEmptyNull(item?.logs?.amount2, 2)}
                    </p>
                    <p>
                      - {(item?.logs?.isUserInitiatedBy && "User ") || "Admin "}
                      Initiated
                    </p>
                  </Col>
                  <Col md={8} className={style.historyDates}>
                    <p>{getTimeFromdateString(item?.logs?.date)} </p>
                    <p>{formatDate(item?.logs?.date)}</p>
                    <p className={style.viewAttachment}>
                      {" "}
                      <span>
                        {" "}
                        <img src={AttachmentIcon} width={20} alt="icon" />{" "}
                      </span>{" "}
                      <a href={item?.logs?.attachment} target="_bank">
                        {" "}
                        View Attachment
                      </a>
                    </p>
                  </Col>
                </Row>
              );
            })}

          {singleCustomerData?.history
            ?.filter((item) => item.key === "deposit")
            ?.map((item, index) => {
              return (
                <Row className={style.historyItemRow} key={index}>
                  <Col md={16} className={style.historyTitle}>
                  <b>{item.key}: </b>{" "}
                    <p>
                    <br/>
                      {item?.logs?.name} updated deposit from $
                      {renderItemDataOrEmptyNull(
                        item?.logs?.availableAmount,
                        2
                      )}{" "}
                      to $
                      {renderItemDataOrEmptyNull(item?.logs?.currentAmount, 2)}
                    </p>
                    <p>
                      - {(item?.logs?.isUserInitiatedBy && "User ") || "Admin "}
                      Initiated
                    </p>
                  </Col>
                  <Col md={8} className={style.historyDates}>
                    <p>{getTimeFromdateString(item?.logs?.date)} </p>
                    <p>{formatDate(item?.logs?.date)}</p>
                    <p className={style.viewAttachment}>
                      {" "}
                      <span>
                        {" "}
                        <img src={AttachmentIcon} width={20} alt="icon" />{" "}
                      </span>{" "}
                      <a href={item?.logs?.attachment} target="_bank">
                        {" "}
                        View Attachment
                      </a>
                    </p>
                  </Col>
                </Row>
              );
            })}
        </div>
      </div>
    );
  };

  const onUpdateAuctionAction = async (id, action) => {
    try {
      const payload = { userId: id, action };
      const response = await updateAuctionActionApi(payload);
      if (response.status === 200 && response.data) {
        NotificationManager.info(response?.data?.message);
        fetchDataApi();
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    }
  };

  const enableDisableAccount = () => {
    if (singleCustomerData?.accountStatus === "approved") {
      onUpdateAuctionAction(singleCustomerData?.id, "blocked");
    } else {
      onUpdateAuctionAction(singleCustomerData?.id, "approved");
    }
  };

  return (
    <div className={style.pageContainer}>
      <Spin spinning={loading} indicator={renderLoader}>
        <Row gutter={40} justify={"end"} className={style.headerbtnContainer}>
          <CustomButton
            className={style.disabledButton}
            variant={
              singleCustomerData?.accountStatus === "blocked" ? "one" : "three"
            }
            title={
              singleCustomerData?.accountStatus === "blocked"
                ? "Enable Account"
                : "Disable Account"
            }
            onClick={enableDisableAccount}
          />

          <CustomButton
            title={"Add Deposit Amount"}
            onClick={() => showModalClick()}
            variant="two"
            startData={<PlusOutlined color="black" />}
          />
        </Row>
        {renderUserInfo()}
        {depositManagementCard()}
        {renderHistory()}
        <CustomModal
          visibility={visibleModal}
          handleCancel={() => setVisibleModal(false)}
          handleOk={() => null}
          title={"Upload the Receipt"}
          className={style.uploadRecieptModal}
        >
          <Spin spinning={uploading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.userDetailsModalForm}
              form={form}
              initialValues={{
                date: defaultValueDate,
                amount: depositAmount,
              }}
              onFinish={onSubmit}
            >
              <Row align="bottom" gutter={20}>
                <Col span={24}>
                  <Col>
                    {<Image width={"10rem"} src={file ?? placeholderImage} />}
                  </Col>
                  <input
                    type="file"
                    onChange={(e) => onChooseFile(e)}
                    name="image"
                    required
                    style={{ marginLeft: 10 }}
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <br />
                </Col>
                <Col span={24}>
                  {/* <Form.Item
                    rules={setRules("amount")}
                    label={"Amount ($)"}
                    name={"amount"}
                  > */}
                  <Input
                    value={depositAmount}
                    maxLength={6}
                    required
                    onChange={(e) => {
                      if (Number(e.target.value) < 500001) {
                        setDepositAmount(Number(e.target.value));
                      }
                      //  else {
                      //   form.setFields({
                      //     amount: {
                      //       value: 0,
                      //       errors: ['Please enter a value less than 500,000'],
                      //     },
                      //   });
                      // }
                    }}
                    suffix={<span style={{ color: '#231f1e' }}>$</span>} 
                  />
                  {/* </Form.Item> */}
                </Col>
                <Col span={24}>
                  {/* <Form.Item
                    rules={setRules("Date")}
                    label={"Date"}
                    name={"date"}
                  >
                    <DatePicker
                      showTime
                      defaultValue={defaultValueDate}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        if (e === null) {
                          form.setFieldsValue({
                            date: moment.tz(new Date(), dallasTimezone),
                          });
                        } else {
                          form.setFieldsValue({
                            // date: moment.tz(new Date(e), dallasTimezone),
                            date: moment.tz(new Date(e), dallasTimezone),
                          });
                          const datee=moment.tz(new Date(e), dallasTimezone);
                          console.log(datee,"dateeee",defaultValueDate);
                        }
                      }}
                    />
                  </Form.Item> */}
                  <Form.Item
                rules={setRules("Date")}
                label={"Date"}
                name={"date"}
              >
                <DatePicker
                  // disabled={isDisabledHasBids}
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  onChange={(e) => {
                    if (e === null) {
                      form.setFieldsValue({
                        date: moment.tz(new Date(), dallasTimezone),
                      });
                    } else {
                      form.setFieldsValue({
                        date: moment(e).tz(dallasTimezone),
                      });
                    }
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
                </Col>
                <Col span={24}>
                  <Row justify={"center"}>
                    <CustomButton
                      loading={loading || uploading}
                      disabled={uploading}
                      className={style.btn}
                      htmlType="submit"
                      title={"Upload"}
                    />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default UserDetails;
