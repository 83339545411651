import CommonTable from "components/Tables/CommonTable";
import { Col, Form, Input, Row, Select, Spin, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import { ColTextCheck } from "components/export-common-components/table-columns-text-check";
import classNames from "classnames";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { capitalizeString } from "utils/common-functions";
import { getAllBidsApi } from "apis/bids";
import { formatDate } from "utils/common-functions";
import { getTimeFromOperationsWithDays } from "utils/common-functions";
import { EyeOutlined } from "@ant-design/icons";
import { onChangeCustomDebounce } from "utils/common-functions";
import TimeRemainingTimer from "components/RemainingTimeTimer";
import CustomButton from "components/custom-button";
import CustomModal from "components/CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { exportSpecificAuctionsList } from "apis/bids";
import { successMessage } from "utils/helpers/helpers";
import { getBatchNumbersRelatedToCategory } from "apis/bids";
import { CSVLink, CSVDownload } from "react-csv";
import { Button } from "reactstrap";
import { _getBatchNoList } from "apis/auction/AuctionApi";
import { NotificationManager } from "react-notifications";
import { getAllCustomers } from "apis/user";
import { _getcarriersList } from "apis/auction/AuctionApi";
import { _carrierListingsAction } from "actions/auctions/auctions-details";

const BidsList = (props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [bidsListData, setBidsListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [waitTimeCheck, setWaitTimeCheck] = useState(false);
  const { carrier_list } = useSelector((state) => state.carrier_list);
  const [csvFileName, setCsvFileName] = useState("");
  const [readyToDownload, setReadyToDownload] = useState(false);
  const [customers, setCustomers] = useState([]);

  const [filterListingsData, setFilterListingsData] = useState({
    carrierList: carrier_list,
    batchNoList: [],
  });

  const [filters, setFilters] = useState({
    type: "",
    batchNo: "",
    carrier: "",
    bidType: "highestBids",
  });

  const handleChange = (key) => (value) => {
    setFilters((prevFilters) => {
      // Check if carrier has changed
      if (key === "carrier" && prevFilters.carrier !== value) {
        // Clear batchNo when carrier changes
        return {
          ...prevFilters,
          [key]: value,
          batchNo: "", // Clear batchNo
        };
      }

      // Update other key-value pairs
      return {
        ...prevFilters,
        [key]: value,
      };
    });
  };

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getAllBidsApi(
        page,
        pageSize,
        filters?.type,
        searchValue,
        filters?.batchNo,
        filters?.carrier,
        filters?.bidType
      );
      if (response.status === 200 && response.data?.data) {
        setBidsListData([]);
        setBidsListData(response.data.data);
        setTotalNumberOfRecords(response.data?.total);
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  const fetchBatchNosList = async () => {
    try {
      setLoading(true);
      const response = await _getBatchNoList(filters?.carrier);
      if (response.status === 200 && response.data?.data) {
        setFilterListingsData((prevState) => ({
          ...prevState,
          batchNoList: response?.data?.data || [],
        }));
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCustomersList = async () => {
      try {
        const response = await getAllCustomers();
        if (response.status === 200) {
          const result = response.data.data;
          setCustomers(result);
        } else {
          throw response;
        }
      } catch (e) {
        console.log({ e });
      }
    };
    fetchCustomersList();
  }, []);
  useEffect(() => {
    fetchDataApi();
    if (filters.carrier !== "") fetchBatchNosList(filters.carrier);
  }, [filters]);

  useEffect(() => {
    if (waitTimeCheck) {
      fetchDataApi();
    }
  }, [waitTimeCheck]);

  useEffect(() => {
    fetchDataApi();
  }, [page, pageSize, filters]);

  useEffect(() => {
    fetchCarriersList();
  }, []);
  const fetchCarriersList = async () => {
    try {
      setLoading(true);
      const response = await _getcarriersList();
      if (response.status === 200 && response.data?.data) {
        dispatch(_carrierListingsAction(response.data?.data));
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  const onChangeSearch = (e) => {
    setSearchValue(e.target.value);
    onChangeCustomDebounce(2000, setWaitTimeCheck, e.target.value, searchValue);
  };

  const columnsData = [
    {
      title: "Auction ID",
      key: "auctionName",
      dataIndex: "auctionName",
      render: (text, row) => {
        return (
          <div
            onClick={() => {
              props.history.push("auction-detail", { id: row?.auctionId });
            }}
            className={style.onClickName}
          >
            <span> {row?.identifier} </span>
          </div>
        );
      },
    },
    {
      title: "Serial ID",
      key: "id",
      dataIndex: "id",
      render: (text, row) => {
        return <div>{renderItemDataOrEmptyNull(row?.serialNumber)}</div>;
      },
    },
    {
      title: "Batch No",
      key: "batchNo",
      dataIndex: "batchNo",
      render: (text, row) => {
        return (
          <div style={{ width: 100 }}>
            <span>{renderItemDataOrEmptyNull(row?.batchNo)}</span>
          </div>
        );
      },
    },
    {
      title: "Sku",
      key: "sku",
      dataIndex: "sku",
      render: (text, row) => {
        return <div>{renderItemDataOrEmptyNull(row?.sku)}</div>;
      },
    },
    {
      title: "Carrier",
      key: "manufacture",
      dataIndex: "manufacture",
      render: (text, row) => {
        return (
          <div>
            <span>{renderItemDataOrEmptyNull(row?.category)}</span>
          </div>
        );
      },
    },
    {
      title: "Company Name",
      key: "companyName",
      dataIndex: "companyName",
      render: (text, row) => {
        return (
          <div>
            <span>{renderItemDataOrEmptyNull(row?.companyName)}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "auctionName",
      dataIndex: "auctionName",
      render: (text, row) => {
        return (
          <div>
            <span>{ColTextCheck(text)}</span>
          </div>
        );
      },
    },
    {
      title: "User",
      key: "name",
      dataIndex: "name",
      render: (text) => {
        return (
          <span> {capitalizeString(renderItemDataOrEmptyNull(text))} </span>
        );
      },
    },
    {
      title: "Bidding Amount",
      key: "amount",
      render: (item) => {
        return (
          <Row className={style.amount} alignItem={"center"}>
            <p>$</p>
            {ColTextCheck(
              renderItemDataOrEmptyNull(item?.amount / item?.totalUnits, 2)
            )}
          </Row>
        );
      },
    },
    /*
    {
      title: "Current bid",
      key: "lastBidPrice",
      // dataIndex: "lastBidPrice",
      render: (item) => {
        return (
          <Row className={style.amount} alignItem={"center"}>
            <p>$</p>
            {ColTextCheck(item?.lastBidPrice / item?.totalUnits, 2)}
          </Row>
        );
      },
    },
    */

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text) => {
        return (
          <span> {capitalizeString(renderItemDataOrEmptyNull(text))} </span>
        );
      },
    },
    {
      title: "Bid Date",
      key: "date",
      dataIndex: "date",
      render: (text) => {
        return <span>{renderItemDataOrEmptyNull(formatDate(text))}</span>;
      },
    },
    {
      title: "Time Remaining",
      key: "endTime",
      dataIndex: "endTime",
      render: (text) => {
        return (
          <span>
            {/* {renderItemDataOrEmptyNull(
              getTimeFromOperationsWithDays(text, new Date(), "-")
            )} */}
            <TimeRemainingTimer endAt={text} />
          </span>
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <Row justify={"center"} className={style.actions}>
            <Tooltip placement="top" title={"View"}>
              <EyeOutlined
                style={{
                  color: "#899499",
                  fontSize: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.history.push("auction-detail", { id: row?.auctionId });
                }}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const [visibleModal, setVisibleModal] = useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;
  const rowProps = { justify: "space-between", gutter: [20] };
  const colProps = { md: 12 };
  const [exportCategoryId, setExportCategoryId] = useState(null);
  const [batchNumbers, setBatchNumbers] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef(); // Create a ref for CSVLink component
  const setRules = (title) => {
    return [
      {
        required: true,
        message: `${title} is required`,
      },
    ];
  };

  const onSubmit = async (values) => {
    console.log("values", values);
    const { categoryId, BatchNo, customerId } = values;

    const carriersName = carrier_list.find((car) => car.id === categoryId);

    try {
      setLoading(true);
      const response = await exportSpecificAuctionsList(
        categoryId,
        BatchNo,
        customerId
      );
      if (response.status === 200 && response.data?.data?.length > 0) {
        let exportData = response?.data?.data;

        exportData.forEach((item) => {
          if (item.hasOwnProperty("customerEmail")) {
            item["ID"] = item["id"];
            delete item["id"];
            item["Identifier"] = item["identifier"];
            delete item["identifier"];
            item["OEM"] = item["oem"];
            delete item["oem"];
            item["SKU"] = item["sku"];
            delete item["sku"];
            item["Description"] = item["description"];
            delete item["description"];
            item["Disposition"] = item["disposition"];
            delete item["disposition"];
            item["Quantity"] = item["quantity"];
            delete item["quantity"];
            // Update the key from "customerEmail" to "Customer Email"
            item["Highest Bid"] = item["highestBid"];
            delete item["highestBid"]; // Optionally, delete the old key
            // item["Total Bid Amount"] = item["totalBidAmount"];
            delete item["totalBidAmount"]; // Optionally, delete the old key

            item["Customer Name"] = item["customerName"];
            delete item["customerName"]; // Optionally, delete the old key

            item["Company Name"] = item["companyName"];
            delete item["companyName"]; // Optionally, delete the old key

            item["Customer Email"] = item["customerEmail"];
            delete item["customerEmail"]; // Optionally, delete the old key

            item["Customer Phone Number"] = item["customerPhoneNumber"];
            delete item["customerPhoneNumber"]; // Optionally, delete the old key
          }
        });
        setCsvData(exportData);

        setCsvFileName(
          `${carriersName?.title
            ?.trim()
            .toLowerCase()}-${BatchNo?.trim().toLowerCase()}-bids.csv`
        );

        setReadyToDownload(true);
        setVisibleModal(false);
        successMessage({ message: "File export to CSV successfully " });
        form.setFieldsValue({ BatchNo: undefined, categoryId: undefined });
        setBatchNumbers([]);
      } else if (response.data?.data?.length === 0) {
        NotificationManager.error("Bids not found");
      } else {
        throw response;
      }
    } catch (e) {
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (readyToDownload) {
      document.getElementById("csv-download-link").click();
      setReadyToDownload(false);
    }
  }, [readyToDownload]);

  const getBatchNoDependOnCategory = async (categoryId) => {
    try {
      setLoading(true);
      const response = await getBatchNumbersRelatedToCategory({
        categoryId: categoryId,
      });
      if (response.status === 200) {
        setBatchNumbers(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleChangeCateogryId = async (e) => {
    setExportCategoryId(e);
    await getBatchNoDependOnCategory(e);
    form.setFieldsValue({ BatchNo: undefined });
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <Row
          justify={"end"}
          alignItem="center"
          className={style.searchBoxContainer}
        >
          <div className={style.selectBoxContainer}>
            {csvData.length > 0 && (
              <CSVLink
                id="csv-download-link"
                data={csvData}
                filename={csvFileName}
                target="_blank"
                style={{ display: "none" }} // Hide the CSVLink component
              ></CSVLink>
            )}
            <CustomButton
              // disabled={!auctionPayloadExcel?.length}
              className={style.btn}
              onClick={() => {
                setVisibleModal(true);
                setCsvData([]);
              }}
              loading={loading}
              title={"Export"}
            />
          </div>
          <div className={style.selectBoxContainer}>
            <Select
              defaultValue="highestBids"
              style={{ minWidth: 115 }}
              onChange={handleChange("bidType")}
              className={style.selectBoxHighestBids}
            >
              <Option value="highestBids">Highest Bids</Option>
              <Option value="">All Bids</Option>
            </Select>
          </div>
          <div className={style.selectBoxContainer}>
            <Select
              defaultValue=""
              style={{ minWidth: 115 }}
              onChange={handleChange("carrier")}
              className={style.selectBoxCarrier}
            >
              <Option value="">Carrier</Option>
              {carrier_list?.length > 0 &&
                carrier_list.map((item) => (
                  <Option value={item?.id}>{item?.title}</Option>
                ))}
            </Select>
          </div>
          <div className={style.selectBoxContainer}>
            <Select
              defaultValue=""
              style={{ minWidth: 115 }}
              onChange={handleChange("batchNo")}
              value={filters?.batchNo}
              className={style.selectBoxBatchNo}
            >
              <Option value="">Batch No</Option>
              {filterListingsData?.batchNoList?.length > 0 &&
                filterListingsData?.batchNoList.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
            </Select>
          </div>
          <div className={style.selectBoxContainer}>
            <Select
              defaultValue=""
              style={{ minWidth: 150 }}
              onChange={handleChange("type")}
              className={style.selectBox}
            >
              <Option value="">Filter by price</Option>
              <Option value="lowPrice">Low to High</Option>
              <Option value="highPrice">High to Low</Option>
            </Select>
          </div>
          <Input
            placeholder="Search bids"
            value={searchValue}
            onChange={onChangeSearch}
            style={{ width: "22%", height: "40px", fontSize: "15px" }}
          />
        </Row>

        <CommonTable
          className={style.customerTable}
          data={bidsListData}
          fetchRecords={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
          pageSize={pageSize}
        />

        <CustomModal
          visibility={visibleModal}
          handleCancel={() => {
            setVisibleModal(false);
          }}
          handleOk={() => {}}
          className={style.modalContainer}
          title={"Export Bids"}
        >
          <Form
            layout="vertical"
            className={style.modalFormContainer}
            form={form}
            onFinish={onSubmit}
          >
            <Row align="bottom" gutter={[10]}>
              <Col span={24}>
                <Form.Item
                  rules={setRules("Carrier")}
                  label={"Select Carrier"}
                  name={"categoryId"}
                  style={{ marginBottom: "15px" }}
                >
                  <Select onChange={(e) => handleChangeCateogryId(e)}>
                    {carrier_list?.length > 0 &&
                      carrier_list.map((item) => (
                        <Option value={item?.id}>{item?.title}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row align="bottom" gutter={[10]}>
              <Col span={24}>
                <Form.Item
                  rules={setRules("Batch No")}
                  style={{ marginBottom: "25px" }}
                  label={"Select Batch No"}
                  name={"BatchNo"}
                >
                  <Select disabled={exportCategoryId == null}>
                    {/* <Option value="">Batch No</Option>; */}
                    {batchNumbers.map((bacthNo) => {
                      return <Option value={bacthNo}>{bacthNo}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row align="bottom" gutter={[10]}>
              <Col span={24}>
                <Form.Item
                  style={{ marginBottom: "25px" }}
                  label="Select Customer (Select a customer to export all)"
                  name="customerId"
                >
                  <Select
                    showSearch
                    allowClear 
                    placeholder="Select Customer"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {customers &&
                      customers.map((customer) => {
                        let companyName = customer?.companyName;
                        let customerName = customer?.name;
                        let customerId = customer.id;
                        if (companyName) {
                          customerName = `${customerName} - ${companyName}`;
                        }
                        return (
                          <Option key={customerId} value={customerId}>
                            {customerName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CustomButton
                  // disabled={!auctionPayloadExcel?.length}
                  className={style.btn}
                  loading={loading}
                  title={"Export"}
                  htmlType="submit"
                />
              </Col>
            </Row>
          </Form>
        </CustomModal>
      </Spin>
    </div>
  );
};

export default BidsList;
