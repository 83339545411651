import CommonTable from "components/Tables/CommonTable";
import { Col, Form, Input, Row, Select, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import classNames from "classnames";
import EditBlackIcon from "assets/img/black/edit.svg";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { requestErrorHandel } from "utils/helpers/helpers";
import CustomModal from "components/CustomModal/CustomModal";
import CustomButton from "components/custom-button";
import { successMessage } from "utils/helpers/helpers";
import { getCompanyInfoApi } from "apis/site-setting";
import { updateCompanyInfoApi } from "apis/site-setting";

const SiteSetting = () => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [originalSiteSettingApiRes, setOriginalSiteSettingListApiRes] =
    useState(null);
  const [siteSettingListData, setSiteSettingListData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  const [actionType, setActionType] = useState();

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getCompanyInfoApi(page, pageSize);
      if (response.status === 200 && response.data?.data) {
        const makeArr = Object?.entries(response?.data?.data)?.map(
          ([key, value]) => {
            return {
              key: key,
              value: value,
            };
          }
        );
        setSiteSettingListData(makeArr);
        setOriginalSiteSettingListApiRes(makeArr);
        setTotalNumberOfRecords(response.data?.total);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    fetchDataApi();
  }, [page, pageSize]);

  useEffect(() => {
    form.setFieldsValue({
      type: rowData?.key,
      value: rowData?.value?.value,
    });
  }, [rowData]);

  const camelCaseToTitleCase = (input) => {
    // Step 1: Break camelCase by inserting spaces before capital letters
    let result = input.replace(/([A-Z])/g, " $1");

    // Step 2: Trim any leading space and capitalize the first letter of each word
    result = result
      .trim()
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
      });

    return result;
  };

  const columnsData = [
    {
      title: "#",
      key: "name",
      dataIndex: "name",
      width: "10%",
      render: (text, row, index) => {
        return <span> {index + 1}</span>;
      },
    },
    {
      title: "Type",
      key: "key",
      dataIndex: "key",
      width: "20%",
      render: (text, row) => {
        return (
          <p> {renderItemDataOrEmptyNull(camelCaseToTitleCase(row?.key))} </p>
        );
      },
    },
    {
      title: "Value",
      key: "key",
      dataIndex: "key",
      width: "40%",
      render: (text, row) => {
        return (
          <p>
            {" "}
            {renderItemDataOrEmptyNull(row?.value?.value)}{" "}
            {row?.key === "serviceCharges" || row?.key === "tax" ? " %" : ""}
          </p>
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      width: "20%",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div className={style.actions}>
            <div onClick={() => {}}>
              <Tooltip placement="top" title={"Edit"}>
                <img
                  onClick={() => {
                    setRowData(row);
                    setVisibleModal(true);
                    setActionType(row.value.id);
                    console.log(row.value.id, "row");
                  }}
                  src={EditBlackIcon}
                  alt="icon"
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const onSubmit = async (values) => {
    const payload = {
      id: rowData?.value?.id,
      value: values?.value,
    };
    try {
      setLoading(true);
      const response = await updateCompanyInfoApi(payload);
      if (response.status === 200 && response.data?.data) {
        fetchDataApi();
        successMessage({
          message: "Site Setting has been edited successfully.",
        });
        setVisibleModal(false);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ e });
    } finally {
      setLoading(false);
    }
  };

  const onChangeSearch = (e) => {
    const dummyArr = originalSiteSettingApiRes?.slice();
    const filteredData = dummyArr?.filter((item) =>
      (item?.name).toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    if (filteredData?.length) {
      setSiteSettingListData(filteredData);
    } else if (e.target.value === "") {
      setSiteSettingListData(setOriginalSiteSettingListApiRes);
    } else {
      setSiteSettingListData([]);
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <CustomModal
          visibility={visibleModal}
          handleCancel={() => {
            setRowData(null);
            setVisibleModal(false);
          }}
          handleOk={() => null}
          title={"Update Setting"}
        >
          <Spin spinning={loading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.modalForm}
              form={form}
              name="control-hooks"
              onFinish={onSubmit}
            >
              <Row align="bottom" gutter={[10]}>
                <Col span={24}>
                  <Form.Item label={"Type"} name={"type"}>
                    <Select disabled></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={"Value"} name={"value"}>
                    {actionType === 4 || actionType === 10 ? (
                      <Input
                        suffix={<span style={{ color: "#231f1e" }}>%</span>}
                      />
                    ) : (
                      <Input />
                    )}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Row justify={"center"}>
                    <CustomButton
                      className={style.btn}
                      title={"Update"}
                      disabled={loading}
                      htmlType="submit"
                      loading={loading}
                      destroyOnClose={true}
                    />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </CustomModal>

        <CommonTable
          className={style.customerTable}
          data={siteSettingListData}
          fetchRecords={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
          pageSize={pageSize}
        />
      </Spin>
    </div>
  );
};

export default SiteSetting;
