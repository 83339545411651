import client from "apis/config";

export const getDashboardAPI = async () => {
  return await client.get(`dashBoard`);
};

export const getManageAuctionsApi = async (
  page,
  limit = 10,
  search = "",
  status = "",
  batchNo = "",
  carrier = ""
) => {
  return await client.get(
    `dashBoard/auctions?page=${page}&search=${search}&status=${status}&categoryId=${carrier}&batchNo=${batchNo}&limit=${limit}`
  );
};

export const getManageAuctionsByIdApi = async (id) => {
  return await client.get(`dashBoard/auctions/${id}`);
};

export const postAuctionApi = async (body) => {
  return await client.post("auctions", body);
};

export const postAuctionApiByCSV = async (body) => {
  return await client.post("auctions/csv", body, {
    timeout: 30000,
  });
};

export const updateAuctionApi = async (body) => {
  return await client.patch(`auctions`, body);
};

export const setAuctionFeatureApi = async (id) => {
  return await client.put(`dashBoard/auctions/feature/${id}`);
};

export const cancelAuctionApi = async (id, data) => {
  return await client.put(`dashBoard/auctions/cancelled/${id}`, data);
};

export const deleteManageAuctionsByIdApi = async (id) => {
  return await client.delete(`auctions/${id}`);
};

export const getAuctionStatusApi = async (page = 1, limit = 10) => {
  return await client.get(
    `auctions/history/statuses?page=${page}&limit=${limit}`
  );
};

export const updateAuctionStatusMarkAsCompleteApi = async (id) => {
  return await client.put(`auctions/history/statuses/${id}`);
};

export const addAuctionShipmentApi = async (data) => {
  return await client.put(`dashBoard/auctions/shipment`, data);
};

export const _deleteAuctionsList = async (body) => {
  return await client.post("auctions/delete", body);
};

export const _getcarriersList = async (page = 1, limit = 1000) => {
  return await client.get(`categories?page=${page}&limit=${limit}`);
};

export const _getBatchNoList = async (id) => {
  return await client.get(`categories/batchNo/${id}`);
};
