import CommonTable from "components/Tables/CommonTable";
import { Col, Form, Input, Row, Select, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { renderLoader } from "utils/helpers/helpers";
import style from "./style.module.scss";
import classNames from "classnames";
import EditBlackIcon from "assets/img/black/edit.svg";
import { getPagesApi } from "apis/pages";
import { renderItemDataOrEmptyNull } from "utils/common-functions";
import { requestErrorHandel } from "utils/helpers/helpers";
import CustomModal from "components/CustomModal/CustomModal";
import CustomButton from "components/custom-button";
import { updatePagesApi } from "apis/pages";
import { successMessage } from "utils/helpers/helpers";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const PagesList = () => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [originalPagesApiRes, setOriginalPagesListApiRes] = useState(null);
  const [pagesListData, setPagesListData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  const [editorContent, setEditorContent] = useState(""); // New state to manage CKEditor content

  const fetchDataApi = async () => {
    try {
      setLoading(true);
      const response = await getPagesApi(page, pageSize);
      if (response.status === 200 && response.data?.data) {
        const makeArr = Object?.entries(response?.data?.data)?.map(
          ([key, value]) => {
            return {
              key: key,
              value: value,
            };
          }
        );
        setPagesListData(makeArr);
        setOriginalPagesListApiRes(makeArr);
        setTotalNumberOfRecords(response?.data?.total);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ e });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    fetchDataApi();
  }, [page, pageSize]);

  useEffect(() => {
    if (rowData) {
      form.setFieldsValue({
        type: renderItemDataOrEmptyNull(rowData?.key),
        text: rowData?.value?.valueEn,
      });
      setEditorContent(rowData?.value?.valueEn || ""); // Ensure CKEditor gets updated
    }
  }, [rowData, form]);

  const camelCaseToTitleCase = (text) => {
    if (!text) return "";
    // Add space before each uppercase letter and capitalize the first letter
    return text
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  };

  const columnsData = [
    {
      title: "Page",
      key: "key",
      dataIndex: "key",
      width: "60%",
      render: (text, row) => {
        return <p>{camelCaseToTitleCase(row?.key)}</p>;
      },
    },
    {
      title: "Actions",
      key: "Actions",
      width: "20%",
      dataIndex: "Actions",
      render: (text, row) => {
        return (
          <div className={style.actions}>
            <div onClick={() => {}}>
              <Tooltip placement="top" title={"Edit"}>
                <img
                  onClick={() => {
                    setRowData(row);
                    setVisibleModal(true);
                  }}
                  src={EditBlackIcon}
                  alt="icon"
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const onSubmit = async (values) => {
    const payload = {
      id: rowData?.value?.id,
      name: values?.text,
    };

    try {
      setLoading(true);
      const response = await updatePagesApi(payload);
      if (response.status === 200 && response.data?.data) {
        fetchDataApi();
        successMessage({
          message: "Page Content has been edited successfully.",
        });
        setVisibleModal(false);
      } else {
        throw response;
      }
    } catch (e) {
      requestErrorHandel({ e });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classNames(style.userListContainer, "both-side-padding")}>
      <Spin spinning={loading} indicator={renderLoader}>
        <CustomModal
          visibility={visibleModal}
          handleCancel={() => {
            setRowData(null);
            setVisibleModal(false);
          }}
          handleOk={() => null}
          title={"Edit Page Content"}
        >
          <Spin spinning={loading} indicator={renderLoader}>
            <Form
              layout="vertical"
              className={style.modalForm}
              form={form}
              name="control-hooks"
              onFinish={onSubmit}
            >
              <Row align="bottom" gutter={[10]}>
                <Col span={24}>
                  <Form.Item label={"Type"} name={"type"}>
                    <Select disabled></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={"Description"} name={"text"}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorContent} // Controlled content from state
                      config={{
                        extraAllowedContent: "a[!href]", // Allow custom styles on <a> tags
                        // You can also customize the toolbar here
                      }}
                      onReady={(editor) => {
                        // Inject custom CSS that applies only to links inside the editor
                        const stylesheet =
                          editor.ui.view.editable.element.ownerDocument.createElement(
                            "style"
                          );
                        stylesheet.innerHTML = `
                              a {
                                color: #12BCFE !important; /* Light blue color for links */
                              }
                            `;
                        editor.ui.view.editable.element.ownerDocument.head.appendChild(
                          stylesheet
                        );
                      }}
                      onChange={(event, editor) => {
                        const content = editor.getData();
                        form.setFieldsValue({ text: content }); // Update the form value
                        setEditorContent(content); // Keep editor content in sync
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row justify={"center"}>
                    <CustomButton
                      className={style.btn}
                      title={"Save"}
                      disabled={loading}
                      htmlType="submit"
                      loading={loading}
                      destroyOnClose={true}
                    />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </CustomModal>

        <CommonTable
          className={style.customerTable}
          data={pagesListData}
          fetchRecords={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          totalRecord={totalNumberOfRecords}
          columns={columnsData}
          pageSize={pageSize}
        />
      </Spin>
    </div>
  );
};

export default PagesList;
